import React, { Component } from "react";
import { connect } from "react-redux";
import RenderField from "../common/renderField";
import { Tabs, Tab } from "react-bootstrap";
import { Field, reduxForm, SubmissionError } from "redux-form";
import Loader from "../common/loader";
import renderHTML from 'react-render-html';
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import PageHeader from "../common/pageheader";
import Multiselect from "../common/multi";
import Multiselect3 from "../common/multi_v3";
import Multiselect4 from "../common/multi_v4";
import {
  normalizePhone,

} from './numberValidation';
import {
  PropertyStatus
} from "../common/options";
import './flyer.css';
import DropdownCompV2 from "../common/dropdown_v2";
import DropdownCompV4 from "../common/dropdown_v4";
import { STATUS } from "../common/options";
import DropdownComp from "../common/DropdownList";
import LocationSearchInput from "../common/address";
import DatePicker from "../common/DateTimePicker";
import { push } from "react-router-redux";
import { toast } from "react-toastify";
import HTTP from "../../services/http";
import { createNumberMask, createTextMask } from 'redux-form-input-masks';
import MaterialMultiV1 from '../common/material_multi_v1';
import RenderFieldV1 from "../common/renderFieldV1";

import {
  required,
  ValidateOnlyAlpha,
  yearBuiltValidation,
  mobileValidate,
  emailValiadte,
  RemarksMin
} from "../common/fieldValidations";
const normalizeLot = (value, previousValue,dhdh) => {
  if (!value) {
    return value
  }
  if(value > 43560){
     value = (value / 43560).toFixed(2)
  }
  else{
    value = value.replace(/[^\d]/g, '')
  }
     return value 
  }

const phoneMask = createTextMask({
  pattern: '(999)999-9999',
});

const currencyMask = createNumberMask({
  // prefix: 'US$ ',
  // suffix: ' per item',
  // decimalPlaces: 2,
  allowEmpty: true,
  locale: 'en-US',
})

const capitalize = (value,value2) => value ? value.charAt(0).toUpperCase() + value.slice(1) : ""
class AddRealEstateInvestors extends Component {


  constructor(props) {
    super(props);
    this.state = {
      propertyGuid:"",
      formAction: "ADD",
      propLot:0,
      selectedCoolingType:[],
      selectedHeatingType:[],
      selectedParkingType:[]
    };
    this.upsertProp = this.upsertProp.bind(this);
    this.getProp = this.getProp.bind(this);
    this.onlyValue = this.onlyValue.bind(this);
    this.onChangeHoa = this.onChangeHoa.bind(this);

  }



  componentDidMount() {

    console.log('props', this.props.personalInfo);


    (async () => {
      await this.getProp();
    })();

    console.log('props', this.props.personalInfo);

  }

  render() {
    const { handleSubmit } = this.props;
    const { isLoading, formAction, propLot, selectedMarkets, marketImage, status, isRenovated, isWholesale, selectedCounties, register, user_type_option, marketCounty, communication_type, selectedUsers } = this.state;
    //  console.log(11111,marketCounty,Array.isArray(marketImage),Array.isArray(user_type_option ) );
    return (


      <div className="relative">
        {isLoading ? (
          <Loader />
        ) : (
            <div>
              <PageHeader
                parent="Marketing Templates"
                parentRoute="/users"

              />

              <div>


                <Tabs defaultActiveKey={1} animation={false} id="profileTabs">
                  <Tab
                    eventKey={1}
                    title={
                      formAction === "ADD"
                        ? "Add New Template"
                        : "Edit User"
                    }
                  >
                    <form onSubmit={handleSubmit(this.upsertProp)}>

                      <Field name="email_subject"
                        fieldName="Email Subject*"
                        type="text"
                        rows={
                          2
                        }
                        textarea maxLength="100"
                        component={
                          RenderField
                        }
                        validate={
                          [required,

                          ]
                        }
                      />

                      <Field
                        name="register_wholesale"
                        placeholder="-select-"
                        options={
                          [{
                            label: "Just Listed",
                            value: "d-45ad5c547229475b8276ce621f1d4aa8"
                          },
                          {
                            label: "Price Reduced",
                            value: "d-62d043e10c8a42379555ecafd0447aab"
                          },
                          {
                            label: "Relisted",
                            value: "d-3488ecf6c65249798cb7b291db357993"
                          }
                          ]
                        }
                        onChangeHoa={
                          this.onChangeHoa
                        }
                        label="Choose Flyer Type*"
                        textField="label"
                        valueField="value"
                        component={DropdownCompV2}
                        validate={
                          required
                        }
                      />

                      <Field
                        name="category"
                        fieldName="Category"
                        format={capitalize}
                        readOnly={
                          true
                        }
                        type="text"
                        component={RenderField}
                      />

                      <Field name="status_prop"
                        textField="label"
                        valueField="value"
                        label="Status*"
                        readOnly={
                          true
                        }
                        id="status_prop"
                        component={
                          DropdownCompV2
                        }
                        options={
                          PropertyStatus
                        }
                      />

                      <Field
                        name="street_address"
                        fieldName="Street Address"
                        readOnly={
                          true
                        }
                        type="text"
                        component={RenderField}
                      />

                      <Field
                        name="city"
                        fieldName="City*"
                        readOnly={
                          true
                        }
                        type="text"
                        component={RenderField}
                      />


                      <Field
                        name="county"
                        fieldName="County*"
                        type="text"
                        readOnly={
                          true
                        }
                        component={RenderField}
                      />

                      <Field
                        name="zipcode"
                        fieldName="Zipcode*"
                        readOnly={
                          true
                        }
                        type="text"
                        component={RenderField}
                      />

                      <Field
                        name="price"
                        fieldName="Price*"
                        readOnly={
                          true
                        }
                        type="text" {
                        ...currencyMask
                        }
                        component={RenderField}
                      />

                      <Field
                        name="sqft"
                        fieldName="Total Sq Ft*"
                        readOnly={
                          true
                        }
                        type="text" {
                        ...currencyMask
                        }
                        component={RenderField}
                      />

                      <Field
                        name="bed"
                        fieldName="Beds*"
                        readOnly={
                          true
                        }
                        type="text"
                        component={RenderField}
                      />

                      <Field
                        name="bath"
                        fieldName="Baths*"
                        readOnly={
                          true
                        }
                        type="text"
                        component={RenderField}
                      />




                      < Field name="property_type"
                        textField="label"
                        valueField="value"
                        label="Property Type*"
                        id="key_features.property_type"
                        readOnly={true}
                        component={
                          DropdownCompV2
                        }
                        placeholder="-select-"
                        validate={
                          required
                        }
                        options={
                          [{
                            label: "Single Family",
                            value: "Single Family"
                          },
                          {
                            label: "Townhouse",
                            value: "Townhouse"
                          },
                          {
                            label: "Condo",
                            value: "Condo"
                          }
                          ]
                        }
                      />

                      < Field name="year_built"
                        fieldName="Year Built*"
                        type="text"
                        readOnly={
                          true
                        }
                        max={
                          4
                        }
                        component={
                          RenderField
                        }
                        validate={
                          [required, yearBuiltValidation]
                        }
                      />
                      < Field name="heating"
                        id="heating"
                        selectedValues={this.state.selectedHeatingType}
                        label="Heating*"
                        component={
                          MaterialMultiV1
                        }
                        placeholder="-select-"
                       
                        options={[
                          {
                            title: 'Kerosene',
                            _id: 'Kerosene',
                          }, {
                            title: '90% Forced Air',
                            _id: '90% Forced Air',
                          }, {
                            title: 'Baseboard',
                            _id: 'Baseboard',
                          }, {
                            title: 'Bio Fuel',
                            _id: 'Bio Fuel',
                          }, {
                            title: 'Ceiling',
                            _id: 'Ceiling',
                          }, {
                            title: 'Central',
                            _id: 'Central',
                          },
                          {
                            title: 'Coal',
                            _id: 'Coal',
                          },
                          {
                            title: 'Electric',
                            _id: 'Electric',
                          },
                          {
                            title: 'Electric Air Filter',
                            _id: 'Electric Air Filter',
                          }, {
                            title: 'Electric Star Heating',
                            _id: 'Electric Star Heating',
                          }, {
                            title: 'Floor Furnace',
                            _id: 'Floor Furnace',
                          }, {
                            title: 'Forced Air',
                            _id: 'Forced Air',
                          }, {
                            title: 'Gas',
                            _id: 'Gas',
                          }, {
                            title: 'Geothermal',
                            _id: 'Geothermal',
                          }, {
                            title: 'Geothermal Heat Pump',
                            _id: 'Geothermal Heat Pump',
                          }, {
                            title: 'Gravity',
                            _id: 'Gravity',
                          }, {
                            title: 'Heat Pump - Electric Backup',
                            _id: 'Heat Pump - Electric Backup',
                          }, {
                            title: 'Heat Pump - Gas Backup',
                            _id: 'Heat Pump - Gas Backup',
                          }, {
                            title: 'Heat Pump - Oil Backup',
                            _id: 'Heat Pump - Oil Backup',
                          }, {
                            title: 'Heat Pump(s)',
                            _id: 'Heat Pump(s)',
                          }, {
                            title: 'Hot Water',
                            _id: 'Hot Water',
                          }, {
                            title: 'Humidifier',
                            _id: 'Humidifier',
                          }, {
                            title: 'Oil',
                            _id: 'Oil',
                          }, {
                            title: 'Pellet Stove',
                            _id: 'Pellet Stove',
                          }, {
                            title: 'Programmable Thermostat',
                            _id: 'Programmable Thermostat',
                          }, {
                            title: 'Propane',
                            _id: 'Propane',
                          }, {
                            title: 'Radiant',
                            _id: 'Radiant',
                          }, {
                            title: 'Radiator',
                            _id: 'Radiator',
                          }, {
                            title: 'S/W Changeover',
                            _id: 'S/W Changeover',
                          }, {
                            title: 'Solar Active/Passive',
                            _id: 'Solar Active/Passive',
                          }, {
                            title: 'Solar Off Grid',
                            _id: 'Solar Off Grid',
                          }, {
                            title: 'Solar On Grid',
                            _id: 'Solar On Grid',
                          }, {
                            title: 'Solar Rough-In',
                            _id: 'Solar Rough-In',
                          },
                          {
                            title: 'Space Heater',
                            _id: 'Space Heater',
                          },
                          {
                            title: 'Steam',
                            _id: 'Steam',
                          },
                          {
                            title: 'Wall Unit',
                            _id: 'Wall Unit',
                          }, {
                            title: 'Wood Burn Stove',
                            _id: 'Wood Burn Stove',
                          },
                          {
                            title: 'Zoned',
                            _id: 'Zoned',
                          },
                          {
                            title: 'Other',
                            _id: 'Other',
                          },
                        ]}
                      />

                      <  Field name="parking_type"
                        readOnly={true}
                        id="parking_type"
                        label="Parking Type*"
                        selectedValues={this.state.selectedParkingType}
                        component={
                          MaterialMultiV1
                        }
                        placeholder="-select-"
                        validate={
                          required
                        }
                        options={[
                          {
                            title: 'None',
                            _id: 'None'
                          },
                          {
                            title: 'Alley',
                            _id: 'Alley'
                          },
                          {
                            title: 'Attached Carport',
                            _id: 'Attached Carport',
                          },
                          {
                            title: 'Attached Garage',
                            _id: 'Attached Garage',
                          },
                          {
                            title: 'Detached Carport',
                            _id: 'Detached Carport'
                          },
                          {
                            title: 'Detached Garage',
                            _id: 'Detached Garage'
                          },
                          {
                            title: 'Driveway',
                            _id: 'Driveway'
                          },
                          {
                            title: 'Off site',
                            _id: 'Off site',
                          },
                          {
                            title: 'Off Street',
                            _id: 'Off Street',
                          },
                          {
                            title: 'On Street',
                            _id: 'On Street',
                          },
                          {
                            title: 'Parking lot',
                            _id: 'Parking lot',
                          },
                          {
                            title: 'Other',
                            _id: 'Other',
                          }
                        ]}
                      />

                      <Field name="lot"
                        id="exterior_features.lot"
                        fieldName={"Lot Sq Ft"}
                        readOnly={true}
                        type="text"
                        normalize={normalizePhone}
                        {...currencyMask} 
                        component={RenderField}
                      />

                      < Field name="cooling"
                        id="key_features.cooling"
                        label="Cooling*"
                        component={
                          MaterialMultiV1
                        }
                        selectedValues={this.state.selectedCoolingType}
                        placeholder="-select-"
                        validate={
                          required
                        }
                        options={[
                          {
                            title: 'Central A/C',
                            _id: 'Central A/C',
                          },
                          {
                            title: 'Ceiling Fan(s)',
                            _id: 'Ceiling Fan(s)',
                          },
                          {
                            title: 'Air Purification System',
                            _id: 'Air Purification System',
                          },
                          {
                            title: 'Attic Fan',
                            _id: 'Attic Fan',
                          }, {
                            title: 'Dehumidifier',
                            _id: 'Dehumidifier',
                          }, {
                            title: 'ENERGY STAR Cooling System',
                            _id: 'ENERGY STAR Cooling System',
                          }, {
                            title: 'Fresh Air Recovery System',
                            _id: 'Fresh Air Recovery System',
                          }, {
                            title: 'Geothermal',
                            _id: 'Geothermal',
                          }, {
                            title: 'Heat Pump(s)',
                            _id: 'Heat Pump(s)',
                          }, {
                            title: 'HRV/ERV',
                            _id: 'HRV/ERV',
                          },
                          {
                            title: 'Programmable Thermostat',
                            _id: 'Programmable Thermostat',
                          }, {
                            title: 'Roof Mounted',
                            _id: 'Roof Mounted',
                          }, {
                            title: 'Solar Attic Fan',
                            _id: 'Solar Attic Fan',
                          }, {
                            title: 'Solar Off Grid',
                            _id: 'Solar Off Grid',
                          }, {
                            title: 'Solar Rough-In',
                            _id: 'Solar Rough-In',
                          }, {
                            title: 'Wall Unit',
                            _id: 'Wall Unit',
                          }, {
                            title: 'Whole House Exhaust Ventilation',
                            _id: 'Whole House Exhaust Ventilation',
                          }, {
                            title: 'Whole House Fan',
                            _id: 'Whole House Fan',
                          }, {
                            title: 'Whole House Supply Ventilation',
                            _id: 'Whole House Supply Ventilation',
                          }, {
                            title: 'Window Unit(s)',
                            _id: 'Window Unit(s)',
                          }, {
                            title: 'Zoned',
                            _id: 'Zoned',
                          },
                          {
                            title: 'Other',
                            _id: 'Other',
                          }, {
                            title: 'None',
                            _id: 'None',
                          }
                        ]}
                      />

                      < Field name="recName"

                        fieldName="REC Name*"
                        type="text"
                        readOnly={
                          true
                        }
                        component={
                          RenderField
                        }
                      />

                      < Field name="recMobile"

                        fieldName="REC Mobile"
                        type="text"{
                        ...phoneMask
                        }
                        readOnly={
                          true
                        }
                        component={
                          RenderField
                        }
                      />

                      < Field name="recEmail"

                        fieldName="REC Email"
                        type="text"
                        readOnly={
                          true
                        }
                        component={
                          RenderField
                        }
                      />

                      <Field name="remarks"
                        fieldName="Remarks*"
                        type="text"
                        rows={
                          5
                        }
                        readOnly={true}
                        textarea maxLength="1000"
                        component={
                          RenderField
                        }
                        validate={
                          [required]
                        }
                      />

                      <div className="form-actions">
                        <button
                          type="submit"
                          className="btn green uppercase"
                          disabled={this.props.invalid || this.props.submitting}
                        >
                          {formAction === "ADD" ? "Add" : "Update"}
                        </button>
                      </div>
                    </form>
                  </Tab>
                </Tabs>
              </div>
            </div>
          )}


      </div>
    );
  }











  onlyValue() {

  }

  onChangeHoa(data) {


  }


  upsertProp(data) {
    const { match } = this.props;

    if (data.register_wholesale === 'd-45ad5c547229475b8276ce621f1d4aa8') {
      data.flyerType = 'Just Listed from House Buyers of America!';
    } 
    else if(data.register_wholesale === 'd-3488ecf6c65249798cb7b291db357993'){
      data.flyerType = 'Relisted from House Buyers of America!';
    } 
    else {
      data.flyerType = 'Price Reduced! House Buyers of America renovated property!'
    }
    data['guid']=this.state.propertyGuid

    console.log(data);
    this.props.dispatch({
      type: 'Renovated_Flyer_Info',
      data

    })

    this.props.history.push('/renovatedflyer/preview');
  }

  getProp() {
    console.log('props calling', this.props);
    const { match, initialize } = this.props;
    /*extract plant id from request*/
    let roleID = match.params.id;
    console.log(roleID);

    if (roleID) {
      this.setState({ isLoading: true });
      HTTP.Request("get", window.admin.flyerSingle, { id: roleID }).then(
        result => {
          // console.log("hvshvfhsv--->",result.data.wholesale_market)
          this.setState({ isLoading: false ,propLot:result.data.key_features.lot,propertyGuid:result.data.guid});
          let propertyData = {
            id: result.data._id,
            recMobile: result.data.agents ?result.data.agents.phone_no || null:null,
            recName: result.data.agents ?result.data.agents.name || null:null,
            recEmail: result.data.agents ?result.data.agents.email || null:null,
            recFirstName: result.data.agents ?result.data.agents.firstname || null:null,
            street_address: result.data.street_address,
            status_prop: result.data.status_prop,
            category: result.data.category,
            city: result.data.city,
            market: result.data.market,
            county: result.data.county,
            zipcode: result.data.postal_code,
            price: result.data.price,
            sqft: result.data.sqft,
            bed: result.data.interior_features.bed,
            bath: result.data.interior_features.bath,
            remarks: result.data.publicRemarks,
            feature_image: result.data.feature_image,
            lot: result.data.exterior_features.lot,
            images: result.data.images,
            slug: result.data.slug,
            state_code: result.data.state_code,
            unit_no: result.data.unit_no ? result.data.unit_no : ''

          }

          if (result.data.key_features) {

            // this.state.selectedCoolingType = result.data.key_features.cooling;
            // this.state.selectedHeatingType = result.data.key_features.heating;
            this.state.selectedParkingType = result.data.key_features.parking_type;
            propertyData.property_type = result.data.key_features.property_type;
            propertyData.year_built = result.data.key_features.year_built;
            propertyData.parking_type = result.data.key_features.parking_type;
            propertyData.heating = result.data.key_features.heating;
            propertyData.cooling = result.data.key_features.cooling;
          };



          /*set data to form*/
          initialize(propertyData);
        }
      );
    }
  }
}

let AddRealEstateForm = reduxForm({
  form: "AddRealEstateForm"
})(AddRealEstateInvestors);

const mapStateToProp = state => {

  console.log('state', state);

  return ({
    personalInfo: state
  });



}

export default connect(mapStateToProp)(AddRealEstateForm);
