// @ts-check
import React, { Component } from 'react';
import { Field, initialize, reduxForm, SubmissionError } from 'redux-form';
import { push } from 'react-router-redux';
import { BrowserRouter, Routes, Route, withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import HTTP from '../../services/http';
import _ from 'lodash/core';
import Swal from 'sweetalert2'
import { Tab, Tabs, Alert } from 'react-bootstrap';
import { countyToMarket } from '../common/options';
// import { required, ValidateOnlyAlpha } from '../common/fieldValidations';
import { connect } from 'react-redux';
import { defaultTheme } from 'react-autosuggest/dist/theme';
import TT from "../common/tooltip";
import Banner from 'react-js-banner';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faWindowClose } from '@fortawesome/free-solid-svg-icons'
import { Gallery } from "react-grid-gallery";
import Switch from '@material-ui/core/Switch';
import "../../assets/css/profile.min.css";

import {
  required,
  ValidateOnlyAlpha,
  slugValidation,
  faqOrderMax,
} from '../common/fieldValidations';

/**COMPONENT */
import RenderField from '../common/renderField';
/* import ImageCropper from "../common/ImageCropper"; */

import PageHeader from '../common/pageheader';
import DropdownComp from '../common/DropdownList';
import ImageCropper from '../common/ImageCropper';
import Editor from '../common/editor';
import FroalaEditorComp from '../common/floalaEditor';
import Loader from '../common/loader';
import Revisions from './element/revisions';
import { ADMIN_TRACK_AUDIT_LOGS } from '../common/actions';
import infoOf from '../common/tooltip-text';
import { slugify } from '../../libs/Helper';
import { HelpBlock } from 'react-bootstrap';
import { RoutesValue } from '../../components/common/options';

/**CONSTANT DATA */
import { POSITIONS } from '../common/options';
import Autosuggest from 'react-autosuggest';
import ShowPopup from '../common/modalsq';
import Session from '../../services/session';
import PagePreview from './pagePreview';



class AddPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      viewMode:false,
      orignalData:{},
      suggestions:[],
      pageLinkedPopup:false,
      redirectLinkValid:true,
      sitemapLinks:[],
      revisions: [],
      statusValue:"",
      content: '',
      isLoading: false,
      isSlugify:true,
      value:"",
      imageSize: false,
      formAction: 'ADD',
      status: false,
      isPreview:false,
      previewData:{},
      imageRequired: false,
      _Position: [POSITIONS[0]],
      linkedPages:[],
      pageType: 'Page',
      state: '',
      city: '',
      originalSlug: '',
      selectImageModal: false,
      modalIsOpen: false,
      featuredImagefromLibraryURL: '',
      imageSelectedFromLibrary: false, 
      setImages: [],
      chosenImageData: null,
      imageRawData: null,
      intervalID: null,
      autoSavedPageID: null,
      autoSaveUserID: null,
      allPagesForFroala: []
    };

    

    console.log('propssss', this.props);
    /**event binding  */
    this.upsertCMS = this.upsertCMS.bind(this);
    this.upsertDraft = this.upsertDraft.bind(this);
    this.getaCMS = this.getaCMS.bind(this);
    this.getFile = this.getFile.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleImageValueChange = this.handleImageValueChange.bind(this);
    this.handleCityChange = this.handleCityChange.bind(this);
    this.titleInput = React.createRef();
    this.stateInput = React.createRef();
    this.cityInput = React.createRef();
    this.slugInput = React.createRef();
    this.summaryInput = React.createRef();
    this.froalaInput = React.createRef();
    this.metaDescInput = React.createRef();
    this.metaTitleInput = React.createRef();
    this.metaTagsInput = React.createRef();
    this.onCityChange = this.onCityChange.bind(this);
    this.onStateChange = this.onStateChange.bind(this);
    this.onSlugChange = this.onSlugChange.bind(this);
    this.onBlurEvent = this.onBlurEvent.bind(this);
    this.closePageLinkedPopup = this.closePageLinkedPopup.bind(this);
    this.closePagePreviewPopup = this.closePagePreviewPopup.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.setFeaturedImageFromLibrary = this.setFeaturedImageFromLibrary.bind(this);
    this.resetTimeout = this.resetTimeout(this);
    this.triggerAutoSave = this.triggerAutoSave.bind(this);
    this.loadPages = this.loadPages.bind(this);
  }

  customStyles = {
    content: {
      top: '45%',
      left: '55%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      borderRadius: '10px',
      transform: 'translate(-50%, -50%)',
      position: 'relative',
      maxWidth: '72%',
      height: '700px'
    },
    overlay: {
      background: 'rgb(0, 0, 0, 0.5)',
      zIndex: '2147483639',
      position: 'fixed'
    }
  };
  

  componentWillMount() {
    this.getaCMS();
    this.getSitemapLinks();
  }

  closeModal() {
    let updatedImages = this.state.setImages.map(item => {
      return {...item, isSelected: false}
    });
    const imageData = sessionStorage?.getItem('tempImage');
    if(imageData !== ''){
      this.setState({
        imageSelectedFromLibrary: false
      })
    }
    sessionStorage.removeItem('tempImage');
    this.setState({
      modalIsOpen: false,
      setImages: updatedImages,
      imageRawData: imageData
    })
  }

  openModal(e){
    e.preventDefault();
    this.setState({
      modalIsOpen: true
    })
  }

  handleSelect = (index, item ) => {
    const nextImages = this.state.setImages.map((image, i) =>
      i === index ? { ...image, isSelected: !image.isSelected } : {...image, isSelected: false}
    );
    this.setState({
      setImages: nextImages,
      featuredImagefromLibraryURL: this.state.setImages[index]['src'],
      chosenImageData: this.state.setImages[index]
    })
  };

  getSitemapLinks(){
    HTTP.Request('get', window.admin.sitemapActiveListing)
        .then(data => {
          if (data.Error) {
            this.setState({
              sitemapLinks: []
            });
          } else {
            this.setState({
              sitemapLinks: data.data.map(s=>{
                return s.link.split(".com")[1]
              }).filter(e=>"/"+this.state.orignalData.slug !== e)
            });
            this.state.sitemapLinks.push("/")
          }
        })
  }

  onStateChange(event) {
    this.setState({
      state: event,
    });
    if (this.state.pageType !== 'Page') {
      const { change } = this.props;
      change(
        'slug',
        slugify(event) +
          (this.state.pageType === 'CityPage' && this.cityInput.current.value
            ? '/' + slugify(this.cityInput.current.value)
            : ''),
      );
    }
  }

  onCityChange(event) {
    if (this.state.pageType === 'CityPage') {
      const { change } = this.props;
      if (event.target.value) {
        this.setState({
          city: event.target.value,
        });
        change(
          'slug',
          slugify(this.state.state) +
            (event.target.value ? '/' + slugify(event.target.value) : ''),
        );
      }
    }
  }

  onChange(event) {
    if (this.state.pageType === 'Page') {
      const { change } = this.props;
      if (event.target.value) {
          if(this.state.isSlugify){
            change('slug', slugify(event.target.value));
          }
          change('meta_title', slugify(event.target.value));
        }
      }
  }

  handleImageValueChange(event) {
    if (event == 'NoImage') {
      this.setState({ imageRequired: false, imageSelectedFromLibrary: false, imageRawData: false, image: null });
    } else if (event == 'UploadImage') {
      this.setState({ imageRequired: true });
    } else if (event == 'DefaultImage') {
      this.setState({ imageRequired: false, imageSelectedFromLibrary: false, imageRawData: false, image: null });
    }
  }

  setFeaturedImageFromLibrary() {
     this.setState({
      imageSelectedFromLibrary: true
     })
     this.closeModal();
  }

  chooseImageModalOpen() {

  }

  onSlugChange(e) {
    const { change } = this.props;
    change('slug', slugify(e.target.value));
    e.preventDefault();
  }

  onSugChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    });
  };

 resetTimeout = (id, newID) => {
	
    clearTimeout(id)
    return newID
    
  }

componentDidMount(){
  this.triggerAutoSave();
  this.loadPages();
}

loadPages() {
  const user = Session.getSession('user');
  const loginId = user._id;
  const data = {
      filter: 2,
      loginId: loginId,
      order: "updated_at",
      page: 1,
      pageType: "Page",
      searchQuery: "",
      statusFilter: "active",
      type: "page",
      value: "active",
      limit: 500
  };
  const blogData = {
     type: "blog",
     order: "created_at",
     loginId: loginId,
     limit: 500,
     statusFilter: "active",
     label: "Active",
     value: "active"
  }
  HTTP.Request("get", window.admin.getPages, data)
.then(response => { 
          const createdData = response?.data?.records?.map(item => ({text: item.title, href: process?.env?.NODE_ENV === 'production' ? `https://stage.housebuyersofamerica.com/${item.slug}`: `https://www.housebuyersofamerica.com/${item.slug}`}));
          const popup = [
                {
                  displayText: 'Modal Popup',
                  href: 'javascript%3Avoid(0)',
                }
          ];
          HTTP.Request("get", window.admin.getBlogs, blogData)
          .then(res => {
            const createdBlogData = res?.data?.records?.map(item => ({text: item.title, href: process?.env?.NODE_ENV === 'production' ? `https://stage.housebuyersofamerica.com/blog/${item.slug}`: `https://www.housebuyersofamerica.com/blog/${item.slug}`}));
            if(createdData !== undefined && createdBlogData !== undefined){
              const totalData = [...createdData, ...createdBlogData];
              totalData.sort((a, b) => { 
              let fa = a.text.toLowerCase(),
                  fb = b.text.toLowerCase();

                  if (fa < fb) {
                      return -1;
                  }
                  if (fa > fb) {
                      return 1;
                  }
                  return 0;
              });
              const newtotalData = [...popup, ...totalData];
              this.setState({
                allPagesForFroala: newtotalData
              })
              this.forceUpdate();
            }
          })
          .catch(err => console.error(err))
      })
.catch(error => { console.error(error) });
 
}

triggerAutoSave = () => {
  const id = setInterval(async () => {
    const { match } = this.props;
    let data = {};
    let formData = new FormData();
    data.type = 'page';
    data.content = this.froalaInput.current?.value ? this.froalaInput.current?.value : '' ;
    data.title = this.titleInput.current?.value ? this.titleInput.current?.value : '';
    data.slug = this.slugInput.current?.value;
    data.isDrafted = false;
    data.status = this.state.status;
    data.meta_title = this.metaTitleInput.current?.value ? this.metaTitleInput.current?.value : '';
    data.metaTags = this.metaTagsInput.current?.value ? this.metaTagsInput.current?.value : [];
    data.meta_description = this.metaDescInput.current?.value ? this.metaDescInput.current?.value : '';
    if(this.state.imageSelectedFromLibrary){
      data['image'] = this.state.chosenImageData;
    }
    if (match.params._id) data.editID = match.params._id;
    if(this.state.autoSavedPageID){
      data.editID = this.state.autoSavedPageID;
    }
  formData.append('data', JSON.stringify(data));
      if(!this.state.status && data.slug !== undefined ){
        HTTP.Request('post', window.admin.upsertPage, formData)
        .then((result) => {
          this.setState({
            autoSavedPageID: result.data._id,
            autoSaveUserID: result.data.author
          })
          toast.success('Page Auto Saved');
        })
        .catch((err) => {
          console.error(err);
          toast.error('Auto Save Paused, Some Error Occured, Please refresh the page');
        });
      }
      if(this.state.status && data.slug !== undefined && data.title !== '' && data.meta_title !== '') {
        HTTP.Request('post', window.admin.upsertPage, formData)
        .then((result) => {
          this.setState({
            autoSavedPageID: result.data._id,
            autoSaveUserID: result.data.author
          })
          toast.success('Page Auto Saved');
        })
        .catch((err) => {
          console.error(err);
          toast.error('Auto Save Paused, Some Error Occured, Please refresh the page');
        });
      } else if(data.title === '' && this.state.status) {
        toast.error('Auto Save Paused, Title can not be Blank');
      } else if(data.slug === undefined && this.state.status) {
        toast.error('Auto Save Paused, Custom URL can not be Blank');
      }
      else if(data.meta_title === '' && this.state.status) {
        toast.error('Auto Save Paused, Meta Title can not be Blank');
      }
    // }
    
    // this.upsertCMS();
  }, 10000);
  this.setState({
    intervalID: id
  })
} 

componentWillUnmount() {
  clearInterval(this.state?.intervalID);
}

  render() {
    console.log('EDIT_CUSTOM_URL', this.props);
    const { handleSubmit,match } = this.props;
    const { isLoading,previewData,isPreview,status,isSlugify,linkedPages,viewMode,value,pageLinkedPopup,orignalData ,redirectLinkValid,formAction, imageSize, suggestions,imageRequired, pageType } =
      this.state;
      const renderSuggestion = suggestion => (
        <div>
          {suggestion}
        </div>
      );
      
      var inputProps = {
        placeholder: 'Url',
        value,
        onChange: this.onSugChange,
        onBlur:this.onBlurEvent
     };
    return (
      <div className="relative">
        
        {isLoading ? (
          <Loader />
        ) : (
          
          <div>
            
            <PageHeader
              route={formAction === 'ADD' ? 'Add New Page' : 'Edit Page'}
              parent="Pages"
              parentRoute={pageType === 'Page' ? '/pages' : '/city-pages'}
            />

            <div className="tab-pane active">
              <Tabs defaultActiveKey={1} animation={false} id="profileTabs">
                <Tab
                  eventKey={1}
                  title={formAction === 'ADD' ? 'Add New Page' : 'Edit Page'}
                >
                  {pageLinkedPopup && (
            <ShowPopup
              closeParentModal={this.closePageLinkedPopup}
              message={
                <div>
                <p>You cannot make this page Inactive or redirect it as it is linked with other pages.</p>
                <b>Linked Pages</b>
                {
                  linkedPages.map(e=>{
                    return <p style={{cursor:"pointer",margin:"0"}} onClick={()=>{this.openNewTab(window.location.pathname.includes("/city-pages/")?`https://stageadmin.housebuyersofamerica.com/city-pages/edit/${e._id}`:`https://stageadmin.housebuyersofamerica.com/pages/edit/${e._id}`)}}>{e.slug}</p>
                  })
                }
                </div>
              }
            />
  )}
          {isPreview &&
              <div >
                <PagePreview  formData={previewData} upsertData={this.clickSubmitButton} closeParentModal={this.closePagePreviewPopup}/>
              </div>
            }
            {
                <div style={isPreview ?{display:'none'}:{}}> 
                  <Banner showBanner={formAction === 'EDIT' && ((!!orignalData.draft && orignalData.draft !== null && orignalData.draft !== "") || orignalData.isDrafted)} css={{ color: "#FFF", backgroundColor: "#ff4545ab", borderRadius: "4px", cursor: "pointer" }}>
                          <div >
                            <Link style={{textDecoration:'none',color:"white"}}
                            to={
                              orignalData.pageType == "CityPage"
                                ? '/city-pages/edit/' + orignalData._id + "/draft"
                                : '/pages/edit/' + orignalData._id + "/draft"
                            }
                            onClick={this.forceUpdate}
                          >There is a draft content not made live
                          </Link>
                            </div>
                  </Banner>
                  <form id="pageForm">
                    <Field
                      name="title"
                      fieldName="Title*"
                      type="text"
                      onChange={this.onChange}
                      component={RenderField}
                      // validate={[required, ValidateOnlyAlpha]}
                      validate={status ? [required, ValidateOnlyAlpha] : []}
                      ref={this.titleInput}
                      readOnly={viewMode}
                    />
                    {(this.state.pageType === 'CityPage' ||
                      this.state.pageType === 'StatePage') && (
                      <Field
                        name="pageType"
                        options={[
                          { label: 'State Page', value: 'StatePage' },
                          { label: 'City Page', value: 'CityPage' },
                        ]}
                        label="Select Page Type"
                        textField="label"
                        valueField="value"
                        handleImageValueChange={this.handleCityChange}
                        component={DropdownComp}
                        readOnly={viewMode}
                      />
                    )}
                    {(this.state.pageType === 'CityPage' ||
                      this.state.pageType === 'StatePage') && (
                      <div>
                        <Field
                          name="state"
                          options={countyToMarket}
                          label="State*"
                          textField="state"
                          valueField="state"
                          handleImageValueChange={this.onStateChange}
                          component={DropdownComp}
                          validate={[required]}
                          ref={this.stateInput}
                          readOnly={viewMode}
                        />
                        {this.state.pageType === 'CityPage' && (
                          <Field
                            name="city"
                            fieldName="City / County"
                            type="text"
                            component={RenderField}
                            onChange={this.onCityChange}
                            ref={this.cityInput}
                            readOnly={viewMode}
                          />
                        )}
                      </div>
                    )}

                  <div className='row ' style={{display:"flex"}}>
                    <div  className="col-sm-6">
                    <Field
                      name="slug"
                      fieldName="Custom URL*"
                      type="text"
                      component={RenderField}
                      onChange={this.onSlugChange}
                      disabled={this.props.allowCustomURLEdit ? false : true}
                      validate={[required]}
                      readOnly={viewMode}
                      ref={this.slugInput}
                    />
                    </div>
                    {this.state.pageType !== "CityPage" && 
                    <div className="col-sm-3">
                  <p style={{fontSize:"15px",marginTop:"0px",marginBottom:"1px",paddingLeft:"12px"}}>Auto generate slug from title</p>
                    <Switch
                      checked={isSlugify}
                      onChange={(e) => {
                        this.setState({
                          isSlugify: !isSlugify
                        })
                      }}
                      color="primary"
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                    </div>
                    }
              </div>
                    {/* the below auto suggest is hidden by force */}
                    {false&&<p>Redirect To</p>}
                    {false && <Autosuggest
                        suggestions={suggestions}
                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                        getSuggestionValue={this.getSuggestionValue}
                        renderSuggestion={renderSuggestion}
                        inputProps={inputProps}
                        id="redirectTo"
                        name="redirectTo"
                        value = {value}
                        theme={defaultTheme}
                      />}
                      {true && <Field
                        id="redirectTo"
                        name="redirectTo"
                        type="text"
                        fieldName="Redirect To"
                        placeholder='Url'
                        readOnly={true}
                        component={RenderField}
                        />}
                    {!redirectLinkValid ?(<p className='text-danger'>The redirect link is not valid</p>):("")}
                    {/* <Field
                      name="redirectTo"
                      fieldName="Redirect to URL"
                      type="text"
                      component={RenderField}
                    /> */}
                    <Field
                      name="oldUrl"
                      type="hidden"
                      component={RenderField}
                      readOnly={viewMode}
                    />
                    <div className="row">
                      <div className="col-sm-12">
                        <Alert bsStyle="info">
                          <strong>NOTE : </strong>
                          <span>
                            Note: If no featured image is uploaded, default
                            image will be selected
                          </span>
                        </Alert>
                      </div>
                      <div className="col-sm-6">
                        <Field
                          name="showImage"
                          options={[
                            { label: 'Default Image', value: 'DefaultImage' },
                            { label: 'Uploaded Image', value: 'UploadImage' },
                            { label: 'No Image', value: 'NoImage' },
                          ]}
                          label="Select Image"
                          textField="label"
                          valueField="value"
                          handleImageValueChange={this.handleImageValueChange}
                          component={DropdownComp}
                          disabled={viewMode}
                        />
                        {(this.state.imageRequired || this.state.image) && (
                            <button
                            //id="formSubmitBtn"
                            className="btn green uppercase"
                            // disabled={this.props.submitting}
                            onClick={(e) => {this.openModal(e)}}
                            >
                            Choose Image
                            </button>
                        )}
                         
                      </div>
                      <div className="col-sm-6">
                        {(this.state.image?.secure_url || this.state.imageSelectedFromLibrary || this.state.imageRawData) &&  (
                          <>
                          <label> Featured Image </label>
                            {(this.state.image || this.state.imageSelectedFromLibrary || this.state.imageRawData) ? this.state.imageSelectedFromLibrary ? (
                              <img
                                src={this.state.featuredImagefromLibraryURL}
                                alt={this.state.img_alt}
                                width="500px"
                                className="img-responsive img-thumbnail i-bot"
                              />
                            ) : this.state.imageRawData ? (
                              <img
                                src={this.state.imageRawData}
                                alt={this.state.img_alt}
                                width="500px"
                                className="img-responsive img-thumbnail i-bot"
                              />
                            ): (
                              <img
                                src={this.state.image?.secure_url}
                                alt={this.state.img_alt}
                                width="500px"
                                className="img-responsive img-thumbnail i-bot"
                              />
                            ) : null}
                            {/* {this.state.imageRawData && (
                               <img
                               src={this.state.imageRawData}
                               alt={this.state.img_alt}
                               width="500px"
                               className="img-responsive img-thumbnail i-bot"
                             />
                            )}
                            {this.state.imageSelectedFromLibrary && (
                               <img
                               src={this.state.featuredImagefromLibraryURL}
                               alt={this.state.img_alt}
                               width="500px"
                               className="img-responsive img-thumbnail i-bot"
                             />
                            )}
                            {this.state.image && (
                               <img
                               src={this.state.image?.secure_url}
                               alt={this.state.img_alt}
                               width="500px"
                               className="img-responsive img-thumbnail i-bot"
                             />
                            )} */}
                          </>
                        )}
                       
                      </div>
                    
                    </div>

                    <Field
                      textarea
                      name="summary"
                      component={RenderField}
                      fieldName="Summary"
                      readOnly={viewMode}
                      ref={this.summaryInput}
                    />
                    {this.state.allPagesForFroala.length > 0 && (
                      <Field
                      name="content"
                      fieldName="Content"
                      type="text"
                      component={FroalaEditorComp}
                      content={this.state.content}
                      statePage={this.state.state}
                      pageType={this.state.pageType}
                      readOnly={viewMode}
                      allLinksToRender={this.state.allPagesForFroala}
                      ref={this.froalaInput}
                    />
                    )}
                    <Field
                      name="meta_title"
                      icon="fa fa-info-circle"
                      tooltip={infoOf.meta_title}
                      fieldName="Meta Title*"
                      type="text"
                      component={RenderField}
                      validate={[required]}
                      readOnly={viewMode}
                      ref={this.metaTitleInput}
                    />
                    <Field
                      name="meta_description"
                      textarea
                      fieldName="Meta Description"
                      component={RenderField}
                      readOnly={viewMode}
                      ref={this.metaDescInput}
                    />
                    <Field
                      name="metaTags"
                      icon="fa fa-info-circle"
                      tooltip={infoOf.metaTags}
                      fieldName="Meta Tags"
                      type="text"
                      component={RenderField}
                      readOnly={viewMode}
                      ref={this.metaTagsInput}
                      // validate={[required]}
                    />
                    <div className="row">
                      {/* <div className="col-sm-6">
                        <Field
                          name="order"
                          icon="fa fa-info-circle"
                          tooltip={infoOf.order}
                          fieldName="Order"
                          type="number"
                          component={RenderField}
                          validate={[faqOrderMax]}
                        />
                      </div> */}
                      <div className="col-sm-4">
                        <Field
                          name="status"
                          options={[
                            { label: 'Active', value: true },
                            { label: 'Inactive', value: false },
                          ]}
                          label="Status"
                          defaultValue={
                            status ? 'Active' : 'Inactive'
                          }
                          value={
                            status ? 'Active' : 'Inactive'
                          }
                          onChange={this.onStatusChange}
                          textField="label"
                          valueField="value"
                          component={DropdownComp}
                          disabled={viewMode}
                        />
                      </div>
                    </div>

                    {!viewMode && <div className="form-actions" style={{ marginTop: '10px' ,display:"flex"}}>
                      <button
                        id="formSubmitBtn"
                        className="btn green uppercase"
                        disabled={this.props.submitting}
                        onClick={handleSubmit(e=>{
                          this.upsertCMS(e)})}
                      >
                        {formAction === 'ADD' ? 'Add' : 'Update'}
                      </button>
                      {formAction === 'EDIT' &&((!!orignalData.draft && orignalData.draft !== null && orignalData.draft !== "") || orignalData.isDrafted) && <Link className='btn green uppercase' style={{marginLeft:"4px"}} 
                              to={
                                orignalData.pageType == "CityPage"
                                  ? '/city-pages/edit/' + orignalData._id + "/draft"
                                  : '/pages/edit/' + orignalData._id + "/draft"
                              }
                              onClick={this.forceUpdate}
                            >View Draft
                      </Link>}
                      {status && (
                        <>
                        <button style={{marginLeft:"6px"}}
                        className="btn green uppercase ps-1"
                        disabled={status || ((match.params.draft=="draft" || formAction === 'ADD') && !status)?false:true}
                        onClick={(e)=>{
                          e.preventDefault();
                          this.upsertDraft(window['pagesForm'].values)
                        }}
                      >
                        {'Save as Draft'}
                      </button>
                      <TT tooltip="Status must be active to save as draft"><i style={{marginLeft:"4px"}} className="mb-4 fa fa-info-circle"></i></TT>
                      </>
                      )}
                      
                      <Banner showBanner={formAction === 'EDIT' && ((!!orignalData.draft && orignalData.draft !== null && orignalData.draft !== "") || orignalData.isDrafted)} css={{ color: "#FFF", backgroundColor: "#ff4545ab", borderRadius: "4px", cursor: "pointer" ,margin:"0px 6px" ,padding:"5px 10px"}}>
                            <div >
                              <Link style={{textDecoration:'none',color:"white"}}
                              to={
                                orignalData.pageType == "CityPage"
                                  ? '/city-pages/edit/' + orignalData._id + "/draft"
                                  : '/pages/edit/' + orignalData._id + "/draft"
                              }
                              onClick={this.forceUpdate}
                            >There is a draft content not made live
                            </Link>
                              </div>
                    </Banner>
                    </div>}
                  </form>
                  </div>}
                </Tab>
                {/* <Tab eventKey={2} title="Revisions">
                  <Revisions revisions={this.state.revisions} />
                </Tab> */}
              </Tabs>
            </div>
            <Modal
                isOpen={this.state.modalIsOpen}
                ariaHideApp={false}
                // onAfterOpen={afterOpenModal}
                onRequestClose={this.closeModal}
                style={this.customStyles}
                contentLabel="Example Modal"
              >
                <div>
                 <h2>Choose Image</h2>
                 <FontAwesomeIcon 
                  icon={faWindowClose} 
                  size='2x' 
                  style={{ position: 'absolute', left: '96%', top: '6%'}}
                  onClick={this.closeModal}
                />
                </div>
                
                <Tabs defaultActiveKey={1} animation={false} id="chooseImageTab">
                <Tab  eventKey={1}
                  title={`Select Image`}>
                <div style={{width: '100%', height:'500px', margin: '10px auto', position: 'relative'}}>
                  <div style={{ height: '100%', margin: 0, overflow: 'auto'}}>
                   <div style={{ maxHeight: '100%'}}>
                  <Gallery images={this.state.setImages} onSelect={this.handleSelect} defaultContainerWidth={200} />
                  </div>
                  {this.state.featuredImagefromLibraryURL && (
                    <div style={{width: '100%', marginTop: '5%', position: 'absolute', bottom: '-53px', left: '2px', right: '2px', height: '50px'}}>
                   <button
                            //id="formSubmitBtn"
                            className="btn green uppercase"
                            // disabled={this.props.submitting}
                            onClick={this.setFeaturedImageFromLibrary}
                            >
                            Set As Featured Image
                            </button>
                </div>
                  )}
                  </div>
                </div>    
                
                {/* <button onClick={this.closeModal}><FontAwesomeIcon icon={faWindowClose} /></button> */}
                
                  </Tab>
                  <Tab eventKey={2}
                  title={`Upload Image`}>
                    <Field
                          component={ImageCropper}
                          name="image"
                          id={'imagePage'}
                          minWidth={1300}
                          minHeight={550}
                          dimensionsCheck={true}
                          ratioUpper={1300}
                          ratioLower={550}
                          
                          validate={
                            formAction === 'ADD' && imageRequired
                              ? [required]
                              : []
                          }
                          disabled={viewMode}
                        />
                        <HelpBlock style={{ color: '#e73d4a' }}>
                          <div>Only <strong>*.jpg</strong>, <strong>*png</strong> images are supported</div>
                        </HelpBlock>
                        <Field
                          name="img_alt"
                          fieldName="Image Alternative Text"
                          type="text"
                          component={RenderField}
                          readOnly={viewMode}
                        />
                        <br />
                        {imageSize && (
                          <HelpBlock style={{ color: '#e73d4a' }}>
                            {
                              'Please select image with minimum resolution of 1300 x 450 pixels'
                            }
                          </HelpBlock>
                        )}
                  </Tab>
                </Tabs>  
                
      </Modal>
          </div>
        )}
      </div>
    );
  }

  onSuggestionsFetchRequested = ({ value }) => {
    let sug = this.state.sitemapLinks.filter((s)=>{
      return !!s ? s.includes(value) :  false
    })
    this.setState({
      suggestions:sug
    })
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  clickSubmitButton = () =>{
    document.getElementById("formSubmitBtn").click() 
  }

  getSuggestionValue = suggestion => {
    this.setState({
      redirectLinkValid:true
    })
    return suggestion
  }

  onStatusChange = async(event,value) =>{
    const {change}=this.props
    if(!value){
      const token = await Session.getSession('token');
      let requestOptions2 = {
        method: 'get',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
      };
      let response = await fetch(window.admin.getAllRedirectLink, requestOptions2);
      response = await response.json()
      let isRedirected = response.data.find(e=>e.redirectedUrl.includes("https://stage.housebuyersofamerica.com/"+this.state.orignalData.slug));
      if(isRedirected){
        event.preventDefault()
        this.setState({
          status:true
        })
        change("status","Active")
        toast.error("You cannot make this page inactive until it is used as redirect")
      }else{
        this.setState({
          status:false
        })
        change("status","Inactive")
      }
    }else{
      this.setState({
        status:true
      })
    }
    if(value && (this.state.value !== "" || !!this.state.value)){
      event.preventDefault()
      change("status","Inactive")
      this.setState({
        status:false
      })
      toast.error("You cannot make this page active until you remove the redirect")
    }
  }

  onBlurEvent=ss=>{
    const {change} = this.props
    if(this.state.value){
      change("status","Inactive")
      this.setState({
        status : false
      })
    }
    if(this.state.sitemapLinks.includes(ss.currentTarget.value) || ss.currentTarget.value ==""){
      this.setState({
        redirectLinkValid:true
      })
    }
    else{
      this.setState({
        redirectLinkValid:false
      })
    }
  }

  closePageLinkedPopup = () => {
      this.setState({
        pageLinkedPopup : false
      })
    }

  closePagePreviewPopup = () => {
    this.setState({
      isPreview : false
    })
  }
  
  openNewTab(link){
    window.open(link, '')
  }

  handleCityChange(event) {
    const { change } = this.props;
    this.setState({
      pageType: event,
    });
    if (event === 'CityPage' || event === 'StatePage') {
      change(
        'slug',
        slugify(this.state.state) +
          (event === 'CityPage' ? '/' + slugify(this.state.city) : ''),
      );
    } else {
      change(
        'slug',
        slugify(
          this.titleInput.current.value ? this.titleInput.current.value : '',
        ),
      );
    }
  }

 async upsertCMS(data) {
    const {orignalData} = this.state;
    if(!data.status || data.status == "Inactive"){
        const token = Session.getSession('token');
        const requestOptions = {
          method: 'post',
          headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
          body: JSON.stringify({ redirectTo: "/"+this.state.orignalData.slug }),
        };
        const requestOptions2 = {
          method: 'get',
          headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
        };
        let response = await fetch(window.admin.getCMSByRedirect, requestOptions);
        let response1 = await fetch(window.admin.getAllRedirectLink, requestOptions2);
        this.state.linkedPages = await response.json();
        response1 = await response1.json()
        const redirectLinks  = response1.data.map(e=>!!e.redirectedUrl.split(".com/")[1] ? e.redirectedUrl.split(".com/")[1] : "")
        if(redirectLinks.includes(this.state.originalSlug)){
          toast.error("You cannot make this page inactive as it is used as redirect URL");
          return false
        }
        if(this.state.linkedPages.data.length > 0){
          this.setState({
            linkedPages:this.state.linkedPages.data.map((e)=>{return {slug:e.slug ? "/"+e.slug:"",_id:e._id}}),
            pageLinkedPopup:true
          })
          return false
        }  
    }
    
    if(data.status == "Inactive"){
      data.status = false
    }
    else if(data.status == "Active"){
      data.status = true
    }
    if(!!this.state.value && !this.state.redirectLinkValid){
      toast.error("Redirect URL is incorrect please select a valid URL!");
      return false
    }
    if( !orignalData.redirectTo && !!this.state.value){
      toast.info("Since there is a redirect,this page is being made Inactive");
    }
    if(!!orignalData.redirectTo&&orignalData.redirectTo !== this.state.value  && this.state.value !== ""){
      toast.info("This page redirect is changed to "+'"'+this.state.value+'"');
    }
    if(this.state.formAction == "EDIT" && (!!this.state.orignalData.redirectTo && this.state.orignalData.redirectTo !== "")&& !data.status && this.state.value ==""){
      Swal.fire({
        title: 'The redirect is removed!',
        text: "Would you like to make page Active now as the redirect had been removed?",
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      }).then((result) => {
        if(result.isConfirmed){
          data.status = true
          this.state.status = true
        }
        data["redirectTo"]=this.state.value
        if(this.state.value){
          data.status = false
        }
        const { match, initialize,handleSubmit } = this.props;
        this.setState({ isLoading: true });
          const metaTagas = [];
          if(typeof data.metaTags == "string"){
            data.metaTags.length &&
            data.metaTags.split(',').forEach((ele) => {
              metaTagas.push(ele);
            });
          }
          data.metaTags = metaTagas;
          if (data.status === undefined) {
            data.status = false;
          }
      
          if (!data.pageType) {
            data.pageType = 'Page';
          }
          /*bind type of Post*/
          data.type = 'page';
          delete data.updated_at
          if (match.params._id) data.editID = match.params._id;
      
          let formData = new FormData();
          /*add file to request*/
          formData.append('file', data.image);
          if(data.status){
            data["draft"] = null
          }
          if (data.slug != this.state.originalSlug) {
            data.oldUrl = this.state.originalSlug;
          }
            data["isDrafted"] = false;
            formData.append('data', JSON.stringify(data));
            if(!this.state.isPreview && !!this.state.status){
              this.setState({
                previewData:data,
                isPreview:true,
                isLoading:false
              })
              return false
            }
          return HTTP.Request('post', window.admin.upsertPage, formData)
            .then((result) => {
              this.setState({ isLoading: false });
              if (result.data.pageType === 'Page') {
                this.props.dispatch(push('/pages'));
              } else {
                this.props.dispatch(push('/city-pages'));
              }
              toast.success(result.message);
              /*log audits for user*/
              this.props.dispatch({
                type: ADMIN_TRACK_AUDIT_LOGS,
                action: {
                  comment: 'Modified the content of Page - ' + result.data.title,
                  type: 'audit',
                },
              });
            })
            .catch((err) => {
              this.setState({ isLoading: false });
              if (err && err.errors && err.errors.length > 0) {
                let validationErrorsObj = {};
                err.errors.map((errorElem) => {
                  toast(errorElem, { type: 'error' });
                  // validationErrorsObj[errorElem.field] = errorElem.message;
                });
                throw new SubmissionError(validationErrorsObj);
              } else {
                toast(err.message, { type: 'error' });
              }
            });
        })
    }else{
    data["redirectTo"]=this.state.value
    if(this.state.value){
      data.status = false
    }
    const { match, initialize,handleSubmit } = this.props;
    this.setState({ isLoading: true });
      const metaTagas = [];
      if(typeof data.metaTags == "string"){
        data.metaTags.length &&
        data.metaTags.split(',').forEach((ele) => {
          metaTagas.push(ele);
        });
      }
      data.metaTags = metaTagas;
      if (data.status === undefined) {
        data.status = false;
      }
  
      if (!data.pageType) {
        data.pageType = 'Page';
      }
      /*bind type of Post*/
      data.type = 'page';
      delete data.updated_at
      if (match.params._id) data.editID = match.params._id;
  
      let formData = new FormData();
      /*add file to request*/
      formData.append('file', data.image);
      if(data.status){
        data["draft"] = null
      }
      if (data.slug != this.state.originalSlug) {
        data.oldUrl = this.state.originalSlug;
      }
      if(this.state.imageSelectedFromLibrary){
        data['image'] = this.state.chosenImageData;
      }
      data["isDrafted"] = false;
      if(this.state.autoSavedPageID){
        data.editID = this.state.autoSavedPageID;
      }
            formData.append('data', JSON.stringify(data));
            if(!this.state.isPreview){
              this.setState({
                previewData:data,
                isPreview:true,
                isLoading:false
              })
              return false
            }
      // Code to update page content     
      return HTTP.Request('post', window.admin.upsertPage, formData)
        .then((result) => {
          this.setState({ isLoading: false });
          if (result.data.pageType === 'Page') {
            this.props.dispatch(push('/pages'));
          } else {
            this.props.dispatch(push('/city-pages'));
          }
          toast.success(result.message);
          /*log audits for user*/
          this.props.dispatch({
            type: ADMIN_TRACK_AUDIT_LOGS,
            action: {
              comment: 'Modified the content of Page - ' + result.data.title,
              type: 'audit',
            },
          });
        })
        .catch((err) => {
          this.setState({ isLoading: false });
          if (err && err.errors && err.errors.length > 0) {
            let validationErrorsObj = {};
            err.errors.map((errorElem) => {
              toast(errorElem, { type: 'error' });
              // validationErrorsObj[errorElem.field] = errorElem.message;
            });
            throw new SubmissionError(validationErrorsObj);
          } else {
            toast(err.message, { type: 'error' });
          }
        });
      }
  }

  upsertDraft(data) {
    const { match, initialize,handleSubmit } = this.props;
    if(!data.slug || (!!data.slug && !data.slug.trim())){
      toast("Please enter a valid slug", { type: 'error' });
    }else{
      if(data.status == "Inactive"){
        data.status = false
      }else if(data.status == "Active"){
        data.status = true
      }
      this.setState({ isLoading: true });
        const metaTagas = [];
        if(!!data.metaTags && typeof data.metaTags == "string"){
          data.metaTags.length &&
          data.metaTags.split(',').forEach((ele) => {
            metaTagas.push(ele);
          });
        }
        data["metaTags"] = metaTagas;
        if (data.status === undefined) {
          data.status = false;
        }
    
        if (!data.pageType) {
          data.pageType = 'Page';
        }
        /*bind type of Post*/
        data.type = 'page';
        delete data.updated_at
        if (match.params._id) data.editID = match.params._id;
    
        let formData = new FormData();
        /*add file to request*/
        formData.append('file', data.image);
        if(data.status){
          data["draft"] = null
        }
        if (data.slug != this.state.originalSlug) {
          data.oldUrl = this.state.originalSlug;
        }
        if(this.state.formAction == "ADD"){
          data["isDrafted"] = true;
          formData.append('data', JSON.stringify(data));
        }else if(!!data && data.isDrafted){
          formData.append('data', JSON.stringify(data));
        }
        else{
          formData.append('data', JSON.stringify({editID:this.state.orignalData._id,draft:JSON.stringify(data)}));
        }
  
        return HTTP.Request('post', window.admin.upsertPage, formData)
          .then((result) => {
            this.setState({ isLoading: false });
            if (result.data.pageType === 'Page') {
              this.props.dispatch(push('/pages'));
            } else {
              this.props.dispatch(push('/city-pages'));
            }
            toast.success(result.message);
            /*log audits for user*/
            this.props.dispatch({
              type: ADMIN_TRACK_AUDIT_LOGS,
              action: {
                comment: 'Modified the content of Page - ' + result.data.title,
                type: 'audit',
              },
            });
          })
          .catch((err) => {
            this.setState({ isLoading: false });
            if (err && err.errors && err.errors.length > 0) {
              let validationErrorsObj = {};
              err.errors.map((errorElem) => {
                toast(errorElem, { type: 'error' });
                // validationErrorsObj[errorElem.field] = errorElem.message;
              });
              throw new SubmissionError(validationErrorsObj);
            } else {
              toast(err.message, { type: 'error' });
            }
          });
    }
  }

  getFile(event) {
    var reader = new FileReader();
    var selected = event.target.files[0]; // CREATE AN NEW INSTANCE.
    const scope = this;
    reader.onload = function (e) {
      var img = new Image();
      img.src = e.target.result;
      img.onload = function () {
        var height = img.height;
        var width = img.width;
        if (height < 450 && width < 1300) {
          scope.setState({ imageSize: true });
          document.getElementById('uploadCaptureInputFile').value = '';
        } else {
          scope.setState({ file: selected, imageSize: false });
        }
      };
    };
    reader.readAsDataURL(event.target.files[0]);
    /*  this.setState({ file: e.target.files[0],imageSize:false }); */
  }

  getaCMS() {
    const { match, initialize } = this.props;
    /*extract plant id from request*/
    let cmsID = match.params._id ? match.params._id : null;
            this.setState({
              isLoading: true
            })
            // 'http://localhost:3000/admin_api/get-all-images'
            // window.admin.getAllImages
            HTTP.Request('get', window.admin.getAllImages).then(
              (images) => {
                let updatedImages = images.data;
                updatedImages = updatedImages.map(image => {
                  return {...image, src : image.url}
                })
                this.setState({
                  setImages: updatedImages,
                  isLoading: false
                })
              }
            ).catch(error => console.error(error))
    if (cmsID) {
      this.setState({ isLoading: true, formAction: 'EDIT' });
      this.setState({
        isSlugify:false
      })
        HTTP.Request('get', window.admin.getaPage, { _id: cmsID }).then(
          (result) => {
            if(result && result.data.data.slug == "site-map"){
              this.setState({
                viewMode:true
              })
            }
            if(match.params.draft == "draft" || result.data.data.isDrafted){
              let draftData
              if(result.data.data.isDrafted){
                 draftData =result.data.data
              }
              else{
                draftData =JSON.parse(result.data.data.draft)
              }
              const { match, initialize } = this.props;
              
          
                  this.setState({
                    isLoading: false,
                    status: draftData.status,
                    _Position: [
                      _.find(POSITIONS, { value: draftData.position }),
                    ],
                    content: draftData.content ? draftData.content : '',
                    image: draftData.image ? draftData.image : '',
                    value:draftData.redirectTo ? draftData.redirectTo : '',
                    revisions: result.data.revisions ? result.data.revisions : [],
                    pageType: draftData.pageType,
                    state: draftData.state,
                    city: draftData.city,
                    originalSlug: draftData.slug,
                    orignalData:draftData,
                    // selectableImages: selImages
                  });
                  /*set data to form*/
                  initialize(draftData);
            }else{
              const { match, initialize } = this.props;
              this.setState({
                isLoading: false,
                status: result.data.data.status,
                _Position: [
                  _.find(POSITIONS, { value: result.data.data.position }),
                ],
                content: result.data.data.content ? result.data.data.content : '',
                image: result.data.data.image ? result.data.data.image : '',
                revisions: result.data.revisions ? result.data.revisions : [],
                pageType: result.data.data.pageType,
                state: result.data.data.state,
                city: result.data.data.city,
                originalSlug: result.data.data.slug,
                orignalData:result.data.data,
                // selectableImages: selImages,
                value:result.data.data.redirectTo ? result.data.data.redirectTo : ''

              });
              /*set data to form*/
              initialize(result.data.data);
            }
          },
        );
    } else {
      const isCityPage = this.props.location.pathname.includes('city-pages');

      const pageType = isCityPage ? 'CityPage' : 'Page';
      this.setState({ pageType: pageType });
    }
  }
}

//decorate form component
let AddPage_Form = reduxForm({
  form: 'AddPage_Form',
  touchOnBlur: false})(AddPage);
const mapStateToProp = (state) => {
  window['pagesForm']=state.form.AddPage_Form
  var solvedPermissions = {};
  if (
    state.admin.permissions &&
    state.admin.permissions.indexOf('EDIT_CUSTOM_URL') !== -1
  ) {
    solvedPermissions.allowCustomURLEdit = true;
  }
  return solvedPermissions;
};
export default connect(mapStateToProp)(AddPage_Form);
