import { Modal, Alert, Table, Button } from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';
import Pagination from "react-js-pagination";
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import queryString from 'query-string';
import React,{Component} from 'react';
import moment from "moment-timezone";
import Moment from "react-moment";

import { required, ValidateOnlyAlpha } from "../../common/fieldValidations";
import HTTP from '../../../services/http';

/**COMPONENTS */
import DatePicker from '../../common/DateTimePicker';
import Loader from "../../common/loader";
import ROW from "./row";

var timer;
class TableComp extends Component{
    constructor(props){
        super(props);
        this.state= {
            array:[],
            isLoading:false,
            searchQuery :'',
            activePage :1,
            totalItemsCount: 1,
            showOfferDelete: false,
            showOfferSettelmentDialog: false,
        }
        this.getOffers=this.getOffers.bind(this);
        this.search = this.search.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.closeOfferDeleteModal = this.closeOfferDeleteModal.bind(this);
        this.deleteOffer = this.deleteOffer.bind(this);
        this.exportToCSV = this.exportToCSV.bind(this);
    }

    componentWillMount(){
        const queryParams = queryString.parse(this.props.location.search);
        this.getOffers({
            page: queryParams.page > 1 ? queryParams.page : 1
        });
        this.setState({activePage: queryParams.page > 1 ? queryParams.page : 1})
    }
    
    render(){
        const settlementFormSubmit = (data) => {
            this.setSettlementDate(data);
        }
        const {array ,isLoading} = this.state;
        const { acceptOffer } = this.props;
        return(
            <div>
                 {isLoading &&<Loader />}
                <div className="portlet light bordered">
                <div className="portlet-body">
                    {/* actions search addnew  */}
                    <div className="table-toolbar">
                            <div className="row">
                                <div className="col-md-12">
                                    <button onClick={this.exportToCSV} id="sample_editable_1_new" className="btn sbold green pull-right"> Export to CSV </button>
                                    <h4><b> OFFERS </b></h4>
                                </div>
                            </div>
                        </div>
                           {/* if list is empty */}
                           {!isLoading && !array.length ? <Alert bsStyle="warning">
                            <strong>No Data Found !</strong>
                        </Alert>:
                            <div style={{ display: "flow-root"}}>                                
                            <Table responsive striped bordered condensed hover> 
                                <thead>
                                    <tr>
                                        <th>Property</th>
                                        <th>User</th>
                                        <th>Price</th>
                                        <th>EMD</th>
                                        <th>Status</th>
                                        <th>Received On</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
 
                                <tbody>
                                    {array.map(cms => {
                                        return (
                                            <ROW 
                                                key={cms._id} 
                                                cms={cms} 
                                                delete={this.delete}
                                                declineOffer={this.declineOffer.bind(this)}
                                                showOfferDeleteModal={this.showOfferDeleteModal.bind(this)}
                                                showOfferSettelmentModal={this.showOfferSettelmentModal.bind(this)}
                                             />
                                        )
                                    })}
                                </tbody>
                                <Modal
                                    show={this.state.showOfferDelete}
                                    onHide={() => this.closeOfferDeleteModal()}
                                >
                                    <Modal.Header closeButton className="theme-bg">
                                    <Modal.Title>DELETE OFFER</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                    <div>
                                        Are you sure you want to delete this offer?
                                        {/* {offer.property.street_address} - {offer && offer.buyer}" */}
                                    </div>
                                    </Modal.Body>

                                    <Modal.Footer>
                                    {/* <Button className='btn btn-default' onClick={this.closeModel}>Cancel</Button> */}
                                    <Button onClick={() => this.closeOfferDeleteModal()} className="btn red-mint">
                                        Cancel
                                    </Button>
                                    <button type="submit" onClick={() => this.deleteOffer()} className="btn green uppercase">
                                        Confirm
                                    </button>
                                    </Modal.Footer>
                                </Modal>

                                {/* Offer Settelment Dialog */}
                                <Modal show={this.state.showOfferSettelmentDialog} onHide={() => this.closeOfferSettelmentModal()}>
                                    <Modal.Header closeButton className="theme-bg">
                                    <Modal.Title>SETTLEMENT DATE</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                    <form onSubmit={this.props.handleSubmit(settlementFormSubmit)}>
                                        <div>
                                            Specify settlement date for this offer
                                            <Field name="sett_date" id="sett_date" autocomplete="off" component={DatePicker} validate={[required]} />
                                            {/* <input name="last_sold_date" id="last_sold_date" autocomplete="off" component={DatePicker} validate={[required]} /> */}
                                            <Button onClick={() => this.closeOfferSettelmentModal()} className="btn red-mint"> Cancel </Button>
                                            <button style={{margin: "10px"}} type="submit" className="btn green uppercase"> Set and Accept Offer </button>
                                        </div>
                                    </form>
                                    </Modal.Body>

                                    <Modal.Footer>
                                        {/* <Button onClick={() => this.closeOfferSettelmentModal()} className="btn red-mint"> Cancel </Button>
                                        <button type="submit" onClick={() => this.acceptOffer()} className="btn green uppercase"> Set and Accept Offer </button> */}
                                    </Modal.Footer>
                                </Modal>
                            </Table>
                                <div style={{float:"right"}}>
                                <Pagination
                                    activePage={parseInt(this.state.activePage)}
                                    itemsCountPerPage={window.limit}
                                    totalItemsCount={this.state.totalItemsCount}
                                    pageRangeDisplayed={3}
                                    onChange={this.handlePageChange}
                                />
                             </div>                         
                        </div>
                    }
                </div>
                </div>
            </div>
        )
    }

    /**PAGINATION */
    handlePageChange(eventKey){
        this.setState({ activePage: eventKey });
        this.getOffers({
            page: eventKey ? eventKey : 1,
            searchQuery: this.state.searchQuery ? this.state.searchQuery : '',
        });
        /**to set query in route */
        this.props.history.push({
            search: '?page=' + eventKey
        })
    }
    /**SEARCH */
    search(e) {
        /**to remove Event Pooling  */
        e.persist();
        let searchedValue = e.target.value;
        let seracherror = ValidateOnlyAlpha(searchedValue);
        if (seracherror) {
            this.setState({ seracherror: seracherror });
            return;
        }

        if (!searchedValue.length) {
            this.setState({ searchQuery: '', seracherror: '', activePage: 1 });
            this.props.history.push({
                search: '?page=' + 1
            });
        } else if (searchedValue.length > 2) {
            this.props.history.push({
            search: '?page=' + 1
        });
        this.setState({ searchQuery: searchedValue, activePage: 1, seracherror: seracherror });
        } else {
            this.setState({ seracherror: 'Enter minimum 3 characters to search.', searchQuery: '' });
            return;
        }
    
        clearTimeout(timer);
        timer = setTimeout(() => {
        this.getOffers({
            page: this.state.activePage ? this.state.activePage : 1,
            searchQuery: this.state.searchQuery,
            filter: this.state.filter ? this.state.filter : 2
        });
        }, 1000);
    }

    exportToCSV() {
        console.log('Export to CSV Started');
        this.setState({  isLoading: true });

        HTTP.Request("post", window.admin.offersListCSV)
        .then((response) =>{
            console.log(response, 'Offers List');
            this.setState({  isLoading: false });
            const headersObj = {
                buyer: response.data[0].user,
                created_at: response.data[0].receivedOn,
                emd_amount: response.data[0].emd,
                price: response.data[0].price,
                property: {street_address: response.data[0].property},
                status: response.data[0].status
            }
            response.data[1].unshift(headersObj);

            const csvString = [
              ...response.data[1].map((item,i) => [
                item.buyer,
                !i ? item.created_at :  moment(item.created_at).format("DD-MM-YYYY"),
                item.emd_amount,
                item.price,
                item.property.street_address.replace(/,/g, ' '),
                item.status,
              ]),
            ]
              .map((e) => e.join(','))
              .join('\n');
            window.open('data:text/csv;charset=utf-8,' + escape(csvString),"_self");
            console.log(csvString);
            
        }).catch((error)=> {
            console.log(error, 'Error while fetching offers CSV');
            this.setState({  isLoading: false });
        });
    }

    getOffers(params={}){
        this.setState({  isLoading: true });
        if(!params.page) {
            params.page = 1;
        }

        HTTP.Request("get",window.admin.offersList,params)
        .then((response) =>{
            console.log(response, 'Offers List'); 
             this.setState({
                array : response.data,
                isLoading:false,
                totalItemsCount: response.count
            });
        }).catch((error)=> {
            console.log(error, 'Error while fetching offers');
            this.setState({  isLoading: false });
        });
    }

    setSettlementDate(data) {
        console.log(this.state.offer_id, 'Selected Offer ID', data);
        this.setState({  isLoading: true });

        HTTP.Request('post', window.admin.offerSettlement, {offer_id: this.state.offer_id, settlement_date: data.sett_date, page: 1,}).then((result) => {
            console.log(result);
            this.setState({  showOfferSettelmentDialog: false, isLoading: false });
            toast.success(result.message);
            this.acceptOffer();
        }).catch((error) => {
            this.setState({  isLoading: false });
            toast.error(error.message || 'Some Error');
        });
    };

    acceptOffer() {
        this.setState({  isLoading: true });

        HTTP.Request('get', window.admin.acceptOffers, {offer_id: this.state.offer_id,page: 1,}).then((result) => {
            console.log(result);
            this.setState({  isLoading: false });
            toast.success(result.message);
            this.getOffers({
                page: this.state.activePage,
                searchQuery: this.state.searchQuery ? this.state.searchQuery : '',
            });
        }).catch((error) => {
            this.setState({  isLoading: false });
            toast.error(error.message || 'Some Error');
        });
    };

    declineOffer(data) {
        this.setState({  isLoading: true });
        HTTP.Request('get', window.admin.declineOffers, {offer_id: data,page: 1,}).then((result) => {
            console.log(result);
            this.setState({  isLoading: false });
            toast(result.message, {type: 'error'});
            this.getOffers({
                page: this.state.activePage,
                searchQuery: this.state.searchQuery ? this.state.searchQuery : '',
            });
        }).catch((error) => {
            this.setState({  isLoading: false });
            toast.error(error.message || 'Some Error');
        });
    };

    deleteOffer() {
        HTTP.Request('delete', window.admin.deleteOffers + '?offer_id=' + this.state.offer_id)
        .then((result) => {
            toast(result.message, {type: 'error'});
            this.setState({ showOfferDelete: false });
            this.getOffers({
                page: this.state.activePage,
                searchQuery: this.state.searchQuery ? this.state.searchQuery : '',
            });
        }).catch((error) => {
            this.setState({ showOfferDelete: false });
            toast.error(error.message || 'Some Error');
        });
    };

    showOfferDeleteModal(data) {
        /*show popup and confirm before delete*/
        this.setState({ showOfferDelete: true, offer_id: data });
    };
    closeOfferDeleteModal() {
        this.setState({ showOfferDelete: false });
    };
    showOfferSettelmentModal(data) {
        /*show popup and confirm before delete*/
        this.setState({ showOfferSettelmentDialog: true, offer_id: data });
    };
    closeOfferSettelmentModal() {
        this.setState({ showOfferSettelmentDialog: false });
    };
}
// export default TableComp;

let settelmentOffer = reduxForm({
    form: 'settelmentOffer',
  })(TableComp);
  const mapStateToProps = (state) => {
    return {
      state,
    };
  };
export default connect(mapStateToProps)(settelmentOffer);
  