import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from "moment-timezone";
import Moment from "react-moment";

class ROW extends Component {
    
    constructor(props){
        super(props);
        this.currencyFormat = this.currencyFormat.bind(this);
    }
    
    render() {
        const {cms} = this.props;
        return (
          <tr
            className="odd gradeX"
            style={{
              backgroundColor: cms.status == 'DECLINED' ? '#f7d0d0' : '',
            }}
          >
            <td>
              <p>
                <Link to={'/update-property/' + cms.property_id}>
                  {cms.property.street_address}
                </Link>
              </p>
              <p>
                <i>Buyer Company :</i>{' '}
                <span>{cms.buyer_company ? cms.buyer_company : 'N/A'}</span>
              </p>
              <p>
                <i>Buyer Name :</i> <span>{cms.buyer && cms.buyer}</span>
              </p>
              {cms.proof_of_fund && cms.proof_of_fund.url && (
                <p>
                  <i>
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href={cms.proof_of_fund.url}
                    >
                      Download proof of funds
                    </a>
                  </i>
                </p>
              )}
            </td>
            <td>
              {cms.customer && (
                <Link to={'/users-edit/' + cms.customer_id || ''}>
                  {cms.customer &&
                    cms.customer.firstname + ' ' + cms.customer.lastname}
                </Link>
              )}
            </td>
            <td>{cms.price ? '$' + this.currencyFormat(cms.price) : '-'}</td>
            <td>
              {cms.emd_amount ? '$' + this.currencyFormat(cms.emd_amount) : '-'}
            </td>
            <td>{cms.status}</td>
            <td>
              <Moment format="MM/DD/YY">
                {moment(cms.created_at).zone('-04:00')}
              </Moment>
            </td>
            <td className="text-center">
              {cms && cms.status === 'NEW' ? (
                <>
                  <a
                    id={cms._id}
                    className="mr-10"
                    title="Accept Offer"
                    onClick={() => this.props.showOfferSettelmentModal(cms._id)}
                  >
                    <i className="fa fa-thumbs-up"></i>
                  </a>
                  <a
                    id={cms._id}
                    className="mr-10"
                    title="Decline Offer"
                    onClick={() => this.props.declineOffer(cms._id)}
                  >
                    <i className="fa fa-thumbs-down"></i>
                  </a>
                  <a
                    id={cms._id}
                    className="mr-10"
                    title="Delete Offer"
                    onClick={() => {
                      this.props.showOfferDeleteModal(cms._id);
                    }}
                  >
                    <i className="fa fa-trash"></i>
                  </a>
                </>
              ) : cms && cms.status === 'ACCEPTED' ? (
                <>
                  <a
                    id={cms._id}
                    className="mr-10"
                    title="Decline Offer"
                    onClick={() => this.props.declineOffer(cms._id)}
                  >
                    <i className="fa fa-thumbs-down"></i>
                  </a>
                  <a
                    id={cms._id}
                    className="mr-10"
                    title="Delete Offer"
                    onClick={() => {
                      this.props.showOfferDeleteModal(cms._id);
                    }}
                  >
                    <i className="fa fa-trash"></i>
                  </a>
                </>
              ) : cms && cms.status === 'DECLINED' ? (
                <a
                  id={cms._id}
                  className="mr-10"
                  title="Delete Offer"
                  onClick={() => {
                    this.props.showOfferDeleteModal(cms._id);
                  }}
                >
                  <i className="fa fa-trash"></i>
                </a>
              ) : (
                <>
                  <a
                    id={cms._id}
                    className="mr-10"
                    title="Accept Offer"
                    onClick={() => this.props.acceptOffer(cms._id)}
                  >
                    <i className="fa fa-thumbs-up"></i>
                  </a>
                  <a
                    id={cms._id}
                    className="mr-10"
                    title="Decline Offer"
                    onClick={() => this.props.declineOffer(cms._id)}
                  >
                    <i className="fa fa-thumbs-down"></i>
                  </a>
                  <a
                    id={cms._id}
                    className="mr-10"
                    title="Delete Offer"
                    onClick={() => {
                      this.props.showOfferDeleteModal(cms._id);
                    }}
                  >
                    <i className="fa fa-trash"></i>
                  </a>
                </>
              )}
            </td>
          </tr>
        );
    }

    currencyFormat(num) {
        return num && num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
}

export default ROW;

