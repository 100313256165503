import React, { Component } from "react";
import { connect } from "react-redux";
import RenderFiled from "../common/renderField";
import { Tabs, Tab } from "react-bootstrap";
import { Field, reduxForm } from "redux-form";
import Loader from "../common/loader";
import PageHeader from "../common/pageheader";
import HTTP from "../../services/http";
import LocationSettings from './element/locationSettings';
import { toast } from 'react-toastify';
import { push } from 'react-router-redux';

import {
  required,
  ValidateOnlyAlpha,
  mobileValidate,
  emailValiadte
} from "../common/fieldValidations";
import {  createTextMask } from 'redux-form-input-masks';
import Offers from "../property/element/offers";

const phoneMask = createTextMask({
  pattern: '(999)999-9999',
});

class AddBuyersModule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: '',
      isLoading: false,
      formAction: 'ADD',
      status: false,
      showOfferDelete: false,
      offers: [],
      isRedirect: false,
      offerSetllementDialogFlag: false,
    };
    /**event binding  */
    this.upsertCMS = this.upsertCMS.bind(this);
    this.getaCMS = this.getaCMS.bind(this);
    this.setSettlementDate = this.setSettlementDate.bind(this);

    /*  this.getFile = this.getFile.bind(this); */
  }

  getPropOffers() {
    HTTP.Request('get', window.admin.getPropertyOffers, {
      property_id: this.props.match.params._id,
      page: 1,
    }).then((result) => {
      this.setState({ offers: result.data ? result.data : [] });
    });
  }
  setSettlementDate(data) {
    this.setState({ isLoading: true });
    let param = {
      offer_id: this.state.offer_id,
      ...data,
    };
    HTTP.Request('post', window.admin.offerSettlement, param)
      .then((result) => {
        console.log(result);
        this.setState({ offerSetllementDialogFlag: false, isLoading: false });
        toast.success(result.message);
        this.acceptOffer();
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        toast.error(error.message || 'Some Error');
      });
  }
  acceptOffer = () => {
    HTTP.Request('get', window.admin.acceptOffers, {
      offer_id: this.state.offer_id,
      page: 1,
    }).then((result) => {
      toast.success(result.message);
      this.getPropOffers();
    });
  };

  declineOffer = (data) => {
    HTTP.Request('get', window.admin.declineOffers, {
      offer_id: data,
      page: 1,
    }).then((result) => {
      toast(result.message, { type: 'error' });
      this.getPropOffers();
    });
  };

  deleteOffer = () => {
    HTTP.Request(
      'delete',
      window.admin.deleteOffers + '?offer_id=' + this.state.offer_id,
    )
      .then((result) => {
        this.getPropOffers();
        toast(result.message, { type: 'error' });
        this.setState({ showOfferDelete: false });
      })
      .catch((error) => {
        this.setState({ showOfferDelete: false });
      });
  };
  componentDidMount() {
    // const cmsID = this.props.match.params._id ? this.props.match.params._id : null;
    // if (cmsID){this.getaCMS();}
    this.getaCMS();
    this.getPropOffers();
  }
  render() {
    const { handleSubmit } = this.props;
    const { isLoading, formAction } = this.state;
    return (
      <div className="relative">
        {isLoading ? (
          <Loader />
        ) : (
          <div>
            <PageHeader
              route={formAction === 'ADD' ? 'Add Buyers' : 'Edit Buyers'}
              parent="Buyers"
              parentRoute="/customers"
            />

            <div className="tab-pane active">
              <Tabs defaultActiveKey={1} animation={false} id="profileTabs">
                <Tab
                  eventKey={1}
                  title={
                    formAction === 'ADD' ? 'Add New Buyers' : 'Edit Buyers'
                  }
                >
                  <form onSubmit={handleSubmit(this.upsertCMS)}>
                    <Field
                      name="email"
                      fieldName="Email*"
                      type="text"
                      component={RenderFiled}
                      validate={[required]}
                    />
                    <Field
                      name="firstName"
                      fieldName="First Name*"
                      type="text"
                      component={RenderFiled}
                      validate={[required]}
                    />
                    <Field
                      name="lastName"
                      fieldName="Last Name*"
                      type="text"
                      component={RenderFiled}
                      validate={[required]}
                    />
                    <Field
                      name="companyName"
                      fieldName="Company Name*"
                      type="text"
                      component={RenderFiled}
                      validate={[required]}
                    />
                    <Field
                      name="phone"
                      fieldName="Mobile Phone*"
                      type="text"
                      {...phoneMask}
                      component={RenderFiled}
                      validate={[required]}
                    />
                    {/* <Field name="leadId" fieldName="SalesForce Lead Id*" type="text" component={RenderFiled} validate={[required]} /> */}
                    <Field
                      name="password"
                      fieldName="Password*"
                      type="password"
                      component={RenderFiled}
                      validate={[required]}
                    />
                    <Field
                      name="confirmPassword"
                      fieldName="Password confirmation*"
                      type="password"
                      component={RenderFiled}
                      validate={[required]}
                    />
                    {/* <Field name="notes" fieldName="Notes*" type="text" component={FroalaEditorComp} validate={[required]} />  */}
                    <Field
                      name="notes"
                      fieldName="Notes"
                      type="text"
                      component={RenderFiled}
                      textarea="true"
                    />
                    {/* <Field name="VIPBuyer" fieldName="VIP Buyer" type="checkbox" component={RenderFiled} /> */}
                    <div className="form-actions">
                      <button
                        type="submit"
                        className="btn green uppercase"
                        disabled={this.props.invalid || this.props.submitting}
                      >
                        {formAction === 'ADD' ? 'Add' : 'Update'}
                      </button>
                    </div>
                  </form>
                </Tab>
                {/* <Tab eventKey={2} title="Inquiries">inquiries</Tab> */}
                <Tab eventKey={2} title="Offers">
                  <Offers
                    offers={this.state.offers}
                    acceptOffer={this.acceptOffer}
                    declineOffer={this.declineOffer}
                    deleteOffer={this.deleteOffer}
                    showOfferDeleteModal={this.showOfferDeleteModal}
                    closeOfferDeleteModal={this.closeOfferDeleteModal}
                    showOfferDelete={this.state.showOfferDelete}
                    isRedirect={this.state.isRedirect}
                    offerSetllementDialogFun={this.offerSetllementDialogFun}
                    offerSetllementDialogFlag={
                      this.state.offerSetllementDialogFlag
                    }
                    closeOfferSettelmentModal={this.closeOfferSettelmentModal}
                    setSettlementDate={this.setSettlementDate}
                  />
                </Tab>
                {/* <Tab eventKey={4} title="Open Houses">open-houses</Tab> */}
                {/* <Tab eventKey={3} title="Notification Settings">notification-settings</Tab> */}
                {/* <Tab eventKey={6} title="Locations Settings">
                  <LocationSettings></LocationSettings>
                </Tab> */}
              </Tabs>
            </div>
          </div>
        )}
      </div>
    );
  }
  setSettlementDate(data) {
    this.setState({ isLoading: true });
    let param = {
      offer_id: this.state.offer_id,
      ...data,
    };
    HTTP.Request('post', window.admin.offerSettlement, param)
      .then((result) => {
        console.log(result);
        this.setState({ offerSetllementDialogFlag: false, isLoading: false });
        toast.success(result.message);
        this.acceptOffer();
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        toast.error(error.message || 'Some Error');
      });
  }
  offerSetllementDialogFun = (data) => {
    // document.getElementById('settlement_date_input').value="";
    this.setState({ offerSetllementDialogFlag: true, offer_id: data });
    console.log(this.state.offerSetllementDialogFlag, 'Flag Offer', data);
  };
  closeOfferSettelmentModal = () => {
    this.setState({ offerSetllementDialogFlag: false });
  };
  showOfferDeleteModal = (data) => {
    /*show popup and confirm before delete*/
    this.setState({ showOfferDelete: true, offer_id: data });
  };
  closeOfferDeleteModal = () => {
    this.setState({ showOfferDelete: false });
  };
  upsertCMS(data) {
    console.log('data', data);
    this.setState({
      isLoading: true,
    });
    // const { match } = this.props;
    // this.setState({isLoading: true});
    // const {editID}=this.state;
    HTTP.Request('post', window.admin.AddNewBuyer, data)
      .then((result) => {
        this.setState({
          isLoading: false,
        });

        console.log(result);

        this.props.dispatch(push('/customers'));
        toast(result.message, {
          type: 'success',
        });
      })
      .catch((err) => {
        toast('Something went wrong', {
          type: 'error',
        });
        this.setState({
          isLoading: false,
        });

        /*  toast(err.message, { type: "error" }); */
      });
  }
  getaCMS() {
    const { match, initialize } = this.props;
    /*extract plant id from request*/
    let cmsID = match.params._id ? match.params._id : null;
    if (cmsID) {
      this.setState({ isLoading: true, formAction: 'EDIT' });
      HTTP.Request('get', window.admin.GetSingleBuyerDetails, {
        id: cmsID,
      })
        .then((result) => {
          console.log('Result is', result.data);
          initialize(result.data.email);
          this.setState({
            isLoading: false,
            // status: result.data.status,
            // editID: result.data._id ? result.data._id : '',
          });
          /*set data to form*/
        })
        .catch((error) => {
          this.setState({ isLoading: false });
        });
    }
  }
}

let NewBuyerForm = reduxForm({
  form: 'NewBuyerForm',
})(AddBuyersModule);

export default connect()(NewBuyerForm);
