import React, { Component } from 'react';
import { Alert, HelpBlock, Table } from "react-bootstrap";
import Pagination from "react-js-pagination";
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import HTTP from '../../../../services/http';
import { ValidateOnlyAlpha } from "../../../common/fieldValidations";
/**COMPONENTS */
import Loader from "../../../common/loader";
import ROW from "./row";

var timer;
class TableComp extends Component {

    constructor(props){
        super(props);
        this.state ={
            array:[],
            isLoading:false,
            isSyncing: false,
            searchQuery :'',
            activePage :1,
            totalItemsCount:1
        }
        /**event binding */
        this.getAuthors = this.getAuthors.bind(this);
        this.search = this.search.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.delete = this.delete.bind(this);

    }

    componentWillMount(){
        this.getAuthors();
    }
    render() {
        const {array ,isLoading,seracherror} = this.state;
        return (
            <div >
                {isLoading &&<Loader />}

                <div className="portlet light bordered">
                    <div className="portlet-body min-heigh">

                        {/* actions search addnew  */}
                        <div className="table-toolbar">
                            <div className="row">


                                {/* <!-- search --> */}
                                <div className="col-md-3">
                                    <div className="btn-group pull-right">
                                        <input type="text" className="form-control" id="search_field" placeholder="Search Author(s)" onChange={this.search} />
                                        <HelpBlock style={{ color: '#e73d4a' }}>
                                            {seracherror ? seracherror : null}
                                        </HelpBlock>
                                    </div>
                                </div>

                                {/* <!-- add new --> */}
                                <div className="col-md-5 col-sm-offset-4">
                                    <div className="btn-group pull-right">
                                        <Link to="/author-management/add">
                                            <button id="sample_editable_1_new" className="btn sbold green"> Add New
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* actions search addnew END */}


                        {/* if list is empty */}
                        {!isLoading && !array.length ? <Alert bsStyle="warning">
                            <strong>No Data Found !</strong>
                        </Alert>:
                            <div style={{ display: "flow-root"}}>
                                <Table responsive striped bordered condensed hover>
                                <thead>
                                    <tr>
                                        <th> Name </th>
                                        <th> Email </th>
                                        <th> Mobile NO. </th>
                                        <th> Modified On </th>
                                        <th> Created On </th>
                                        <th> Status </th>
                                        <th width='15%'> Actions </th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {array.map(element => {
                                        return (
                                            <ROW key={element._id} admin={element} delete={this.delete} />
                                        )
                                    })}
                                </tbody>
                            </Table>
                                <div style={{float:"right"}}>
                                <Pagination
                                    activePage={this.state.activePage}
                                    itemsCountPerPage={window.limit}
                                    totalItemsCount={this.state.totalItemsCount}
                                    pageRangeDisplayed={3}
                                    onChange={this.handlePageChange}
                              />
                             </div>
                        </div>
                    }
                    </div>
                </div>
            </div>
        );
    }

    /**PAGINATION */
    handlePageChange(eventKey){
        this.setState({ activePage: eventKey });
        this.getAuthors({
            page: eventKey ? eventKey : 1,
            searchQuery: this.state.searchQuery ? this.state.searchQuery : '',
        });
        /**to set query in route */
        this.props.history.push({
            search: '?page=' + eventKey
        })
    }
    /**SEARCH */
    search(e) {
        /**to remove Event Pooling  */
        e.persist();
        let searchedValue = e.target.value;
        let seracherror = ValidateOnlyAlpha(searchedValue)
        if (seracherror) {
            this.setState({ seracherror: seracherror });
            return;
        }

        if (!searchedValue.length) {
            this.setState({ searchQuery: '', seracherror: '', activePage: 1 });
        } else if (searchedValue.length > 2) {
            this.props.history.push({
            search: '?page=' + 1
        });
        this.setState({ searchQuery: searchedValue, activePage: 1, seracherror: seracherror });
        } else {
            this.setState({ seracherror: 'Enter minimum 3 characters to search.', searchQuery: '', activePage: 1 });
            return;
        }
    
        clearTimeout(timer);
        timer = setTimeout(() => {
        this.getAuthors({
            page: this.state.activePage ? this.state.activePage : 1,
            searchQuery: this.state.searchQuery
        });
        }, 1000);
    }

    /**to get list of roles */
    getAuthors(params={}){
        /**to start Loader */
        this.setState({  isLoading: true })
        HTTP.Request("get",window.admin.getAuthors,params)
        .then((response) =>{
             this.setState({
                array : response.data.list,
                isLoading:false,
                totalItemsCount: response.data.count
            })
        }).catch(err=>{
            this.setState({  
                array : [],
                isLoading:false,
                totalItemsCount: 0
            })
        })
    }

     /* delete */
     delete(id){
         let data={
             editId:id,
             trash:true
         };

        let formData = new FormData()
        formData.append('data', JSON.stringify(data));
        HTTP.Request("post", window.admin.addEditAuthor,formData)
        .then(result => {
        this.getAuthors({
            page:1
        })
        toast(result.message,{type:"success"});
        })
        .catch(err => {
             toast(err.message, { type: "error" }) ;
         })

        /*  id.trash=true;
        HTTP.Request("post", window.admin.addEditUser, id)
        .then(result => {
            this.props.dispatch(push("/user-management"));
            toast(result.message,{type:"success"});


            this.props.dispatch({
                type: ADMIN_TRACK_AUDIT_LOGS,
                action: {
                    comment: "Modified details of Admin User - " + (result.data.firstname + ' ' + result.data.lastname + ' (' + result.data.email+')' ),
                    type: "audit"
                }
            });
        })
        .catch(err => {
            if(err && err.error && err.error.length>0)
            err.error.map(message => toast(message,{type:"error"})  )
            else toast(err.message, { type: "error" }) ;
        }) */

    }


}

export default TableComp ;
