import React, { Component } from "react";
import Moment from "react-moment";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
import Modal from "../../common/modal";
import TT from "../../common/tooltip";

class ROW extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false
        };
       
          
        /*bind this with methods*/
        this.showModal = this.showModal.bind(this);
        this.deleteRow = this.deleteRow.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }
    render() {
        const { element } = this.props;
        const divStyle = {
            wordBreak:'break-all',
        
          };
        return (
            <tr className="odd gradeX">
                <td width="10%"  style={divStyle}> {element.name} </td>
                <td width="10%"  style={divStyle}> {element.title.slice(0,20)} </td>
                <td width="10%" style={divStyle} > {element.content.slice(0,95) + "..."} </td>
                <td width="10%">
                <td><Moment format="MM/DD/YY">{moment(element.created_at).zone("-04:00")}</Moment></td>

                    {/* <Moment format="MM/DD/YY">{element.created_at}</Moment> */}
                </td>
                <td className="center" width="10%">
                    {" "}
                    <label
                        className={
                            element.status
                                ? "label label-info"
                                : "label label-danger"
                        }
                    >
                        {element.status ? "Active" : "Inactive"}
                    </label>{" "}
                </td>
                <td>
                    <TT tooltip="Remove">
                        <a
                            className=" btn btn-xs red-mint"
                            onClick={this.showModal}
                        >
                            <i className="fa fa-trash no-pointer" />
                        </a>
                    </TT>
                    <TT tooltip="Edit">
                        <Link
                            to={"/review/edit/" + element._id}
                            className=" btn btn-xs grey-mint"
                        >
                            <i className="fa fa-pencil-square-o" />
                        </Link>
                    </TT>
                    {this.state.show && (
                        <Modal
                            show={true}
                            func={this.deleteRow}
                            closeParentModal={this.closeModal}
                            title={element.title}
                        />
                    )}
                </td>
            </tr>
        );
    }

    showModal() {
        /*show popup and confirm before delete*/
        this.setState({ show: true });
    }

    deleteRow() {
        this.setState({ isLoading: true, show: false });
        /*delete row by call prop func*/
        this.props.delete(this.props.element._id);
    }

    closeModal() {
        this.setState({ show: false });
    }
}

export default ROW;
