import React, { Component } from 'react';
import RenderField from '../common/renderField';
import { connect } from 'react-redux'
import HTTP from "../../services/http";
import renderHTML from 'react-render-html';
import Loader from "../common/loader";
import { toast } from "react-toastify";
import xlsx from 'xlsx';
import { push } from 'react-router-redux';
import { ADMIN_TRACK_AUDIT_LOGS } from '../common/actions';
import { Modal, Button,Alert } from 'react-bootstrap';


class PagePreview extends Component {
    constructor(props) {
        super(props);

        this.state = {
            someHTML: '',
            styling: `.parallax8 {
        height: 70vh;
        max-height: 560px;
        text-align: center;
        position: relative !important;
        padding: 100px 0;
    background-size: cover !important;
    min-height: 200px;
    }
    
    .page-title-heading {
        position: absolute;
        margin: 0 auto;
        padding: 0px 10px;
        width: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .container930-inner {
        max-width: 950px;
    margin: 0 auto;
    padding-right: 15px;
    padding-left: 15px;
    }
    
    .page-title-heading h1 {
        color: #fff;
        font-size: 43px;
        font-weight: 600;
        line-height: 63px;
        font-family: merriweather;
        /* margin-top: 10%; */
    }
    
    
    
    .our_story_para_cont {
        padding-left: 50px;
        padding-right: 50px;
        padding-bottom: 50px;
    }
    
    .content_inner .col-sm-7,
    .content_inner .col-sm-9 {
        padding-left: 0px;
    }
    
    .mid_cont {
        padding-top: 25px;
    }
    
    .content_inner h3 {
        line-height: 24px;
        font-size: 22px !important;
        color: #292e31;
        padding-top: 20px;
        padding-bottom: 12px;
        margin-bottom: 0px;
    }
    
    .content_inner li {
        color: #6f7c82;
    }
    
    .common_content p {
        font-size: 16px;
        line-height: 27px;
        color: #6f7c82;
        margin-bottom: 0;
    }
    .common_content h3 
    {
      font-family: Arial,Helvetica Neue,Helvetica,sans-serif;
      line-height: 24px;
    font-size: 22px
    }
    
    .content_inner a {
        color: #ff7e18;
    }
    
    .pull-right {
        float: right;
    }
    
    .fr-fic {
        margin-left: 0;
        margin-bottom: 0px;
        margin-right: 20px;
        margin-top: 8px;
    }
    
    .fr-fil {
        float: left;
    }
    
    .fr-fir {
        float: right;
    }
    
    .img-space {
        margin-bottom: 0px !important;
    }
    
    .img_left {
        margin-left: 0;
        margin-bottom: 20px;
        display: inline-block;
        float: left;
        margin-right: 10px;
    }
    @font-face {
      font-display: swap;
      font-family: 'Gotham';
      font-style: normal;
      font-weight: 500;
      src: local('?'), url('./assets/fonts/Gotham-Medium.woff2') format('woff2'), url('./assets/fonts/Gotham-Medium.woff') format('woff');
  }
  
  @font-face {
      font-display: swap;
      font-family: merriweather;
      src: local('?'), url('https://res.cloudinary.com/house-buyers-of-america/raw/upload/v1674047626/FE_assets_new/Merriweather-Regular.otf');
  }
  
  @font-face {
      font-display: swap;
      font-family: helvetica;
      src: local('?'), url('https://res.cloudinary.com/house-buyers-of-america/raw/upload/v1674047627/FE_assets_new/Helvetica-Normal.ttf');
  }

  .body-imp {
    font-family: Arial, Helvetica Neue, Helvetica, sans-serif !important;
    /* font-family: Helvetica; */
    overflow-x: hidden;
    position: relative;
    padding:3px;
}
    
    .pad-bot50 {
        padding-bottom: 50px;
    }
    
    .image-position {
        margin-top: 6px !important;
    }
    
    @media (max-width: 767px) {
        .get_an_offer h1 {
            line-height: 30px;
            font-size: 24px;
            margin-bottom: 0px;
            padding: 0px 10px 19px 10px;
        }
    
        .our_story_para_cont {
            padding-left: 10px;
            padding-right: 10px;
            padding-bottom: 30px;
        }
    
        .parallax8 {
            padding-top: 50px;
            margin:0px
        }
    
        .page-title-heading h1 {
            font-size: 25px;
            /* margin-top: 40%; */
        }
    }
    
    @media (max-width: 639px) {
        /*.page-title-heading h1 {
        line-height: 30px;
        margin-top: 40%;
    
      }
       .page-title-heading{top:30%} */
    }
    
    @media only screen and (max-width: 500px) {
        .fr-fil {
            display: block;
            margin: 0 auto !important;
            margin-bottom: 15px;
            float: none;
        }
    
        .fr-fir {
            display: block;
            margin: 0 auto !important;
            float: none;
            margin-bottom: 15px;
        }
    
        .mobimgWidth {
            max-width: 100%;
        }
    
        .setspace img {
            padding-bottom: 20px;
        }
    
        /*.page-title-heading h1 {
    
        margin-top: 40%;
      }
       .page-title-heading {top:30%} */
    }
    
    @media (max-width: 479px) {
        .content_inner h3 {
            font-size: 17px !important;
        }
    
        .img_left {
            margin-left: 0;
            margin-bottom: 20px;
            max-width: 100%;
            display: block;
            float: left;
            margin-right: 10px;
        }
    
        .img-space {
            margin-bottom: 0 px !important;
        }
    
        .mid_cont {
            padding-top: 10px;
        }
    
        .mob-img {
            max-width: 100% !important;
            width: 100% !important;
            padding-bottom: 15px !important;
        }
    
        .page-title-heading {
            top: 24%;
        }
    
        .page-title-heading h1 {
            font-size: 25px;
            line-height: 35px;
            margin-top: 0;
        }
    }
    
    
    /* customer sotires */
    
    .mb_5 {
        margin-bottom: 5px;
    }
    
    .mb_10 {
        margin-bottom: 10px;
    }
    
    .mb_15 {
        margin-bottom: 15px;
    }
    
    .mb_20 {
        margin-bottom: 20px;
    }
    
    .mt_5 {
        margin-bottom: 5px;
    }
    
    .mt_10 {
        margin-bottom: 10px;
    }
    
    .mt_15 {
        margin-bottom: 15px;
    }
    
    .mt_20 {
        margin-bottom: 20px;
    }
    
    
    /*  */
    
    .inner_content.inner_contWrap h1,
    .inner_content.inner_contWrap h3,
    .inner_content.inner_contWrap h4 {
        display: block;
        clear: both;
    }
    
    .inner_content.inner_contWrap p {
        margin-bottom: 15px !important;
        margin: 0;
    }
    
    .inner_content.inner_contWrap h1,
    .inner_content.inner_contWrap h3:after,
    .inner_content.inner_contWrap h4 {
        content: '';
        display: block;
        clear: both;
    }
    
    .inner_content.inner_contWrap h1,
    .inner_content.inner_contWrap h4 {
        padding: 10px 0px 0px;
        margin-bottom: 8px;
    }
    
    .inner_content.inner_contWrap h3 {
        padding: 0;
        margin-bottom: 8px !important;
        margin: 0;
    }
    
    .inner_content.inner_contWrap p img {
        float: left;
        width: auto;
        margin-top: 3px;
        margin-right: 20px;
    }
    
    @media (max-width: 500px) {
        .inner_content.inner_contWrap p img {
            float: none;
            display: block;
            margin: 0 auto 15px !important;
        }
    }
    
    .relInnsec {
        position: relative;
        padding-left: 320px;
    }
    
    .relInnsec .lft {
        position: absolute;
        top: 0;
        left: 0;
    }
    
    @media screen and (max-width: 767px) {
        .relInnsec {
            padding-left: 0;
        }
    
        .lft img {
            width: 100%;
        }
    
        .relInnsec .lft {
            position: relative;
        }
    
        .innerDesktopView {
            display: none;
        }
    
        .innerMobileView {
            display: block !important;
        }
    }
    
    .innerMobileView {
        display: none;
    }
    
    .h1-style {
        width: 100%;
        font-weight: normal
    }
    
    .remove-padding {
        padding: 0;
    }

    img.fr-fic {
        margin: 5px auto;
        display: block;
        float: none;
        vertical-align: top;
    }
    
    img.fr-dib.fr-fil {
        margin-left: 0;
        text-align: left;
    }
    
    img.fr-dib.fr-fir {
        margin-right: 0;
        text-align: right;
    }

    img.fr-dii {
        display: inline-block;
        float: none;
        vertical-align: bottom;
        margin-left: 5px;
        margin-right: 5px;
        max-width: calc(100% - 10px);
    }
    `,
            isLoading: true,
            pageData: {},
            base64data: ""
        }

        this.upsertCms = this.upsertCms.bind(this)
        this.closeModel = this.closeModel.bind(this);
    }


    componentDidMount() {
        const scope = this
        var reader = new FileReader();
        reader.onloadend = function () {
            scope.setState({
                base64data: reader.result,
            });
        }
        if (!!this.props.formData && !!this.props.formData.image && !this.props.formData.image.secure_url) { reader.readAsDataURL(this.props.formData.image) }
        if (!!this.props) {
            this.setState({
                pageData: this.props.formData
            })
        }
    }

    render() {
        const { pageData, styling, base64data } = this.state;
        return (
            <div>
                <div className='body-imp' style={{ border: "1px solid" }}
                >
                    {!!pageData && pageData.showImage == 'DefaultImage' && <div className="parallax parallax8" style={{ 'background': 'url(	https://www.housebuyersofamerica.com/about_banner.jpg) no-repeat' }}>
                        <div className="page-title-heading">
                            <h1>{!!pageData.title ? pageData.title : ""}</h1>
                        </div>
                    </div >}

                    {!!pageData && pageData.showImage == 'UploadImage' && <div className="parallax parallax8" style={{ 'background': `url(${!!base64data ? base64data : !!pageData.image && !!pageData.image.secure_url ? pageData.image.secure_url : ""}) no-repeat` }}>
                        <div className="page-title-heading">
                            <h1>{!!pageData.title ? pageData.title : ""}</h1>
                        </div>
                    </div >}

                    <div className="mid_cont maincont_paddbot_0" >
                        <div className="our_story_top_sec">
                            <div className="container container930-inner">
                                <div className="common_content content_inner pad-bot50">
                                <div dangerouslySetInnerHTML={!!pageData && !!pageData.content ? { __html: pageData.content } : { __html: `<div></div>` }} className="inner_content inner_contWrap get_an_offer">
                                    </div>
                                </div>
                            </div>
                        </div >
                    </div >
                    <style dangerouslySetInnerHTML={{ __html: styling }}></style>
                </div >
                <button
                    style={{ marginTop: "8px" }}
                    className="btn green uppercase"
                    disabled={this.props.submitting}
                    onClick={(e => {
                        this.upsertCms()
                    })}
                >Submit
                </button>
                <button
                    style={{ marginTop: "8px" ,marginLeft:"5px"}}
                        className="btn green uppercase"
                        disabled={this.props.submitting}
                        onClick={(e => {
                            this.closeModel()
                        })}
                    >Back to edit
                </button>
            </div>
        );
    }

    closeModel(){
        /**to close modal */
        this.props.closeParentModal();
    }

    upsertCms() {
        this.props.upsertData()
    }
}
export default PagePreview