import { createTextMask } from 'redux-form-input-masks';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { Field, reduxForm } from "redux-form";
import { Tab, Tabs } from "react-bootstrap";
import { push } from "react-router-redux";
import React, { Component } from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";

import { requireArray, required} from "../common/fieldValidations";
import MaterialMultiV1 from "../common/material_multi_v1";
import MaterialMulti from "../common/material_multi";
import DropdownComp from "../common/DropdownList";
import Offers from '../property/element/offers';
import RenderField from "../common/renderField";
import PageHeader from "../common/pageheader";
import { STATUS } from "../common/options";
import HTTP from "../../services/http";
import Loader from "../common/loader";
import './materialSelect.css';

const phoneMask = createTextMask({
  pattern: '999-999-9999',
});

class AddRealEstateInvestors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formAction: 'ADD',
      communication_type: [
        { name: 'SMS and Email', value: 1 },
        { name: 'Email', value: 2 },
        { name: 'Unsubscribe', value: 3 },
        { name: 'SMS', value: 4 },
      ],
      register: [
        { name: 'Yes', value: true },
        { name: 'No', value: false },
      ],
      isMobile: false,
      isEmail: false,
      UserType: '',
      user_type_option: [],
      selectedUsers: [],
      UserMarket: '',
      content: '',
      isLoading: false,
      isRenovated: true,
      isWholesale: true,
      status: true,
      selectdValue: '',
      renovated_market: [],
      wholesale_market: [],
      renovatedToSend: [],
      wholesaleToSend: [],
      countyList: [],
      defaultCountiesWholesale: [],
      defaultCountiesRenovated: [],
      showOfferDelete: false,
      offers: [],
      isRedirect: false,
      offerSetllementDialogFlag: false,
      defaultTabSelected: 1
    };
    this.upsertProp = this.upsertProp.bind(this);
    this.getProp = this.getProp.bind(this);
    this.getAgent = this.getAgent.bind(this);
    this.onChangeHoa = this.onChangeHoa.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.wholesaleValueChange = this.wholesaleValueChange.bind(this);
    this.onChangeMarket = this.onChangeMarket.bind(this);
    this.clearMarkets = this.clearMarkets.bind(this);
    this.setSettlementDate = this.setSettlementDate.bind(this);
    this.setDefaultCounties = this.setDefaultCounties.bind(this);
    this.triggerValidation = this.triggerValidation.bind(this);
    this.setSelectedTab = this.setSelectedTab.bind(this);
  }

  setSettlementDate(data) {
    this.setState({ isLoading: true });
    let param = {
      offer_id: this.state.offer_id,
      ...data,
    };
    HTTP.Request('post', window.admin.offerSettlement, param)
      .then((result) => {
        console.log(result);
        this.setState({ offerSetllementDialogFlag: false, isLoading: false });
        toast.success(result.message);
        this.acceptOffer();
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        toast.error(error.message || 'Some Error');
      });
  }

  getPropOffers() {
    HTTP.Request('get', window.admin.getPropertyOffers, {
      customer_id: this.props.match.params._id,
      page: 1,
    }).then((result) => {
      this.setState({ offers: result.data ? result.data : [] });
    });
  }

  acceptOffer = () => {
    HTTP.Request('get', window.admin.acceptOffers, {
      offer_id: this.state.offer_id,
      page: 1,
    }).then((result) => {
      toast.success(result.message);
      this.getPropOffers();
    });
  };

  declineOffer = (data) => {
    HTTP.Request('get', window.admin.declineOffers, {
      offer_id: data,
      page: 1,
    }).then((result) => {
      toast(result.message, { type: 'error' });
      this.getPropOffers();
    });
  };

  deleteOffer = () => {
    HTTP.Request(
      'delete',
      window.admin.deleteOffers + '?offer_id=' + this.state.offer_id,
    )
      .then((result) => {
        this.getPropOffers();
        toast(result.message, { type: 'error' });
        this.setState({ showOfferDelete: false });
      })
      .catch((error) => {
        this.setState({ showOfferDelete: false });
      });
  };
  async componentWillMount() {
    this.setState({isLoading:true})
    this.getPropOffers();
    let users = [
      { name: 'Buyer', value: 1 },
      { name: 'Realtor', value: 3 },
      { name: 'Investor', value: 2 },
    ];
    users = users.map((j) => {
      return { title: j.name, _id: j.value };
    });
    this.setState({
      user_type_option: users,
    });

    /* (async () => {
      await this.getAgent();
      await this.getProp();
    })(); */
    await this.getAgent();
    await this.getProp();

    let a = [1, 2];
    let optValue = [];
    users.map((value) => {
      a.map((newvalue) => {
        if (value._id === newvalue) {
          optValue.push(value.title);
        }
      });
    });

    /* Default selected tab code */
    const currentPath = window.location.pathname.split('/');
    if (currentPath.length == 4) {
      if (currentPath[3] == 'offers') {
        this.setState({defaultTabSelected: 2});
      } else {
        this.setState({defaultTabSelected: 1});
      }
    }
    this.setState({isLoading:false})
  }

  render() {
    const { handleSubmit } = this.props;
    const {
      isLoading,
      formAction,
      isEmail,
      marketImage,
      isRenovated,
      isWholesale,
      register,
      communication_type,
      selectedUsers,
      renovated_market,
      wholesale_market,
      countyList,
      form,
      defaultCountiesWholesale,
      defaultCountiesRenovated,
      defaultTabSelected
    } = this.state;
    return (
      <div className="relative">
        {isLoading ? (
          <Loader />
        ) : (
          <div>
            <PageHeader
              route={formAction === 'ADD' ? 'Add New User' : 'Edit New User'}
              parent="Users"
              parentRoute="/users"
            />

            <div>
              <Tabs defaultActiveKey={defaultTabSelected} animation={false} id="profileTabs" onSelect={this.setSelectedTab}>
                <Tab
                  eventKey={1}
                  title={formAction === 'ADD' ? 'Add New User' : 'Edit User'}
                >
                  <form onSubmit={handleSubmit(this.upsertProp)}>
                    <Field
                      name="firstname"
                      fieldName="First Name*"
                      type="text"
                      component={RenderField}
                      validate={[required]}
                    />
                    <Field
                      name="lastname"
                      fieldName="Last Name*"
                      type="text"
                      component={RenderField}
                      validate={[required]}
                    />

                    <Field
                      name="mobile"
                      fieldName="Mobile Phone"
                      type="text"
                      component={RenderField}
                      {...phoneMask}
                      /* validate={!isEmail && [required]}
                      onBlur={(e) => {
                        if (e.target.value == '') {
                          this.setState({ isEmail: false });
                        } else {
                          this.setState({ isEmail: true });
                        }
                      }} */
                    />
                    <Field
                      name="email"
                      fieldName="Email"
                      type="text"
                      component={RenderField}
                      /* validate={
                        !isEmail && [required, emailValiadte]
                      }
                      onBlur={(e) => {
                        if (e.target.value == '') {
                          this.setState({ isEmail: true });
                        } else {
                           this.setState({ isEmail: false });
                        }
                      }} */
                    />

                    <Field
                      options={this.state.user_type_option}
                      name="user_type"
                      selectedValues={selectedUsers}
                      label="UserType*"
                      component={MaterialMultiV1}
                      validate={[requireArray]}
                    />

                    <InputLabel id="demo-customized-select-label">
                      Are you Interested in Wholesale Listings?
                    </InputLabel>

                    <Select
                      labelId="demo-customized-select-label"
                      id="demo-customized-select"
                      name="register_wholesale"
                      value={isWholesale}
                      onChange={this.wholesaleValueChange}
                    >
                      {register.map((data) => {
                        return (
                          <MenuItem value={data.value}>{data.name}</MenuItem>
                        );
                      })}
                    </Select>

                    {this.state.isWholesale == true && marketImage && (
                      <Field
                        options={marketImage}
                        name="wholesale_market"
                        selectedValues={wholesale_market}
                        label="Wholesale Market*"
                        value={isWholesale}
                        component={MaterialMulti}
                        validate={[requireArray]}
                        updateMarket={this.onChangeMarket}
                      />
                    )}

                    {this.state.isWholesale == true &&
                      marketImage &&
                      this.state.countyList &&
                      Object.keys(this.state.countyList).length > 0 &&
                      this.state.wholesale_market.map((market) => (
                        <Field
                          name={`wholesale_${market}_counties`}
                          key={`wholesale_${market}_counties`}
                          label={`${market} Counties*`}
                          options={countyList[market]}
                          selectedValues={defaultCountiesWholesale[market]}
                          component={MaterialMulti}
                          validate={[requireArray]}
                          triggerValidation={(values) =>
                            this.triggerValidation(
                              values,
                              `wholesale_${market}_counties`,
                            )
                          }
                          updateCounty={(selectedCounties) =>
                            this.onChangeCounty(
                              'wholesale',
                              market,
                              selectedCounties,
                            )
                          }
                        />
                      ))}

                    <InputLabel id="demo-customized-select-label">
                      Are you Interested in Renovated Listings?
                    </InputLabel>

                    <Select
                      labelId="demo-customized-select-label"
                      id="demo-customized-select"
                      name="register_renovated"
                      value={isRenovated}
                      onChange={this.handleChange}
                    >
                      {register.map((data) => {
                        return (
                          <MenuItem value={data.value}>{data.name}</MenuItem>
                        );
                      })}
                    </Select>

                    {this.state.isRenovated == true && marketImage && (
                      <Field
                        name="renovated_market"
                        options={marketImage}
                        label="Renovated Market*"
                        component={MaterialMulti}
                        selectedValues={renovated_market}
                        validate={[requireArray]}
                        updateMarket={this.onChangeMarket}
                      />
                    )}

                    {this.state.isRenovated === true &&
                      this.state.countyList &&
                      Object.keys(this.state.countyList).length > 0 &&
                      this.state.renovated_market.map((market) => (
                        <Field
                          name={`renovated_${market}_counties`}
                          key={`renovated_${market}_counties`}
                          label={`${market} Counties*`}
                          selectedValues={defaultCountiesRenovated[market]}
                          options={countyList[market]}
                          component={MaterialMulti}
                          multi={true}
                          validate={[requireArray]}
                          triggerValidation={(values) =>
                            this.triggerValidation(
                              values,
                              `renovated_${market}_counties`,
                            )
                          }
                          updateCounty={(selectedCounties) =>
                            this.onChangeCounty(
                              'renovated',
                              market,
                              selectedCounties,
                            )
                          }
                        />
                      ))}

                    <Field
                      name="communication"
                      options={communication_type}
                      label="Communication Type*"
                      textField="name"
                      valueField="value"
                      validate={[required]}
                      component={DropdownComp}
                    />

                    <Field
                      name="status"
                      options={STATUS}
                      label="Status"
                      defaultValue={this.state.status ? 'Active' : 'Inactive'}
                      textField="title"
                      valueField="value"
                      component={DropdownComp}
                    />
                    <br />

                    <div className="form-actions">
                      <button
                        type="submit"
                        className="btn green uppercase"
                        disabled={this.props.invalid || this.props.submitting}
                      >
                        {formAction === 'ADD' ? 'Add' : 'Update'}
                      </button>
                    </div>
                  </form>
                </Tab>
                <Tab
                  eventKey={2}
                  title="Offers"
                  disabled={formAction === 'ADD'}
                >
                  <Offers
                    offers={this.state.offers}
                    acceptOffer={this.acceptOffer}
                    declineOffer={this.declineOffer}
                    deleteOffer={this.deleteOffer}
                    showOfferDeleteModal={this.showOfferDeleteModal}
                    closeOfferDeleteModal={this.closeOfferDeleteModal}
                    showOfferDelete={this.state.showOfferDelete}
                    isRedirect={this.state.isRedirect}
                    offerSetllementDialogFun={this.offerSetllementDialogFun}
                    offerSetllementDialogFlag={
                      this.state.offerSetllementDialogFlag
                    }
                    closeOfferSettelmentModal={this.closeOfferSettelmentModal}
                    setSettlementDate={this.setSettlementDate}
                  />
                </Tab>
              </Tabs>
            </div>
          </div>
        )}
      </div>
    );
  }
  showOfferDeleteModal = (data) => {
    /*show popup and confirm before delete*/
    this.setState({ showOfferDelete: true, offer_id: data });
  };
  offerSetllementDialogFun = (data) => {
    // document.getElementById('settlement_date_input').value="";
    this.setState({ offerSetllementDialogFlag: true, offer_id: data });
    console.log(this.state.offerSetllementDialogFlag, 'Flag Offer', data);
  };
  closeOfferSettelmentModal = () => {
    this.setState({ offerSetllementDialogFlag: false });
  };
  closeOfferDeleteModal = () => {
    this.setState({ showOfferDelete: false });
  };
  handleChange = (event) => {
    this.setState({
      isRenovated: event.target.value,
    });
  };

  wholesaleValueChange = (event) => {
    this.setState({
      isWholesale: event.target.value,
    });
  };

  clearMarkets(marketType) {
    if (marketType === 'wholesale_market') {
      this.setState(() => ({
        wholesaleToSend: [],
      }));
    } else {
      this.setState(() => ({
        renovatedToSend: [],
      }));
    }
  }

  onChangeMarket = (marketType, markets) => {
    const previousFormValues =
      marketType === 'wholesale_market'
        ? [...this.state.wholesaleToSend]
        : [...this.state.renovatedToSend];
    this.clearMarkets(marketType);
    setTimeout(() => {
      markets.forEach((market) => {
        const fieldIndex = previousFormValues.findIndex(
          (elem) => elem.market === market,
        );
        const countiesToSet =
          fieldIndex === -1 ? [] : [...previousFormValues[fieldIndex].counties];
        const marketObject = {
          market: market,
          counties: countiesToSet,
        };
        if (marketType === 'wholesale_market') {
          this.setState({
            wholesaleToSend: [...this.state.wholesaleToSend, marketObject],
          });
          this.setCounties(market, 'wholesale', countiesToSet);
        } else {
          this.setState({
            renovatedToSend: [...this.state.renovatedToSend, marketObject],
          });
          this.setCounties(market, 'renovated', countiesToSet);
        }
      });
      if (marketType === 'wholesale_market') {
        this.setState({
          wholesale_market: [...markets],
        });
      } else {
        this.setState({
          renovated_market: [...markets],
        });
      }
    }, 0);
  };

  triggerValidation = (values, field) => {
    const { change } = this.props;
    change(field, values);
  };

  onChangeCounty = (marketType, market, counties) => {
    setTimeout(() => {
      let countiesToSave =
        marketType === 'wholesale'
          ? this.state.wholesaleToSend
          : this.state.renovatedToSend;
      const fieldIndex = countiesToSave.findIndex(
        (elem) => elem.market === market,
      );
      if (fieldIndex !== -1) {
        countiesToSave[fieldIndex].counties = counties;
      } else {
        countiesToSave.push({
          market: market,
          counties: counties,
        });
      }
      // this.clearMarkets(marketType);
      if (marketType === 'wholesale') {
        this.setState({
          wholesaleToSend: [...countiesToSave],
        });
      } else {
        this.setState({
          renovatedToSend: [...countiesToSave],
        });
      }
    }, 500);
  };

  closeModel() {
    /**to close modal */
    this.setState({ show: false });
  }

  onChangeHoa(event) {
    this.setState({ isWholesale: event });
  }

  upsertProp(data) {
    const { match } = this.props;
    if (match.params._id) data.editID = match.params._id;

    if (data.communication === 1 && !(!!data.email && !!data.mobile)) {
      alert(
        'You choose Communitcation method as SMS & Email but either Mobile/Email is missing, Please add.',
      );
      return false;
    }

    if (data.communication === 3 && !!!data.email) {
      alert('Please add Email to continue.');
      return false;
    }

    if (data.communication === 2 && !data.email) {
      alert(
        'You choose Communitcation method as Email but EmailId is missing, Please add.',
      );
      return false;
    }

    if (data.communication === 4 && !data.mobile) {
      alert(
        'You choose Communitcation method as SMS but Mobile is missing, Please add.',
      );
      return false;
    }

    data.register_renovated = this.state.isRenovated;
    data.register_wholesale = this.state.isWholesale;
    if (data.communication === 3) {
      data.register_renovated = false;
      data.register_wholesale = false;
    }
    if (data.register_renovated) {
      this.state.renovated_market.forEach(
        (market) => delete data[`renovated_${market}_counties`],
      );
    }

    if (data.register_wholesale) {
      data.wholesale_market.forEach(
        (market) => delete data[`wholesale_${market}_counties`],
      );
    }
    data.renovated_market = this.state.renovatedToSend;
    data.wholesale_market = this.state.wholesaleToSend;

    delete data.wholesale_county;
    delete data.renovated_county;

    if (!data.register_renovated) {
      delete data.renovated_market;
    }

    HTTP.Request('post', window.admin.WebUserUpsert, data)
      .then((result) => {
        this.setState({ isLoading: false });
        if (result.success) {
          this.props.dispatch(push('/users'));
          toast(result.message, { type: 'success' });
        } else {
          toast(result.message, { type: 'error' });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({ isLoading: false });
        toast(err.message, { type: 'error' });
      });
  }

  setCounties(market, marketType, counties) {
    let countiesToSet = [];
    if (this.state.countyList[market] && (!counties || counties.length === 0)) {
      this.state.countyList[market].forEach((item) =>
        countiesToSet.push(item.id),
      );
    } else {
      if (counties) {
        countiesToSet = [...counties];
      }
    }
    this.onChangeCounty(marketType, market, countiesToSet);
    if (marketType === 'wholesale') {
      this.setState({
        [`wholesale_${market}_counties`]: countiesToSet,
      });
    } else {
      this.setState({
        [`renovated_${market}_counties`]: countiesToSet,
      });
    }
  }

  setMarkets(user) {
    if (user.wholesale_market) {
      let marketsToSet = [];
      if (user.wholesale_market.length > 0 && user.wholesale_market[0].market) {
        let countiesWholesaleDefault = this.state.defaultCountiesWholesale;
        user.wholesale_market.forEach((market) => {
          if (countiesWholesaleDefault[market.market]) {
            marketsToSet.push(market.market);
            this.setCounties(market.market, 'wholesale', market.counties);
            countiesWholesaleDefault[market.market] = market.counties;
            this.setState({
              defaultCountiesWholesale: countiesWholesaleDefault,
            });
          }
        });
        this.setState({
          wholesale_market: marketsToSet,
        });
        marketsToSet = [];
      } else {
        user.wholesale_market.forEach((market) => {
          this.setCounties(market, 'wholesale');
        });
        this.setState({
          wholesale_market: user.wholesale_market,
        });
      }
    }
    if (user.renovated_market) {
      let marketsToSet = [];
      if (user.renovated_market.length > 0 && user.renovated_market[0].market) {
        let countiesDefault = this.state.defaultCountiesRenovated;
        user.renovated_market.forEach((market) => {
          if (countiesDefault[market.market]) {
            marketsToSet.push(market.market);
            this.setCounties(market.market, 'renovated', market.counties);
            countiesDefault[market.market] = market.counties;
            this.setState({
              defaultCountiesRenovated: countiesDefault,
            });
          }
        });
        this.setState({
          renovated_market: marketsToSet,
        });
      } else if (user.renovated_market) {
        if (Array.isArray(user.renovated_market)) {
          user.renovated_market.forEach((market) => {
            this.setCounties(market, 'renovated', user.renovated_county);
          });
          this.setState({
            renovated_market: user.renovated_market,
          });
        } else {
          marketsToSet.push(user.renovated_market);
          this.setCounties(
            user.renovated_market,
            'renovated',
            user.renovated_county,
          );
          this.setState({
            renovated_market: marketsToSet,
          });
        }
      }
    }
  }

  async getCounties(market) {
    let countyListByMarket = this.state.countyList || [];
    try {
      const result = await HTTP.Request('get', window.admin.countyList, {
        market: market,
      });
      let countyImageArray = [];
      if (result.data && result.data && result.data.length) {
        countyImageArray = result.data.map((c) => {
          const mCounty = {
            title: c['county'] + ', ' + c['state_code'],
            id: c['_id'],
          };
          return mCounty;
        });
        countyListByMarket[market] = countyImageArray;
      }

      this.setState({
        countyList: countyListByMarket,
      });
    } catch (err) {
      console.log(err);
    }
  }

  setDefaultCounties(markets) {
    let defaultCounties = [];
    let defaultRenovated = [];
    for (const market of markets) {
      defaultCounties[market.title] = [];
      defaultRenovated[market.title] = [];
      if (this.state.countyList[market.title]) {
        this.state.countyList[market.title].forEach((item) => {
          defaultCounties[market.title].push(item.id);
          defaultRenovated[market.title].push(item.id);
        });
      }
    }

    this.setState({
      defaultCountiesWholesale: defaultCounties,
      defaultCountiesRenovated: defaultRenovated,
    });
  }

  setSelectedTab = (key) => {
    console.log(key);
    this.setState({
      defaultTabSelected: key
    });

    const { match } = this.props;
    /*extract plant id from request*/
    let propertyId = match.params._id ? match.params._id : null;

    if(propertyId) {
      if (key == 2) {
        this.props.history.push("/users-edit/"+ propertyId +"/offers");
      } else {
        this.props.history.push("/users-edit/"+ propertyId +"/edit");
      }
    }
  }

  async getAgent() {
    // this.setState({ isLoading: true });
    let marketImageArray = [];
    const result = await HTTP.Request('get', window.admin.agentsDynamic, {});
    if (result.data && result.data.market && result.data.market.length) {
      marketImageArray = result.data.market.map((i) => {
        return { title: i.name, _id: i.name };
      });
      let marketsWithCounties = [];
      for (let i = 0; i < marketImageArray.length; i++) {
        const marketElem = marketImageArray[i];
        await this.getCounties(marketElem.title);
        if (this.state.countyList[marketElem.title]) {
          marketsWithCounties.push(marketElem);
        }
      }

      this.setDefaultCounties(marketImageArray);
      this.setState({
        isLoading: false,
        selectedAgent: result.data ? result.data.agent : [],
        marketImage: marketsWithCounties,
      });
    }
  }

  async getProp() {
    const { match, initialize } = this.props;
    /*extract plant id from request*/
    let roleID = match.params._id ? match.params._id : null;

    if (roleID) {
      this.setState({ isLoading: true, formAction: 'EDIT', isOption: false });
      const result = await HTTP.Request(
        'get',
        window.admin.RealStateInvesterOne,
        { id: roleID },
      );

      this.setState({
        isLoading: false,
        selectedUsers: result.data.user_type,
        // selectedUsers : [1,2],
        status: result.data.status,
        isOption: true,
        isRenovated: result.data.register_renovated,
        isWholesale: result.data.register_wholesale,
      });

      if (result.data.user_type) {
        result.data.user_type.map((e) => {
          if (e === 3 || e === 1) {
            this.setState({
              UserType: e,
            });
          } else if (e === 2) {
            this.setState({
              UserType: e,
            });
          }
        });
      }
      this.setMarkets(result.data);
      let initialObject = { ...result.data };
     if(result.data && result.data.wholesale_market){
      result.data.wholesale_market.forEach((market) => {
        initialObject[`wholesale_${market.market}_counties`] =
          this.state[`wholesale_${market.market}_counties`];
      });
    }
     if(result.data && result.data.renovated_market){
      result.data.renovated_market.forEach((market) => {
        initialObject[`renovated_${market.market}_counties`] =
          this.state[`renovated_${market.market}_counties`];
      });
    }

      if (result.data.mobile) {
        initialObject.mobile = result.data.mobile.replace(/[()]/g, '');
      }
      /*set data to form*/
      setTimeout(() => {
        initialize(initialObject);
      }, 500);
    }
  }
}

let AddRealEstateForm = reduxForm({
  form: "AddRealEstateForm"
})(AddRealEstateInvestors);

export default connect()(AddRealEstateForm);