(function () {
  window.admin = {
    login: window._env.adminPath + 'login-admin',
    logout: window._env.adminPath + 'logout-admin',
    profile: window._env.adminPath + 'my-profile',
    forgotPassword: window._env.adminPath + 'forgot-password',
    resetPassword: window._env.adminPath + 'reset-password',
    changePassword: window._env.adminPath + 'change-password',
    upadteAdminProfile: window._env.adminPath + 'update-profile',
    newUserCreatePassword: window._env.adminPath + 'new-user-create-password',
    // getCronTime: window._env.adminPath + "get-date",

    // ROLE
    addEditRole: window._env.adminPath + 'add-edit-role',
    getRoles: window._env.adminPath + 'get-roles',
    getARole: window._env.adminPath + 'get-a-role',
    getACurrentUser: window._env.adminPath + 'get-current-user',
    // THIRD PARTY
    addThirdPartyCredential: window._env.adminPath + 'third-party-credentials',
    getThirdPartyCredentials: window._env.adminPath + 'third-party-credentials',
    getThirdPartyCredential: window._env.adminPath + 'third-party-credential',
    editThirdPartyCredential:
      window._env.adminPath + 'edit-third-party-credential',

    // USER/ADMIN MANGEMENT
    addEditUser: window._env.adminPath + 'add-edit-admin',
    getUsers: window._env.adminPath + 'get-admins',
    syncUsers: window._env.adminPath + 'sync-admins',
    getAUser: window._env.adminPath + 'get-a-admin',
    getRolesOptions: window._env.adminPath + 'get-roles-options',
    getAdminRole: window._env.adminPath + 'get-admin-roles',
    //CUSTOMER MANGEMENT
    upsertCustomer: window._env.adminPath + 'upsert-user',
    getCustomers: window._env.adminPath + 'get-users',
    getACustomer: window._env.adminPath + 'get-a-user',
    // REDIRECTLINK MANAGEMENT
    upsertRedirectLink: window._env.adminPath + 'upsert-redirectlink',
    getRedirectLink: window._env.adminPath + 'get-redirectlink',
    getAllRedirectLink: window._env.adminPath + 'get-all-redirectlink',
    getRedirectLinkById: window._env.adminPath + 'get-redirectlink-by-id',

    //Pages<==>Blogs<===>Email
    getCMS: window._env.adminPath + 'get-cms',
    getUrlRedirects: window._env.adminPath + 'get-url-redirects',
    getCMSByRedirect: window._env.adminPath + "get-cms-by-redirect",
    upsertCMS: window._env.adminPath + 'upsert-cms',
    getaCMS: window._env.adminPath + 'get-a-cms',
    deleteCMS: window._env.adminPath + 'delete-a-cms',
    importCMS: window._env.adminPath + 'upload-cms-csv',

    // Review 
    getReviews: window._env.adminPath + 'get-reviews',
    upsertReview: window._env.adminPath + 'upsert-review',
    getaReview: window._env.adminPath + 'get-a-review',
    deleteReview: window._env.adminPath + 'delete-a-review',

    // Blog
    importBlog: window._env.adminPath + 'upload-blog-csv',
    getBlogs: window._env.adminPath + 'get-blogs',
    upsertBlog: window._env.adminPath + 'upsert-blog',
    getaBlog: window._env.adminPath + 'get-a-blog',
    deleteBlog: window._env.adminPath + 'delete-a-blog',

    // Page
    importPage: window._env.adminPath + 'upload-page-csv',
    getPages: window._env.adminPath + 'get-pages',
    upsertPage: window._env.adminPath + 'upsert-page',
    getaPage: window._env.adminPath + 'get-a-page',
    deletePage: window._env.adminPath + 'delete-a-page',
    getAllImages: window._env.adminPath + 'get-all-images',
    getAllImagesFroala: window._env.adminPath + 'get-all-images-froala',
    getMakeAllPhoneClickable: window._env.adminPath + 'clickable-phone',
    
     // CMS-Block
     getCmsBlocks: window._env.adminPath + 'get-cms-blocks',
     upsertCmsBlock: window._env.adminPath + 'upsert-cms-block',
     getaCmsBlock: window._env.adminPath + 'get-a-cms-block',
     deleteCmsBlock: window._env.adminPath + 'delete-a-cms-block',

    //  Social
    getSocialCms: window._env.adminPath + 'get-social-cms',
    upsertSocialCms: window._env.adminPath + 'upsert-social-cms',
    getaSocialCms: window._env.adminPath + 'get-a-social-cms',
    deleteSocialCms: window._env.adminPath + 'delete-a-social-cms',

    //  Slide
    getSlides: window._env.adminPath + 'get-slides',
    upsertSlide: window._env.adminPath + 'upsert-slide',
    getaSlide: window._env.adminPath + 'get-a-slide',
    deleteSlide: window._env.adminPath + 'delete-a-slide',

    //  FAQ Category
    getFaqCategory: window._env.adminPath + 'get-faq-category',
    upsertFaqCategory: window._env.adminPath + 'upsert-faq-category',
    getaFaqCategory: window._env.adminPath + 'get-a-faq-category',
    deleteFaqCategory: window._env.adminPath + 'delete-a-faq-category',
  
    //  FAQ
    getFaq: window._env.adminPath + 'get-faq',
    upsertFaq: window._env.adminPath + 'upsert-faq',
    getaFaq: window._env.adminPath + 'get-a-faq',
    deleteFaq: window._env.adminPath + 'delete-a-faq',

    //  Ads
    getAds: window._env.adminPath + 'get-ad',
    upsertAds: window._env.adminPath + 'upsert-ad',
    getaAds: window._env.adminPath + 'get-a-ad',
    deleteAds: window._env.adminPath + 'delete-a-ad',

    //  Buyer Cms
    getBuyerCms: window._env.adminPath + 'get-buyer-cms',
    upsertBuyerCms: window._env.adminPath + 'upsert-buyer-cms',

    //  Investor Cms
    getInvestorCms: window._env.adminPath + 'get-investor-cms',
    upsertInvestorCms: window._env.adminPath + 'upsert-investor-cms',

        //  Realtor Cms
    getRealtorCms: window._env.adminPath + 'get-realtor-cms',
    upsertRealtorCms: window._env.adminPath + 'upsert-realtor-cms',

    //Profile
    changeUserAvtar: window._env.adminPath + 'change-admin-avtar',

    //Notifications
    getNotifications: window._env.adminPath + 'get-notifications',

    //metaData IP<==>Taxes
    addEditMetaData: window._env.adminPath + 'add-edit-metadata',
    // UpdateCron: window._env.adminPath + "add-cron-time",
    getMetaData: window._env.adminPath + 'get-metadata',

    validateSession: window._env.adminPath + 'validate-session',
    requestAccess: window._env.adminPath + 'request-access',
    verifyAccessToken: window._env.adminPath + 'verify-access-token',

    // Feedback
    getFeedbacks: window._env.adminPath + 'get-feedbacks',
    getAFeedback: window._env.adminPath + 'get-a-feedback',
    replyFeedback: window._env.adminPath + 'reply-feedback',

    // Test
    getTests: window._env.adminPath + 'get-tests',
    getATest: window._env.adminPath + 'get-a-test',
    upsertTest: window._env.adminPath + 'upsert-test',

    //payment
    getPayments: window._env.adminPath + 'get-charges',
    getAPayment: window._env.adminPath + 'get-a-charge',

    // logs
    getLoginLogs: window._env.adminPath + 'get-login-logs',
    getAuditLogs: window._env.adminPath + 'get-audit-logs',
    getAccessLogs: window._env.adminPath + 'get-access-logs',
    getAAccessLogs: window._env.adminPath + 'get-a-access-logs',
    getUploadLogs: window._env.adminPath + 'get-upload-logs',
    trackActivity: window._env.adminPath + 'track-activity',
    exportAuditLogs: window._env.adminPath + 'export-audit-logs',
    exportAccessLogs: window._env.adminPath + 'export-access-logs',
    exportLoginLogs: window._env.adminPath + 'export-login-logs',
    exportUploadLogs: window._env.adminPath + 'export-upload-logs',
    getDynamicsLogs: window._env.adminPath + 'get-dynamics-logs',
    getPropertySyncLogs : window._env.adminPath + "get-propertysynclogs",

    //revisions
    restoreRevision: window._env.adminPath + 'restore-revision',
    restoreBlogRevision: window._env.adminPath + 'restore-blog-revision',
    restorePageRevision: window._env.adminPath + 'restore-page-revision',
    restoreCmsBlockRevision: window._env.adminPath + 'restore-cms-block-revision',
    restoreSocialRevision: window._env.adminPath + 'restore-social-revision',
    restoreSlideRevision: window._env.adminPath + 'restore-slide-revision',


    //Reports
    // Admin<====>Customer
    adminCustomerCsv: window._env.adminPath + 'export-admin-customer-csv',
    getanOfferList: window._env.adminPath + 'get-an-offer-list',
    getanStagingOfferList: window._env.adminPath + 'get-an-staging-offer-list',
    getanOfferView: window._env.adminPath + 'get-offer-view',
    getanStagingOfferView: window._env.adminPath + 'get-staging-offer-view',
    offersList: window._env.adminPath + 'offers',
    offersListCSV: window._env.adminPath + 'download-offer-csv',

    //MARKET_MANAGEMENT
    addMarket: window._env.adminPath + 'add-new-market',
    getmarketList: window._env.adminPath + 'get-market-list',
    getmarketView: window._env.adminPath + 'get-market-view',
    EditMarket: window._env.adminPath + 'edit-market',
    importMarkets: window._env.adminPath + 'upload-market',
    downloadAllCounties: window._env.adminPath +'download-all-county-list',

    //PROPERTY MANAGEMENT
    addProperty: window._env.adminPath + 'add-new-property',
    getpropertyList: window._env.adminPath + 'get-property-list',
    removeBlogImage: `${window._env.adminPath}remove-blog-image`,

    // Open House Property
    addOpenHouse: window._env.adminPath + 'open-house-add',
    searchBuyerList: window._env.adminPath + 'search-buyer-list',
    openHouseList: window._env.adminPath + 'open-house-list',
    cancelOpenHouse: window._env.adminPath + 'open-house-cancel',
    removeOpenHouse: window._env.adminPath + 'open-house-delete',
    updateOpenHouse: window._env.adminPath + 'open-house-update',
    addOpenHouseBuyer: window._env.adminPath + 'add-buyer',
    getOpenHouseDetail: window._env.adminPath + 'open-house-detail',
    removeBuyerOpenHouse: window._env.adminPath + 'remove-buyer',
    downlaodBuyersData: window._env.adminPath + 'download-buyer-csv',

    // Offers property
    getPropertyOffers: window._env.adminPath + 'offers',
    acceptOffers: window._env.adminPath + 'offers-accept',
    offerSettlement: window._env.adminPath + 'settlementDate',
    declineOffers: window._env.adminPath + 'offers-decline',
    deleteOffers: window._env.adminPath + 'offers-delete',
    // Comparable property
    addComprables: window._env.adminPath + 'add-new-comparable',
    getComparablesList: window._env.adminPath + 'get-comparable-list',
    removeComparablesList: window._env.adminPath + 'delete-comparable',
    updateComparablesList: window._env.adminPath + 'edit-comparable',

    //REview Management
    gerReviewList: window._env.adminPath + 'review-list',
    getReviewView: window._env.adminPath + 'review-view',

    //out of Market
    gerOutMarketList: window._env.adminPath + 'get-out-market-list',
    gerOutMarketView: window._env.adminPath + 'get-out-of-market-view',

    //career management
    careerList: window._env.adminPath + 'career-list',
    careerView: window._env.adminPath + 'career-view',

    /* froala images */

    uploadImagesFlo: window._env.adminPath + 'floala_image_upload',
    deleteImagesFro: window._env.adminPath + 'floala_image_delete',

    /* faq category */
    faqCategoryList: window._env.adminPath + 'faq-category-list',
    faqCategoryAdd: window._env.adminPath + 'faq-category-add',
    faqQuestionCount: window._env.adminPath + 'faq_category_count',
    faqQuestionDeleteUpdate:
      window._env.adminPath + 'faq_category_update_and_delete',

    /* Agent Count */

    getAgentCount: window._env.adminPath + 'get-agent-count',
    updateAgentDetail: window._env.adminPath + 'update-agent-info',

    // export data

    exportCms: window._env.adminPath + "export-cms",

    /* social */

    socicalUpdateAdd: window._env.adminPath + 'edit_save_social_cms',

    // Author

    addEditAuthor: window._env.adminPath + 'add-edit-author',
    getAuthors: window._env.adminPath + 'get-authors',
    getAAuthor: window._env.adminPath + 'get-a-author',
    deleteAuthor: window._env.adminPath + 'delete-author',


    /* sitemap */
    sitemapUpsert: window._env.adminPath + 'upsert-sitemap',
    sitemaplisting: window._env.adminPath + 'sitemap-listing',
    sitemapActiveListing: window._env.adminPath + "/sitemap-active-listing",
    onesitemap: window._env.adminPath + 'geta-site-map',
    exists: window._env.adminPath + 'exists',
    syncData: window._env.adminPath + 'dynamic-sitemap-routes',
    transferSitemap: window._env.adminPath + 'transfer-sitemap',

    /* Property Details */

    upsertPropertDetails: window._env.adminPath + 'upsert-property-detail',
    listPropertDetails: window._env.adminPath + 'list-property-detail',
    getPropertDetails: window._env.adminPath + 'get-property-detail',
    getCountByGuid: window._env.adminPath + 'get-property-by-guid',
    downloadPropertyDetails:
      window._env.adminPath + 'download-property-details',
    getallwebuser: window._env.adminPath + 'get-all-web-users',
    searchPropertyInCrm: window._env.adminPath + 'search-property-in-crm',
    getCrmPropertyByGuid:window._env.adminPath + "get-crm-property-by-guid",
    syncPropertyWithWordpress: window._env.adminPath + "wordpress-property-sync",
    /* Agent */
    upsertAgent: window._env.adminPath + 'upsert-agent',
    getAgentList: window._env.adminPath + 'get-agent-list',
    searchAgentInCrm: window._env.adminPath + 'search-agent-in-crm',
    syncAgents: window._env.adminPath + 'sync-agents',
    getAgentDetail: window._env.adminPath + 'get-agent-detail',
    agentsDynamic: window._env.adminPath + 'agent-property-list',
    allAgentsDynamic: window._env.adminPath + 'all-agents-property-list',
    trashAgent: window._env.adminPath +'trash-agent',
    /* MArket Image */
    upsertMarketImage: window._env.adminPath + 'upsert-market-image',
    marketImageListing: window._env.adminPath + 'market-image-list',
    marketImageView: window._env.adminPath + 'market-image-view',
    countyList: window._env.adminPath + 'county-list-by-market',
    countyListByState: window._env.adminPath + 'county-list-by-state',
    allMarketList: window._env.adminPath + 'get-all-market-list',
    removeCounty: window._env.adminPath + 'remove-county',

    /* delete admin */
    deleteAdminSoft: window._env.adminPath + 'delete-admin',
    /* property content management */
    upsertPropertyContent: window._env.adminPath + 'property-cms-upsert',
    propertyContentList: window._env.adminPath + 'property-cms-listing',
    propertyContentview: window._env.adminPath + 'property-cms-view',
    getTopArticles: window._env.adminPath + 'get-top-articles',
    getTopArticlesById: window._env.adminPath + 'get-top-articles-by-id',
    upsertTopArticles: window._env.adminPath + 'upsert-top-articles',
    deleteTopArticles: window._env.adminPath + "delete-top-articles",
    deleteRedirectLinks: window._env.adminPath + "delete-redirectlink",
    getFooterLinks: window._env.adminPath + 'get-footer-links',
    getFooterLinksById: window._env.adminPath + 'get-footer-links-by-id',
    upsertFooterLinks: window._env.adminPath + 'upsert-footer-links',
    deleteFooterLinks: window._env.adminPath + 'delete-footer-links',
    moveUpFooterLinks: window._env.adminPath + 'moveup-footer-links',
    moveDownFooterLinks: window._env.adminPath + 'movedown-footer-links',
    moveUpTopArticles: window._env.adminPath + 'moveup-top-articles',
    moveDownTopArticles: window._env.adminPath + 'movedown-top-articles',

    /* Buyers  */
    sendDataToCrm:window._env.adminPath + 'submit-lead-to-crm',
    sendDataToStagingCrm:window._env.adminPath + 'submit-lead-to-staging-crm',
    BuyersListing: window._env.adminPath + 'buyers-listing',
    updateLead: window._env.adminPath + 'update-lead-crm-data',
    updateStagingLead: window._env.adminPath + 'update-staging-lead-crm-data',

    AddNewBuyer: window._env.adminPath + 'add-new-buyer',
    GetSingleBuyerDetails: window._env.adminPath + 'get-buyer-detail',
    GetBuyersList: window._env.adminPath + 'get-buyer-list',
    UpdateBuyers: window._env.adminPath + 'edit-buyer',

    /* Rec Contacts */
    RecContacts: window._env.adminPath + 'rec-contacts',
    /* Realtors */
    RealtorDistressedListing:
      window._env.adminPath + 'realtors-distressed-list',
    /* Real Estate Investors */
    // RealStateInvesterOne:window._env.adminPath+"get-one-real-estate-investor",
    RealStateInvesterOne: window._env.adminPath + 'get-one-user',

    RealStateInvesterUpsert:
      window._env.adminPath + 'upsert-real-estate-investor',
    // RealStateInvesterListing:window._env.adminPath+"real-estate-investor-list",
    InvesterUploads: window._env.adminPath + 'webuser-upload',
    WebUserUpsert: window._env.adminPath + 'manual-save',
    BulkUserInsert: window._env.adminPath + 'save-bulk-user',
    RealStateInvesterListing: window._env.adminPath + 'user-list',
    DownloadAllUsers: window._env.adminPath + 'download-users',

    /* Realtors Signup */
    RealtorSignUpList: window._env.adminPath + 'realtor-signup-listing',
    RealtorSignUpupsert: window._env.adminPath + 'realtor-signup-upsert',
    RealtorSignUpOne: window._env.adminPath + 'realtor-signup-one',
    removePropertyDeatial: window._env.adminPath + 'remove-property-detail',
    getRealtorInvestorOne: window._env.adminPath + 'realtor-investors-get-one',
    // removeRealtorInvestor:window._env.adminPath+"remove-real-estate-investor",
    removeRealtorInvestor: window._env.adminPath + 'user-delete',

    getCounty: window._env.adminPath + 'get-county-detail',
    getCountyList: window._env.adminPath + 'county-list',
    /* bulk images upload section */
    bulkUploadImages: window._env.adminPath + 'bulk_image_upload',

    /* flyers */
    flyerList: window._env.adminPath + 'list-flyer-detail',
    flyerSingle: window._env.adminPath + 'get-single-flyer-detail',
    flyerTemplate: window._env.adminPath + 'get-template',
    sendflyer: window._env.adminPath + 'send-flyer',
    renovatedFlyerLogs: window._env.adminPath + 'renovated-flyer-list',
    sendWholesaleFyer: window._env.adminPath + 'send-wholesale-flyer',
    wholesaleFlyerList: window._env.adminPath + 'list-wholesale-flyer-detail',
    downloadMessageLog: window._env.adminPath + 'download-message-logs',
    mongotoCrmLog: window._env.adminPath + 'get-mongologs',
    CrmtoMongoLog: window._env.adminPath + 'get-crmlogs',
    getUserList: window._env.adminPath + 'get-user-list',

    /* Dashboard */

    reviewsCount: window._env.adminPath + 'get-today-review',
    outofMarketsCount: window._env.adminPath + 'get-todays-outofmarket-leads',
    MarketsCount: window._env.adminPath + 'get-todays-market-leads',
    downloadAllLeads: window._env.adminPath + 'download-leads',


    /* Add New Buy CMS Property */

    AddBuyCMSProperty: window._env.adminPath + 'add-buy-cms-property',
    ListBuyCMSProperty: window._env.adminPath + 'buy-cms-property-list',
    GetOneBuyCMSProperty: window._env.adminPath + 'buy-cms-property-view',

    /* Page Cache */
    getCachedPages: window._env.adminPath + 'cached-pages',
    deleteCachedPageKey: window._env.adminPath + 'delete-cached-key',
    deleteAllCachedPages: window._env.adminPath + 'delete-all-cached-keys',
  };
})();
