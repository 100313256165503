import React, { Component } from 'react';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import { push } from 'react-router-redux';
import { toast } from 'react-toastify';
import HTTP from '../../../services/http';
import Socket from '../../../sockets';
import Switch from '@material-ui/core/Switch';
import { HelpBlock } from 'react-bootstrap';
import ImageCropper from '../../common/ImageCropper';
import RenderField from '../../common/renderField';
import {
  required,
  emailValiadte,
  mobileValidate,
} from '../../common/fieldValidations';
import { ADMIN_TRACK_AUDIT_LOGS } from '../../common/actions';
/**COMPONENT */
import RenderFiled from '../../common/renderField';
import PageHeader from '../../common/pageheader';
import Multiselect from '../../common/multiselect';
import Loader from '../../common/loader';
import DropdownComp from '../../common/DropdownList';
class AddAuthor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      roleOptions: [],
      isLoading: false,
      formAction: 'ADD',
      status: true,
      imageSize:false,
      image:null
      // selectedRole:{}
    };

    /**event binding  */
    this.addEditAuthor = this.addEditAuthor.bind(this);
    this.getAUser = this.getAUser.bind(this);

    this.currentUserRole = '';
  }

  componentWillMount() {
    this.getAUser();
  }

  render() {
    const { handleSubmit } = this.props;
    const {isLoading, formAction,imageSize} =
      this.state;

    var activeInacticeList = '';
    return (
      <div>
        {isLoading ? (
          <Loader />
        ) : (
          <div>
            <PageHeader
              route={formAction === 'ADD' ? 'Add New User' : 'Edit User'}
              parent="Author Management"
              parentRoute="/author-management"
            />

            <div className="tab-pane active">
              <form onSubmit={handleSubmit(this.addEditAuthor)}>
                <Field
                  name="firstName"
                  fieldName="First Name*"
                  type="text"
                  component={RenderFiled}
                  validate={[required]}
                />
                <Field
                  name="lastName"
                  fieldName="Last Name*"
                  type="text"
                  component={RenderFiled}
                  validate={[required]}
                />
                <div className="">
                        <label> Profile Image </label>
                        <Field
                          component={ImageCropper}
                          name="image"
                          id={'imagePage'}
                          minWidth={0}
                          minHeight={0}
                          // dimensionsCheck={true}
                          ratioUpper={550}
                          ratioLower={550}
                        />
                        {imageSize && (
                          <HelpBlock style={{ color: '#e73d4a' }}>
                            {
                              'Please select image with minimum resolution of 1300 x 450 pixels'
                            }
                          </HelpBlock>
                        )}
                        {this.state.image ? (
                          <img
                            src={this.state.image.secure_url}
                            alt={this.state.img_alt}
                            width="120px"
                            className="img-responsive img-thumbnail i-bot"
                          />
                        ) : null}
                      </div>
                <Field
                  name="email"
                  fieldName="Email*"
                  type="text"
                  placeholder="abc@yourdomain.com"
                  component={RenderFiled}
                  validate={[emailValiadte, required]}
                />
                <Field
                  name="phone_no"
                  fieldName="Mobile*"
                  type="number"
                  component={RenderFiled}
                  validate={[mobileValidate, required]}
                />
                <Field
                  textarea
                  name="description"
                  fieldName="Description"
                  type="text"
                  component={RenderFiled}
                />
                <Field
                  name="affliation"
                  fieldName="Affliation"
                  type="text"
                  component={RenderFiled}
                />
                 <Field
                  name="status"
                  options={[
                    { label: 'Active', value: true },
                    { label: 'Inactive', value: false },
                  ]}
                  label="Status"
                  defaultValue={this.state.status ? 'Active' : 'Inactive'}
                  textField="label"
                  valueField="value"
                  component={DropdownComp}
                />
                <div className="form-actions">
                  <button
                    type="submit"
                    className="btn green uppercase"
                    disabled={this.props.invalid || this.props.submitting}
                  >
                    {formAction === 'ADD' ? 'Add ' : 'Update'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    );
  }

  addEditAuthor(data) {
    if(data.status == undefined){
      data["status"]=this.state.status
    }
    const { match } = this.props;
    /*extract plant id from request*/
    let userID = match.params.id ? match.params.id : null;
    if(userID){
      data["editId"] = userID;
    }

    let formData = new FormData()
    formData.append('file', data.image);
    formData.append('data', JSON.stringify(data));
    
    return HTTP.Request('post', window.admin.addEditAuthor, formData)
      .then((result) => {
        console.log('addEditUser =>>>', result);
        this.props.dispatch(push('/author-management'));
        toast(result.message, { type: 'success' });
      })
      .catch((err) => {
        if (err && err.error && err.error.length > 0) {
          let validationErrorsObj = {};
          err.error.map((errorElem) => {
            toast(errorElem.message, { type: 'error' });
            validationErrorsObj[errorElem.field] = errorElem.message;
          });
          throw new SubmissionError(validationErrorsObj);
        } else {
          toast(err.message, { type: 'error' });
        }
      });
  }

  getAUser() {
    const { match, initialize } = this.props;
    /*extract plant id from request*/
    let userID = match.params.id ? match.params.id : null;

    if (userID) {
      this.setState({ isLoading: true, formAction: 'EDIT' });
      HTTP.Request('get', window.admin.getAAuthor, { id: userID }).then(
        (result) => {
          setTimeout(() => {
            this.setState({
              isLoading: false,
              status: result.data.status,
              image:result.data.image
            });
          }, 1000);

          console.log('getAUser =>>>', result);
          /*set data to form*/
          initialize(result.data);
        },
      );
    }
  }

  // toggle changes
  handleChange = (name) => (event) => {
    console.log('this is  name of toggle', name);
    this.setState({ ...this.state, [name]: event.target.checked });
  };
}

//decorate form component
let AddAuthor_Form = reduxForm({
  form: 'addAuthor_Form',
})(AddAuthor);

export default AddAuthor_Form;
