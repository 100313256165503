import React, { Component } from "react";
import { connect } from "react-redux";
import RenderField from "../common/renderField";
import { Tabs, Tab } from "react-bootstrap";
import { Field, reduxForm, SubmissionError } from "redux-form";
import Loader from "../common/loader";
import renderHTML from 'react-render-html';
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import PageHeader from "../common/pageheader";
import Multiselect from "../common/multi";
import Multiselect3 from "../common/multi_v3";
import Multiselect4 from "../common/multi_v4"
import { PropertyStatus
} from "../common/options";
import './flyer.css';
import {
  normalizePhone,
  normalizeBuilt,
  normalizeHoa
} from './numberValidation';
import moment  from 'react-moment'
import commonMoment from 'moment'
import DropdownCompV2 from "../common/dropdown_v2";
import DropdownCompV4 from "../common/dropdown_v4";
import { STATUS } from "../common/options";
import DropdownComp from "../common/DropdownList";
import LocationSearchInput from "../common/address";
import DatePicker from "../common/DateTimePicker";
import TimePickers from "../common/timePicker"
import { push } from "react-router-redux";
import { toast } from "react-toastify";
import HTTP from "../../services/http";
import { createNumberMask, createTextMask } from 'redux-form-input-masks';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import TimePicker from 'material-ui/TimePicker';
import {
  required,
  ValidateOnlyAlpha,
  yearBuiltValidation,
  mobileValidate,
  emailValiadte,
  RemarksMin
} from "../common/fieldValidations";

const phoneMask = createTextMask({
  pattern: '(999)999-9999',
});
const currencyMask = createNumberMask({
  // prefix: 'US$ ',
  // suffix: ' per item',
  // decimalPlaces: 2,
  // locale: 'en-US',
  allowEmpty: true,
})

class WholesaleFlyer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formAction: "ADD",
      opendate: '',
      propertyGuid:"",
    };
    this.upsertProp = this.upsertProp.bind(this);
    this.getProp = this.getProp.bind(this);
    this.onChangeHoa = this.onChangeHoa.bind(this);
    this.changeDate = this.changeDate.bind(this);
  }
  
  componentDidMount() {
    console.log('props', this.props.personalInfo);
    (async () => {
      await this.getProp();
    })();
    console.log('props', this.props.personalInfo);
  }

  render() {
    const { handleSubmit } = this.props;
    const { isLoading,formAction, opendate } = this.state;
  //  console.log(11111,marketCounty,Array.isArray(marketImage),Array.isArray(user_type_option ) );
    return (


      <div className="relative">
        {isLoading ? (
          <Loader />
        ) : (
          <div>
            <PageHeader
              parent="Marketing Templates"
              parentRoute="/users"

            />

            <div>

            {/* {renderHTML(this.someHTML)} */}


              <Tabs defaultActiveKey={1} animation={false} id="profileTabs">
                <Tab
                  eventKey={1}
                  title={
                    formAction === "ADD"
                      ? "Add New Template"
                      : "Edit User"
                  }
                >
                  <form onSubmit={handleSubmit(this.upsertProp)}>

                  <Field name = "email_subject"
                        fieldName = "Email Subject*"
                        type = "text"
                        rows = {
                            2
                        }
                        textarea maxLength = "100"
                        component = {
                            RenderField
                        }
                        validate = {
                            [required,

                              // RemarksMin
                            ]
                        }
                    />

                    <Field
                      name="buynow"
                      fieldName="Buy Now Price"
                      readOnly = {
                        true
                      }
                      type="text"{
                        ...currencyMask
                      }
                      // validate = {
                      //   required
                      // }
                      component={RenderField}
                      // normalize = {
                      //   normalizePhone
                      // }
                    />

                      <Field
                      name="OHD"
                      id = "listing_date"
                      fieldName="Open House Date"
                      // validate = {
                      //   required
                      // }
                      changeDate = {this.changeDate}
                      disabled={this.state.openHouseSelected}
                      component={DatePicker}
                      value = {this.state.opendate}
                      min={
                        (function(){
                            var tomorrow = new Date();
                            tomorrow.setDate(tomorrow.getDate() + 1);
                            return tomorrow;
                        })()
                      }
                      max={new Date('1-01-2030')}
                    />

                {this.state.opendate &&
                  <div>

                    <div className="row">
                      <div className="col-lg-6">
                        <Field
                          validate = {
                            required
                          }
                          id= 'start_time'
                          name="starttime"
                          fieldName="Start Time*"
                          disabled={this.state.openHouseSelected}
                          component={TimePickers}

                        />
                      </div>
                      <div className="col-lg-6">
                        <Field
                         validate = {
                          required
                        }
                          id= "end_time"
                          name="endtime"
                          fieldName="End Time*"
                          disabled={this.state.openHouseSelected}
                          component={TimePickers}
                        />
                      </div>
                    </div>

                      </div> }

                    {/* <div>
                      <MuiThemeProvider>
                          < TimePicker
                            hintText="Start Time"
                            name = "starttime"
                            value = { '10'}
                            component={RenderField}
                          />

                          <TimePicker
                            hintText="End Time"
                          />
                      </MuiThemeProvider>
                    </div> */}

                    <Field
                      name="register_wholesale"
                      placeholder = "-select-"
                      options = {
                        [{
                            label: "New Wholesale Deal",
                            value: "d-90f1539d85d24b7f951741f8f630dc64"
                          },
                          {
                            label: "Wholesale Deal - Price Reduced",
                            value: "d-808c60704a204318a49d729430605fba"
                          }
                        ]
                      }
                      onChangeHoa = {
                        this.onChangeHoa
                      }
                      label="Choose Flyer Type*"
                      validate = {
                        required
                      }
                      textField = "label"
                      valueField = "value"
                      component={DropdownCompV2}
                    />

                    <Field
                      name="category"
                      fieldName="Category"
                      readOnly = {
                        true
                      }
                      type="text"
                      component={RenderField}
                    />

                    <Field name = "status_prop"
                      textField = "label"
                      valueField = "value"
                      label = "Status*"
                      readOnly = {
                        true
                      }
                      id = "status_prop"
                      component = {
                        DropdownCompV2
                      }
                      options = {
                        PropertyStatus
                      }
                      />
                      <Field
                      name="sender_type"
                      placeholder = "-select-"
                      options = {
                        [
                          // {
                          //   label: "HBOA.co Listings",
                          //   value: "5734299"
                          // },
                          {
                            label: "HBOA.co Wholesale Listings",
                            value: "5743319"
                          },
                          // {
                          //   label: "House Buyers of America Wholesale Properties",
                          //   value: "554877"
                          // },
                          // {
                          //   label: "HBOA Wholesale Properties",
                          //   value: "606593"
                          // },
                          // {
                          //   label: "HBOA Deals",
                          //   value: "606591"
                          // }
                        ]
                      }
                      onChangeHoa = {
                        this.onChangeHoa
                      }
                      label="Choose Sender Type*"
                      validate = {
                        required
                      }
                      textField = "label"
                      valueField = "value"
                      component={DropdownCompV2}
                    />

                    <Field
                      name="street_address"
                      fieldName="Street Address"
                      readOnly = {
                        true
                      }
                      type="text"
                      component={RenderField}
                    />

                    <Field
                      name="city"
                      fieldName="City*"
                      readOnly = {
                        true
                      }
                      type="text"
                      component={RenderField}
                    />

                    <Field
                      name="state"
                      fieldName="State*"
                      type="text"
                      readOnly = {
                        true
                      }
                      component={RenderField}
                    />


                      <Field
                      name="market"
                      fieldName="Market*"
                      type="text"
                      readOnly = {
                        true
                      }
                      component={RenderField}
                    />


                    <Field
                      name="county"
                      fieldName="County*"
                      type="text"
                      readOnly = {
                        true
                      }
                      component={RenderField}
                    />

                    <Field
                      name="zipcode"
                      fieldName="Zipcode*"
                      readOnly = {
                        true
                      }
                      type="text"
                      component={RenderField}
                    />

                    <Field
                      name="price"
                      fieldName="Asking Price*"
                      readOnly = {
                        true
                      }
                      type="text"{
                        ...currencyMask
                      }
                      component={RenderField}
                    />

                    <Field
                      name="arv"
                      fieldName="After Repair Price*"
                      readOnly = {
                        true
                      }
                      type="text"{
                        ...currencyMask
                      }
                      component={RenderField}
                    />

                    <Field
                      name="spread"
                      fieldName="Spread"
                      readOnly = {
                        true
                      }
                      type="text" {
                        ...currencyMask
                      }
                      component={RenderField}
                    />

                    < Field name = "recName"

                        fieldName = "REC Name*"
                        type = "text"
                        readOnly = {
                          true
                        }
                        component = {
                            RenderField
                        }
                    />

                    < Field name = "recMobile"

                        fieldName = "REC Mobile"
                        type = "text" {
                          ...phoneMask
                        }
                        readOnly = {
                          true
                        }
                        component = {
                            RenderField
                        }
                    />

                    < Field name = "recEmail"

                        fieldName = "REC Email"
                        type = "text"
                        readOnly = {
                          true
                        }
                        component = {
                            RenderField
                        }
                    />

                    <Field name = "remarks"
                        fieldName = "Remarks*"
                        type = "text"
                        rows = {
                            3
                        }
                        readOnly={true}
                        textarea maxLength = "1000"
                        component = {
                            RenderField
                        }
                        validate = {
                            [required]
                        }
                    />

                    <div className="form-actions">
                      <button
                        type="submit"
                        className="btn green uppercase"
                        disabled={this.props.invalid || this.props.submitting}
                      >
                        {formAction === "ADD" ? "Add" : "Update"}
                      </button>
                    </div>
                  </form>
                </Tab>
              </Tabs>
            </div>
          </div>
        )}
      </div>
    );
  }

  onlyValue() {}

  onChangeHoa(data) {}

  changeDate(e) {
    this.setState({
      opendate: e
    }, (error) => {
      console.log('stateSet', this.state)
    })
    console.log(e, this.state)
  }

  upsertProp(data) {
    const {
      match
    } = this.props;
    console.log(data.OHD)
    if (data.OHD) {
      data.OHD = String(data.OHD)
    }
    if (data.register_wholesale === 'd-90f1539d85d24b7f951741f8f630dc64') {
      data.flyerType = 'New Wholesale Deal from House Buyers of America!';
    } else {
      data.flyerType = 'Price Reduced!  House Buyers of America Wholesale Deal!'
    }

    data['guid']=this.state.propertyGuid

    console.log(data);
    this.props.dispatch({
      type: 'Wholesale_Flyer_Info',
      data
    })
    this.props.history.push('/wholesaleflyer/preview');
  }

  getProp() {
    console.log('props calling', this.props);
    const {
      match,
      initialize
    } = this.props;
    /*extract plant id from request*/
    let roleID = match.params.id;
    console.log(roleID);
    if (roleID) {
      this.setState({
        isLoading: true
      });
      HTTP.Request("get", window.admin.flyerSingle, {
        id: roleID
      }).then(
        result => {
          // console.log("hvshvfhsv--->",result.data.wholesale_market)
          this.setState({
            isLoading: false,
            propertyGuid:result.data.guid
          });
          let propertyData = {
            id: result.data._id,
            ...((!!result.data.agents && !!result.data.agents.phone_no) && {recMobile: result.data.agents.phone_no}),
            ...((!!result.data.agents && !!result.data.agents.name) && {recName: result.data.agents.name}),
            ...((!!result.data.agents && !!result.data.agents.email) && {recEmail: result.data.agents.email}),
            ...((!!result.data.agents && !!result.data.agents.firstname) && {recFirstName: result.data.agents.firstname}),
            street_address: result.data.street_address.split(',').slice(0, 3).join(','),
            buynow: result.data.buy_now_price,
            status_prop: result.data.status_prop,
            category: result.data.category,
            city: result.data.city,
            market: result.data.market,
            state: result.data.city,
            county: result.data.county,
            zipcode: result.data.postal_code,
            price: result.data.price,
            sqft: result.data.sqft,
            bed: result.data.interior_features.bed,
            bath: result.data.interior_features.bath,
            remarks: result.data.remarks || result.data.publicRemarks ||  result.data.wholesaleRemarks,
            feature_image: result.data.feature_image,
            lot: result.data.exterior_features.lot,
            images: result.data.images,
            arv: result.data.avr,
            spread: result.data.avr - result.data.price,
            state_code: result.data.state_code,
            slug: result.data.slug,
            unit_no: result.data.unit_no ? result.data.unit_no : ''
          };
          if (result.data.openHouses && result.data.openHouses.length) {
            const openHouse = result.data.openHouses[0];
            propertyData.OHD_id = openHouse.id;
            propertyData.OHD = commonMoment(openHouse.date, 'YYYY-MM-DD').toDate();
            propertyData.starttime = commonMoment(openHouse.time, 'HH:mm').format('LT');
            propertyData.endtime = commonMoment(openHouse.time_to, 'HH:mm').format('LT');
            this.setState({
              openHouseSelected: true
            });
            this.setState({
              opendate: propertyData.OHD
            });
          } else {
            this.setState({
              openHouseSelected: false
            });
            this.setState({
              opendate: null
            });
          }
          if (result.data.key_features) {
            propertyData.property_type = result.data.key_features.property_type;
            propertyData.year_built = result.data.key_features.year_built;
            propertyData.parking_type = result.data.key_features.parking_type;
            propertyData.heating = result.data.key_features.heating;
            propertyData.cooling = result.data.key_features.cooling;
          }
          /*set data to form*/
          initialize(propertyData);
        }
      );
    }
  }
}

let AddWholesaleFlyer = reduxForm({
  form: "WholesaleFlyer"
})(WholesaleFlyer);

const mapStateToProp = state => {

  console.log('state', state);

  return ({
    personalInfo: state
  });



}

export default connect(mapStateToProp)(AddWholesaleFlyer);

