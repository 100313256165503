const text = {
  permissions:
    'Lorem ipsum dolor sit amet, est nibh dicam numquam no. Illum eripuit omittantur cum an, ne pro mucius utroque.',
  usertype:
    'Lorem ipsum dolor sit amet, est nibh dicam numquam no. Illum eripuit omittantur cum an, ne pro mucius utroque.',
  old_password:
    'Lorem ipsum dolor sit amet, est nibh dicam numquam no. Illum eripuit omittantur cum an, ne pro mucius utroque.',
  new_password:
    'Lorem ipsum dolor sit amet, est nibh dicam numquam no. Illum eripuit omittantur cum an, ne pro mucius utroque.',
  confirm_password:
    'Lorem ipsum dolor sit amet, est nibh dicam numquam no. Illum eripuit omittantur cum an, ne pro mucius utroque.',

  sitename:
    'Lorem ipsum dolor sit amet, est nibh dicam numquam no. Illum eripuit omittantur cum an, ne pro mucius utroque.',
  sitesummary:
    'Lorem ipsum dolor sit amet, est nibh dicam numquam no. Illum eripuit omittantur cum an, ne pro mucius utroque.',
  sitemap:
    'Lorem ipsum dolor sit amet, est nibh dicam numquam no. Illum eripuit omittantur cum an, ne pro mucius utroque.',
  siteemail:
    'Lorem ipsum dolor sit amet, est nibh dicam numquam no. Illum eripuit omittantur cum an, ne pro mucius utroque.',
  default_tax:
    'Lorem ipsum dolor sit amet, est nibh dicam numquam no. Illum eripuit omittantur cum an, ne pro mucius utroque.',
  ip_whitelist:
    'Lorem ipsum dolor sit amet, est nibh dicam numquam no. Illum eripuit omittantur cum an, ne pro mucius utroque.',
  ip_blacklist:
    'Lorem ipsum dolor sit amet, est nibh dicam numquam no. Illum eripuit omittantur cum an, ne pro mucius utroque.',

  subject:
    'Lorem ipsum dolor sit amet, est nibh dicam numquam no. Illum eripuit omittantur cum an, ne pro mucius utroque.',

  meta_title: 'It is advisable to keep limit from 80 to 100 characters.',
  metaTags: 'Please use comma (,) for multiple entries.',
  order:
    'Lorem ipsum dolor sit amet, est nibh dicam numquam no. Illum eripuit omittantur cum an, ne pro mucius utroque.',
  classes:
    'Lorem ipsum dolor sit amet, est nibh dicam numquam no. Illum eripuit omittantur cum an, ne pro mucius utroque.',
  custom_link:
    'Lorem ipsum dolor sit amet, est nibh dicam numquam no. Illum eripuit omittantur cum an, ne pro mucius utroque.',
  position:
    'Lorem ipsum dolor sit amet, est nibh dicam numquam no. Illum eripuit omittantur cum an, ne pro mucius utroque.',
  adtype:
    'Lorem ipsum dolor sit amet, est nibh dicam numquam no. Illum eripuit omittantur cum an, ne pro mucius utroque.',
  adcode:
    'Lorem ipsum dolor sit amet, est nibh dicam numquam no. Illum eripuit omittantur cum an, ne pro mucius utroque.',

  /* customer stories */
  customer_title: 'Represents the title of a story',
};

export default text;