import React, { Component } from 'react';
import { toast } from "react-toastify";
import Spinner from './Spinner';
import Images from './Images';
import Buttons from './Buttons';
import HTTP from "../../services/http";
import './images.css';
import { HelpBlock } from 'react-bootstrap';
import {arrayMove,sortableContainer, sortableElement} from 'react-sortable-hoc';

const gridStyles = {
  display: 'grid',
  gridTemplateColumns: 'repeat(4, 1fr)',
  gridGap: '16px',
};



const SortableContainer = sortableContainer(({children}) => {
  return <div style={gridStyles}> {children}</div>

});

export default class MultiFileUpload extends Component {
  constructor(props){
      super(props);
      this.state = {
        uploading: false,
      }
     
  }
  
  /*works every time file is browsed */
  onChange = e => {
    const errs = [] ;
    const isAllImageUpload = []
    const files = Array.from(e.target.files);
    console.log('All files ',e.target.files);
    /* calculating image sizes */
    const images = this.props.input.value || [];
    const lengthAllowed = images.length + files.length;
    /* Check images array length */
    if(lengthAllowed > 50){
      const msg = `Maximum 50 images are allowed`;
      return toast(msg, { type: "error" }) 
    }
    if ( files.length > 50) {
        const msg = `Only 50 images can be uploaded at a time`;
        return toast(msg, { type: "error" })  
    }
    
    /* Class scope to constructor  */
    let scope = this;
    /* Image instance to calculate image height and width */
    let imageSizeCheck = new Image()
    
    /* Creating Form Data of images browsed */
    const formData = new FormData()
    /* Type checking for each images browsed*/
    const types = ['image/png', 'image/jpeg', 'image/jpg'];
    /* Appending and Validating each images browsed*/
    for (let i = 0; i< files.length; i++) {
    // files.forEach((file, i) => {
        if (types.every(type => files[i].type !== type)) {
            errs.push(`'${files[i].type}' is not a supported format`);
        }
        else if (files[i].size > 900000) {
            console.log("files[i] size--->",files[i].size)
            errs.push(`'${files[i].name}' is too large, please pick smaller file(s) then 900kb`)
        }
        else{
          setTimeout(() => {
            formData.append(i, files[i])
            console.log('check append files', i)
            console.log('between form data');
            // formData.delete(i)
            HTTP.Request("post", window.admin.bulkUploadImages, formData)
            .then(result => {
              const { data } = result ;
              isAllImageUpload.push(result);
              const  images  = this.props.input.value || [];
              if(files.length === isAllImageUpload.length){
                this.setState({
                  uploading: false,
                })
            }
              this.props.input.onChange([...images,...data])
            })
            .catch(err => {
              this.setState({ uploading: false })
              if(err){
                toast(err.message, { type: "error" });
              }
            });   
      
              for(var pair of formData.entries()) {
          
                if (i > 0) {
                  // console.log('after form data', formData.delete(i-1)); 
                formData.delete(i-1);
                } 
             }    
  
          }, 1000);
        }
      
       
    // })
  }
    /*Notify User for Errors */
    if (errs.length) {
        return errs.forEach(err => toast(err,{type:"error"}))
    }
    /*Enable Spinner untill images uploaded */
    this.setState({ uploading: true });


    // HTTP.Request("post", window.admin.bulkUploadImages, formData)
    // .then(result => {
    //   const { data } = result ;
    //   const  images  = this.props.input.value || [];
    //   this.setState({
    //     uploading: false, 
    //   })
    //   this.props.input.onChange([...images,...data])
    // })
    // .catch(err => {
    //   this.setState({ uploading: false })
    //   if(err){
    //     toast(err.message, { type: "error" });
    //   }
    // });

  }


  render() {
    console.log("props--->",this.props.input.value)
    const { uploading } = this.state;
    const { meta:{touched,error,invalid},input } = this.props;
    const imagesLength =this.props.input.value || [];
    const content = () => {
      switch(true) {
        case uploading:
          return <Spinner />
        case imagesLength === 50:
            return <div></div>
        case imagesLength < 51:
          return (
                <Buttons 
                  onChange={this.onChange}
                  touched={touched} 
                  error={error} />
                )
        default:
          return <Buttons onChange={this.onChange} touched={touched} error={error} />
      }
    }

    return (
      <div>
        <div className='buttons'>
          {content()}
        </div>
        <div className="validation-message">
        <HelpBlock>
            {touched && error ? error : null}
        </HelpBlock>

        </div>
      </div>
    )
  }

  
  
  
}