import React, { Component } from "react";
import { Alert, HelpBlock } from "react-bootstrap";
import HTTP from "../../../services/http";
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";
import Loader from "../../common/loader";
import { ValidateOnlyAlpha } from "../../common/fieldValidations";
import DateFilter from "../../common/filter";
import { toast } from "react-toastify";
import { DropdownList } from 'react-widgets';
import xlsx from 'xlsx';

/**COMPONENTS */

import ROW from "./row";
import _ from "lodash";
import { change } from "redux-form";

var timer;
class Table extends Component {
    constructor(props) {
        super(props);
        this.state = {
            array: [],
            isLoading: false,
            searchQuery: "",
            marketList:[],
            stateList:[],
            marketOptions:[],
            stateOptions:[],
            stateFilter:"",
            marketFilter:"",
            activePage: 1,
            totalItemsCount: 1
        };
        /**event binding */
        this.getList = this.getList.bind(this);
        this.search = this.search.bind(this);
        this.filterByDate = this.filterByDate.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.resetFilters = this.resetFilters.bind(this);
        this.delete = this.delete.bind(this);
        this.downloadCountyDetails = this.downloadCountyDetails.bind(this);

    }

    componentWillMount() {
        this.getList();
    }

    downloadCountyDetails=()=> {
        this.setState({ isLoading: true });
        HTTP.Request("get", window.admin.downloadAllCounties)
            .then((response) => {
                const workSheet = xlsx.utils.json_to_sheet(response.data);
                this.workBook = xlsx.utils.book_new();
                xlsx.utils.book_append_sheet(this.workBook, workSheet, "Counties");
                xlsx.writeFile(this.workBook, "County_List.xlsx");
                this.setState({ isLoading: false });
            })
            .catch((error) => {
                this.setState({ isLoading: false });
                console.log("Error downloadCounties =>>>", error);
            });
    }

    render() {
        const { stateFilter,marketFilter,activePage,stateOptions,marketOptions, array, isLoading, totalItemsCount, seracherror } = this.state;
        var divStyle = {
            padding: "8px 8px 8px",
        };
        return (
            <div>
                {isLoading && <Loader />}

                <div className="portlet light bordered">
                    <div className="portlet-body min-heigh">
                        {/* actions search addnew  */}
                        <div className="table-toolbar">
                            <div className="row">
                                <div className="col-md-9">
                                    <DateFilter resetFunction={this.resetFilters} filterByDate={this.filterByDate}></DateFilter>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-7 col-sm-offset-5" />
                            </div>

                            <div className="row">
                                <div className="col-md-5">
                                    <div className="btn-group pull-left">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id = "search_field"
                                            placeholder="Search get Market(s)"
                                            onChange={this.search}
                                        />
                                        <HelpBlock style={{ color: "#e73d4a" }}>
                                            {seracherror ? seracherror : null}
                                        </HelpBlock>
                                    </div>

                                    <div className="col-md-12 pp-0 flex-end">
                                            <DropdownList
                                                data={stateOptions}
                                                defaultValue={undefined}
                                                textField="label"
                                                className="col-md-6 pp-0 pr2"
                                                valueField="value"
                                                name={"statefilter"}
                                                placeholder={'---Select State----'}
                                                value={this.state.stateFilter ? this.state.stateFilter : undefined}
                                                onChange={this.filterByDate}
                                            />

                                            <DropdownList
                                                data={marketOptions}
                                                defaultValue={undefined}
                                                textField="label"
                                                className="col-md-6"
                                                valueField="value"
                                                name={"marketfilter"}
                                                placeholder={'---Select Market----'}
                                                value={this.state.marketFilter ? this.state.marketFilter : undefined}
                                                onChange={this.filterByDate}
                                            />
                                        </div>

                                        <div className="col-md-3 pp-0 margin-top-10">
                                            <button className="btn  sbold red" onClick={this.resetFilters}>Reset</button>
                                        </div>
                                </div>
                                <div className="col-md-1">
                                    <div >
                                        <label style={divStyle} className={'label label-info bg-blue-steel bg-font-blue-steel'}>Total Count <strong>-</strong> {totalItemsCount}</label>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="btn-group pull-right">
                                        <Link to="/market-management-import">
                                            <button className="btn sbold green">
                                                {" "}
                                                <i className="fa fa-plus" />
                                                <span>import</span>
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="btn-group pull-right">
                                        <Link to="/market-management/add">
                                            <button className="btn sbold green">
                                                {" "}
                                                <i className="fa fa-plus" />
                                                <span> Add New</span>
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                                <div style={{textAlign:"end"}}  className="col-md-12">
                                   <button   className="btn sbold green" onClick={this.downloadCountyDetails}>Download Counties</button>
                                </div>
                            </div>
                        </div>
                        {/* actions search addnew END */}

                        {/* if list is empty */}
                        {!isLoading && !array.length ? (
                            <Alert bsStyle="warning">
                                <strong>No Data Found !</strong>
                            </Alert>
                        ) : (
                                <div style={{ display: "flow-root" }}>
                                    <table
                                        className="table table-striped table-bordered table-hover table-checkable order-column"
                                        id="sample_1"
                                    >
                                        <thead>
                                            <tr>
                                                <th width="10%">State</th>
                                                <th width="5%">State Code</th>
                                                <th width="15%">County</th>
                                                <th width="25%">Market </th>
                                                <th width="10%"> Status </th>
                                                <th width="10%"> Modified At </th>
                                                <th width="10%"> Created At </th>
                                                <th width="10%"> Action </th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {array.map(element => {
                                                return (
                                                    <ROW
                                                        key={element._id}
                                                        element={element}
                                                        delete={this.delete}
                                                    />
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                    <div style={{ float: "right" }}>
                                        <Pagination
                                            activePage={activePage}
                                            itemsCountPerPage={window.limit}
                                            totalItemsCount={
                                                totalItemsCount
                                            }
                                            pageRangeDisplayed={3}
                                            onChange={this.handlePageChange}
                                        />
                                    </div>
                                </div>
                            )}
                    </div>
                </div>
            </div>
        );
    }

    /* search data by date */
    filterByDate(data) {
        const { end_date, start_date } = data;
        if(!!data.type && data.type == "state"){
            this.state.stateFilter = data.value;
           
            // this.state.marketFilter = undefined
            // this.setState({
            //     stateFilter: data.value,
            //     marketOptions: _.uniqBy(uniqeMarket,"market").map(e=>{return {value:e.market,label:e.market,type:"market"}}),
            //     marketFilter:undefined
            // })
        }

        if(!!data.type && data.type == "market"){
            this.state.marketFilter = data.value;
            this.setState({
                marketFilter: data.value
            })
        }

        this.setState({
            start_date: start_date,
            end_date: end_date
        })
        this.getList({
            searchQuery: this.state.searchQuery ? this.state.searchQuery : '',
            marketFilter: this.state.marketFilter ? this.state.marketFilter : '',
            stateFilter: this.state.stateFilter ? this.state.stateFilter : '',
            ...data
        });
    }

    /* To reset all filters */
    resetFilters() {
        this.state.marketOptions = []
        this.setState({ activePage: 1,  seracherror: null, start_date: '',  end_date: '', searchQuery: '' ,stateFilter:undefined,marketFilter:undefined,marketOptions:[]});
        let searchEle = document.getElementById('search_field');
        if (searchEle) {
            searchEle.value = '';
        }
        this.props.history.push({
            search: '?page=' + 1
        });
        this.getList({
            page: 1
        });
    }

    /**PAGINATION */
    handlePageChange(eventKey) {
        this.setState({ activePage: eventKey });
        this.getList({
            page: eventKey ? eventKey : 1,
            start_date: this.state.start_date,
            end_date: this.state.end_date,
            searchQuery: this.state.searchQuery ? this.state.searchQuery : ""
        });
        /**to set query in route */
        this.props.history.push({
            search: "?page=" + eventKey
        });
    }
    /**SEARCH */
    search(e) {
        /**to remove Event Pooling  */
        e.persist();
        let searchedValue = e.target.value
        let seracherror = ValidateOnlyAlpha(searchedValue);
        if (seracherror) {
            this.setState({ seracherror: seracherror });
            return;
        }

        if (!searchedValue.length) {
            this.setState({ searchQuery: '', seracherror: '', activePage: 1 });
            this.props.history.push({
                search: '?page=' + 1
            });
        } else if (searchedValue.length > 2) {
            this.props.history.push({
            search: '?page=' + 1
        });
        this.setState({ searchQuery: searchedValue, activePage: 1, seracherror: seracherror});
        } else {
            this.setState({ seracherror: 'Enter minimum 3 characters to search.', searchQuery: '' });
            return;
        }
    
        clearTimeout(timer);
        timer = setTimeout(() => {
        this.getList({
            start_date: this.state.start_date,
            end_date: this.state.end_date,
            stateFilter:this.state.stateFilter,
            marketFilter:this.state.marketFilter,
            page: this.state.activePage ? this.state.activePage : 1,
            searchQuery: this.state.searchQuery
        });
        }, 1000);
    }

    /**to get list  */
    getList(params = {}) {
        // params.sortBy = 'market';
        /**to start Loader */
        this.setState({ isLoading: true });
        HTTP.Request("get", window.admin.getmarketList, params)
            .then(result => {
                this.setState({
                    isLoading: false,
                    array: result.data.offer,
                    totalItemsCount: result.data.offerCount
                });
            })
            .catch(err => this.setState({ isLoading: false }));
        HTTP.Request("get", window.admin.allMarketList)
            .then(result => {
                let uniqueStates = _.uniqBy(result.data,"state")
                this.setState({
                    isLoading: false,
                    stateOptions: uniqueStates.map(e=>{return {value:e.state , label: e.state,type:"state"}}),
                    marketList: result.data,
                    marketOptions: _.uniqBy(result.data,"market").map(e=>{return {value:e.market,label:e.market,type:"market"}}),
                });
            })
            .catch(err => this.setState({ isLoading: false }));
    }

    delete(id) {
        let page = 1;
        let data = {};
        data.editID = id;
        data.trash = true;
        HTTP.Request("post", window.admin.removeCounty, data)
            .then(result => {
                // console.log("Result on property deletion =>>>", result);
                /**to stop loader */
                this.setState({ isLoading: false });
                if (result.success) {
                    toast(result.message, { type: "success" });
                    /**refresh list after deletion */
                    this.getList();
                } else {
                    toast(result.message, { type: "error" });
                }
            })
            .catch(error => {
                // console.log("Error on deleting county =>>>", error);
                toast(error.message, { type: "error" });
                this.setState({ isLoading: false });
            });
    }
}

export default Table;
