import React, {Component} from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'react-bootstrap';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
/* import 'cropperjs/dist/cropper.css'; */

class ImageModal extends Component {

	constructor(props) {
	    super(props);
	    this.state = {
	      	isCropping: false	      	
	    };
	    this.cropImage = this.cropImage.bind(this);
		this.onExit = this.onExit.bind(this);
		this.updateWidth = this.updateWidth.bind(this);
	}
	onExit() {
		this.setState({isCropping: false})
		 this.props.toggle();
	}

	crop = e => {
		
		if (
				e.detail.width < 623 &&
				e.detail.height < 525
		) {
				this.refs.cropper.cropper.setData(
						Object.assign({}, e.detail, {
								width:
										e.detail.width < this.props.cropWidth
												? this.props.cropWidth
												: e.detail.width,
								height:
										e.detail.height < this.props.cropHeight
												? this.props.cropHeight
												: e.detail.height
						})
				);
		}

		return;
};
	cropImage(e) {
	
		const {setDataUrl} = this.props;
    	if (typeof this.cropper.getCroppedCanvas() === 'undefined') {
      		return;
		}
		this.setState({isCropping: true})
		
		if (!HTMLCanvasElement.prototype.toBlob) {
			
			Object.defineProperty(HTMLCanvasElement.prototype, 'toBlob', {
				value: function (callback, type, quality) {
				var canvas = this;
				setTimeout(function() {

					var binStr = atob( canvas.toDataURL(type, quality).split(',')[1] ),
						len = binStr.length,
						arr = new Uint8Array(len);

					for (var i = 0; i < len; i++ ) {
						arr[i] = binStr.charCodeAt(i);
					}
					console.log(type);
					callback( new Blob( [arr], {type: type || 'image/jpg'} ) );
					

				});
				}
			});
		}

		// const dataUrl=this.cropper.getCroppedCanvas({
		// 	imageSmoothingEnabled: false,
		//  	imageSmoothingQuality: 'low',
		// }).toDataURL('image/jpeg')
		// 	const k=this.dataURItoBlob(dataUrl);
		// 	console.log("inside-->",k)
    	this.cropper.getCroppedCanvas({
			imageSmoothingEnabled: false,
			imageSmoothingQuality: 'low',
		}).toBlob(  (blob) => {
			console.log(blob)
			setDataUrl({blob})
		},'image/jpeg', 0.50);
    	//this.setState({cropResult: this.cropper.getCroppedCanvas().toDataURL()});
	  }

	   dataURItoBlob(dataURI) {
		// convert base64 to raw binary data held in a string
		// doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
		var byteString = atob(dataURI.split(',')[1]);
	  
		// separate out the mime component
		var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
	  
		// write the bytes of the string to an ArrayBuffer
		var ab = new ArrayBuffer(byteString.length);
	  
		// create a view into the buffer
		var ia = new Uint8Array(ab);
	  
		// set the bytes of the buffer to the correct values
		for (var i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i);
		}
	  
		// write the ArrayBuffer to a blob, and you're done
		var blob = new Blob([ab], {type: mimeString});
		return blob;
	  
	  }
	  
	  updateWidth() {
		 
			let ele = document.querySelector('.modal-lg.modal-dialog');
			console.log(this.props);
			ele.style.width = `${this.props.width}px`;
			// ele.style.height = this.props.height;

		//   setTimeout(()=>{
		// 	ele.style.width =this.props.width;
		// 	ele.style.height =this.props.height;
		//   },500)
		
	  }

	 
  	render() {
  			
		  const {open, toggle, src,width,height,ratioUpper,ratioLower,minHeight,minWidth,imagetype} = this.props;
		  const {isCropping} = this.state;

		  var ratioUpperCalculatedSize = 0;
		  var ratioLowerCalculatedSize = 0;
	
		  if(this.props.imagetype && this.props.imagetype == 'gallery'){
		  	 ratioUpperCalculatedSize = ratioUpper/3;
		  	 ratioLowerCalculatedSize = ratioLower/3;
		  }

	    return (
    <div>
	
				<Modal 
				id="lg-modal"
				show={open}
			/* 	onEntered={this.updateWidth} */
				onHide={this.onExit}
				onExit={this.onExit}
				backdrop={'static'}
				bsSize = {'lg'}
				style={{ zIndex: '2147483639'}}			
                >
                    <Modal.Header closeButton className='theme-bg'>
                        <Modal.Title>
						Crop Image
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body  >
					{
						<Cropper
						style={{width:width,height:height,maxWidth:"900px",maxHeight:"400px",margin:"auto" }}
						aspectRatio={ratioUpper/ratioLower}
	                    guides={false}
	                    src={src}
						viewMode={1}
						checkOrientation={false}
						minCropBoxHeight={ratioUpper+ratioLowerCalculatedSize}
						minCropBoxWidth={ratioLower+ratioUpperCalculatedSize}
						maxCropBoxHeight={ratioUpper+ratioLowerCalculatedSize}
						maxCropBoxWidth={ratioLower+ratioUpperCalculatedSize}
						zoomable={false}
						autoCropArea={0}
						cropBoxResizable={true}
	                    ref={cropper => { this.cropper = cropper; }}
						/>}

					
                    </Modal.Body>

                    <Modal.Footer>
					<Button color="primary" onClick={this.cropImage} disabled={isCropping}>{isCropping ? 'Cropping ...' :'Crop'}</Button>{' '}
	            	<Button color="secondary" onClick={toggle}>Cancel</Button>
                    </Modal.Footer>
                </Modal>
            </div>

			
		);
	
  	}
}

export default ImageModal;
