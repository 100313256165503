import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Panel, PanelGroup } from 'react-bootstrap';

/**import RoutesValue */
import { RoutesValue } from '../common/options';

/*import images*/

class SideBar extends Component {
  constructor(props) {
    super(props);
    /*bind this with current class object*/
    this.renderPrivteRoute = this.renderPrivteRoute.bind(this);
    this.renderPublicRoute = this.renderPublicRoute.bind(this);
    this.renderRoute = this.renderRoute.bind(this);
  }

  render() {
    /*if user is not logged in then return empty */
    if (!this.props.isAdminLoggedIn) return null;

    return (
      <div>
        {/* <!-- BEGIN SIDEBAR --> */}
        <div className="page-sidebar-wrapper">
          <div
            className={
              this.props.isToggle
                ? 'page-sidebar navbar-collapse collapse'
                : 'page-sidebar navbar-collapse collapse show'
            }
          >
            <div className="page-sidebar-menu">
              <PanelGroup accordion id="accordion-example">
                {/* Dashboard */}
                {this.renderRoute('Dashboard', '/', 'fa fa-home')}
                {/* Add Property */}

                {/* Add Property */}
                {this.renderRoute('Property', '/property-list', 'fa fa-home')}
                {/* Disposition Consultant */}
                {/* {this.renderRoute('Disposition Consultant', '/disposition-consultant-list', 'fa fa-home')} */}
                {/* property content */}
                {/* {this.renderRoute(
                  "Buy Landing Page",
                  "/property-cms-list",
                  "fa fa-home"
                )} */}
                {this.renderRoute('Buyer Leads', '/rec_contacts', 'fa fa-home')}
                {this.renderRoute('Offers', '/offers', 'fa fa-home')}
                {/* Investor*/}
                {/* {
                    route: "/investor-list",
                    value: RoutesValue.Investors,
                    name: "Investor fake listing"
                  }   */}

                {/* Buyers */}
                {/* {this.renderRoute('Buyers Listing', '/customers', 'fa fa-user')} */}

                {/* {this.renderPrivteRoute('BuyersModule', 'fa fa-user', [
                  {
                    route: '/customers',
                    value: RoutesValue.BUYLANDINGPAGE,
                    name: 'Buyers Listing',
                  },
                  // {
                  //   route:'/buyers-cms-add',
                  //   value:RoutesValue.BUYERS,
                  //   name:"Buyers CMS"
                  // },
                ])} */}
                {/* {this.renderRoute("Buyers", "/buyers-list", "fa fa-user") }
                {this.renderPrivteRoute("Buyers", "fa fa-user", [
                  {
                    route: "/buyers-list",
                    value: RoutesValue.BUYLANDINGPAGE,
                    name: "Buyers Listing"
                  },
                  {
                    route:'/buyers-cms-add',
                    value:RoutesValue.BUYERS,
                    name:"Buyers CMS"
                  },

                ])} */}
                {/* this.renderPrivteRoute("Buyers", "fa fa-user", [
                  {
                    route: "/buyers-list",
                    value: RoutesValue.BUYLANDINGPAGE,
                    name: "Buyers Listing"
                  }
                ]) */}
                {/* Realtors */}
                {/* { this.renderPrivteRoute("Realtors", "fa fa-cart-plus", [
                  {
                    route: "/realtors-distressed-list",
                    value: RoutesValue.Realtors,
                    name: "Distressed Property"
                  },
                  {
                    route:"/realtor-signup-list",
                    value: RoutesValue.REALTORSIGNUPMANAGE,
                    name: "Realtors Signup"
                  },
                 {
                    route: "/realtor-cms-add",
                    value: RoutesValue.REALTORSCMSMANAGE,
                    name: "Realtors CMS"

                  }
                ]) } */}
                {/*Get An Offer */}
                {this.renderPrivteRoute('Get Offer Leads', 'fa fa-user', [
                  {
                    route: '/get-offer-list',
                    value: RoutesValue.GET_AN_OFFER,
                    name: 'Incoming Leads',
                  },
                  {
                    route: '/get-staging-offer-list',
                    value: RoutesValue.GET_AN_OFFER,
                    name: 'Staging Incoming Leads',
                  },
                ])}

                {/* new sign up lead */}
                {this.renderPrivteRoute(
                  'Out of Market Leads',
                  'fa fa-cart-plus',
                  [
                    {
                      route: '/get-out-of-market-leads',
                      value: RoutesValue.OUT_OF_MARKET_LEADS,
                      name: 'Signup Leads',
                    },
                  ],
                )}

                {/*Review Management */}
                {/* {this.renderPrivteRoute(
                                    "Review Management",
                                    "fa fa-user",
                                    [
                                        {
                                            route: "/get-review-list",
                                            value: RoutesValue.GET_AN_OFFER,
                                            name: "Incoming Review"
                                        }
                                    ]
                                )} */}

                {/*Market Management*/}
                {this.renderPrivteRoute(
                  'County Management',
                  'fa fa-shopping-cart',
                  [
                    {
                      route: '/market-image-list',
                      value: RoutesValue.OUT_OF_MARKET_LEADS,
                      name: 'Market Management',
                    },
                    {
                      route: '/market-management-list',
                      value: RoutesValue.MARKET_MANAGEMENT,
                      name: 'County to Market List',
                    },
                  ],
                )}

                {/* Feedback */}
                {this.renderPrivteRoute('Contact Us', 'fa fa-comments', [
                  {
                    route: '/feedback',
                    value: RoutesValue.SHORT_FORM_LEAD,
                    name: 'Short Form Leads',
                  },
                ])}

                {/*  Career Management */}
                {this.renderPrivteRoute('Career', 'fa fa-mortar-board', [
                  {
                    route: '/career',
                    value: RoutesValue.CAREER,
                    name: 'Applications Received',
                  },
                ])}

                {/*Property Management*/}
                {/* {this.renderPrivteRoute(
                                    "Photo Gallery",
                                    "fa fa-home",
                                    [
                                        {
                                            route: "/property-management-list",
                                            value:
                                                RoutesValue.PROPERTY_MANAGEMENT,
                                            name: "Manage properties"
                                        }
                                    ]
                                )} */}

                {/* Admin Management */}

                {/* Medical Tests */}
                {/*this.renderPrivteRoute(
                                    "Medical Tests",
                                    "fa fa-flask",
                                    [
                                        {
                                            route: "/test",
                                            value: RoutesValue.MANAGE_ROLE,
                                            name: "Manage Tests"
                                        }
                                    ]
                                )*/}

                {/* Payments */}
                {/*this.renderPrivteRoute(
                                    "Payments",
                                    "fa fa-money",
                                    [
                                        {
                                            route: "/payment",
                                            value: RoutesValue.MANAGE_ROLE,
                                            name: "Payment History"
                                        }
                                    ]
                                )*/}

                {/*CMS */}
                {this.renderPrivteRoute('CMS', undefined, [
                  {
                    route: '/pages',
                    value: RoutesValue.MANAGE_PAGES,
                    name: 'Pages',
                  },
                  {
                    route: '/top-articles',
                    value: RoutesValue.MANAGE_PAGES,
                    name: 'Top Articles',
                  },
                  {
                    route: '/footer-pages',
                    value: RoutesValue.MANAGE_PAGES,
                    name: 'Footer Links',
                  },
                  // {
                  //   route: '/redirect-links',
                  //   value: RoutesValue.MANAGE_PAGES,
                  //   name: 'Redirect Links',
                  // },
                  {
                    route: '/city-pages',
                    value: RoutesValue.MANAGE_PAGES,
                    name: 'We Buy Houses',
                  },
                  {
                    route: '/blogs',
                    value: RoutesValue.MANAGE_BLOGS,
                    name: 'Blogs',
                  },
                  {
                    route: '/slides',
                    value: RoutesValue.PRESS_RELEASE,
                    name: 'Press Releases',
                  },
                  {
                    route: '/cms-blocks',
                    value: RoutesValue.CMS,
                    name: 'CMS Blocks',
                  },
                  {
                    route: '/social',
                    value: RoutesValue.CUSTOMER_STORIES,
                    name: 'Customer Stories',
                  },
                  {
                    route: '/ads',
                    value: RoutesValue.PHOTO_GALLERY,
                    name: 'Photo Gallery',
                  },
                  /*
                                    {
                                        route: "/social",
                                        value: RoutesValue.MANAGE_BLOGS,
                                        name: "Social"
                                    },*/
                  {
                    route: '/reviews',
                    value: RoutesValue.REVIEWS,
                    name: 'Reviews',
                  },
                  {
                    route: '/investor-cms-add',
                    value: RoutesValue.INVESTORCMSMANAGE,
                    name: 'Investor CMS',
                  },
                  {
                    route: '/buyers-cms-add',
                    value: RoutesValue.BUYERS,
                    name: 'Buyers CMS',
                  },
                  {
                    route: '/realtor-cms-add',
                    value: RoutesValue.REALTORSCMSMANAGE,
                    name: 'Realtors CMS',
                  },
                  {
                    route: '/faq',
                    value: RoutesValue.FAQ,
                    name: 'FAQ',
                  },
                  {
                    route: '/faq-category-list',
                    value: RoutesValue.FAQ_CATEGORY,
                    name: 'FAQ Category',
                  },
                  {
                    route: '/property-cms-list',
                    value: RoutesValue.BUYLANDINGPAGE,
                    name: 'Buy Landing Page',
                  },
                  {
                    route: '/buy-property-cms-list',
                    value: RoutesValue.BUYLANDINGPAGE,
                    name: 'Buy Page',
                  },
                ])}

                {this.renderPrivteRoute('Marketing', 'fa fa-user', [
                  // {
                  //   route:"/investor-cms-add",
                  //   value:RoutesValue.INVESTORCMSMANAGE,
                  //   name:"Investor CMS"
                  // },
                  {
                    route: '/users',
                    value: RoutesValue.REALSTATEINVESTORS,
                    name: 'Buyers',
                    //  name: "Real Estate Investors"
                  },
                ])}

                {/* Email Templates */}
                {this.renderPrivteRoute(
                  'Marketing Templates',
                  'fa fa-envelope',
                  [
                    {
                      route: '/email-management',
                      value: RoutesValue.MANAGE_EMAIL,
                      name: 'Email Templates',
                    },
                    {
                      route: '/sms-management',
                      value: RoutesValue.MANAGE_EMAIL,
                      name: 'SMS Templates',
                    },
                    {
                      route: '/renovatedflyer',
                      value: RoutesValue.RENOVATED_FLYER,
                      name: 'Renovated Flyer',
                    },
                    {
                      route: '/wholesaleflyer',
                      value: RoutesValue.WHOLESALE_FLYER,
                      name: 'Wholesale Flyer',
                    },
                  ],
                )}

                {/* Profile */}
                {this.renderPrivteRoute('Profile', 'fa fa-user', [
                  {
                    route: '/profile',
                    value: RoutesValue.PROFILE,
                    name: 'Profile',
                  },
                  {
                    route: '/change-password',
                    value: RoutesValue.CHANGE_PASSWORD,
                    name: 'Change Password',
                  },
                ])}

                {/* Admin Management  */}

                {this.renderPrivteRoute('Admin Management', 'fa fa-user', [
                  {
                    route: '/role-management',
                    value: RoutesValue.MANAGE_ROLE,
                    name: 'Manage Roles',
                  },
                  {
                    route: '/user-management',
                    value: RoutesValue.MANAGE_USER,
                    name: 'Manage Users',
                  },
                  {
                    route: '/author-management',
                    value: RoutesValue.MANAGE_USER,
                    name: 'Manage Authors',
                  },
                  {
                    route: '/third-party-services',
                    value: RoutesValue.MANAGE_ROLE,
                    name: 'Manage Third Party Services',
                  },
                  {
                    route: '/cache-management',
                    value: RoutesValue.MANAGE_USER,
                    name: 'Manage Page Cache',
                  },
                ])}

                {/*Configurations */}
                {this.renderPrivteRoute('Configurations', 'fa fa-cogs', [
                  {
                    route: '/settings',
                    value: RoutesValue.MANAGE_SETTINGS,
                    name: 'Settings',
                  },
                  /*{
                                            route: "/taxes",
                                            value: RoutesValue.MANAGE_BLOGS,
                                            name: "Taxes"
                                        },*/
                  /*  {
                                            route: "/ip-management",
                                            value: RoutesValue.MANAGE_BLOGS,
                                            name: "IP Management"
                                        } */
                ])}

                {/*Reports */}
                {/*this.renderPrivteRoute(
                                    "Reports",
                                    "fa fa-file",
                                    [
                                        {
                                            route: "/reports/customer",
                                            value: RoutesValue.MANAGE_PAGES,
                                            name: "Customer Reports"
                                        },
                                        {
                                            route: "/reports/user",
                                            value: RoutesValue.MANAGE_PAGES,
                                            name: "User Reports"
                                        },
                                        {
                                            route: "/reports/payment",
                                            value: RoutesValue.MANAGE_BLOGS,
                                            name: "Payment Reports"
                                        },
                                        {
                                            route: "/reports/testrequest",
                                            value: RoutesValue.MANAGE_BLOGS,
                                            name: "Test Request Reports"
                                        },
                                        {
                                            route: "/reports/shipping",
                                            value: RoutesValue.MANAGE_BLOGS,
                                            name: "Shipping Reports "
                                        }
                                    ]
                                )*/}

                {/* Sitemap Management */}
                {this.renderPrivteRoute(
                  'Sitemap Management',
                  'fa  fa-file-code-o',
                  [
                    {
                      route: '/site-map',
                      value: RoutesValue.MANAGE_SITEMAP,
                      name: 'Sitemap Management',
                    },
                  ],
                )}
                {/* Export */}
                {this.renderPrivteRoute('Reports', 'fa fa-file', [
                  {
                    route: '/export-cms',
                    value: RoutesValue.MANAGE_PAGES,
                    name: 'Export CMS',
                  },
                  {
                    route: '/fix-page-issues',
                    value: RoutesValue.MANAGE_PAGES,
                    name: 'Fix 404 Issue',
                  }
                ])}
                {/*Logs */}
                {this.renderPrivteRoute('Logs', 'fa fa-cog', [
                  {
                    route: '/logs/login',
                    value: RoutesValue.MANAGE_LOGS,
                    name: 'Login Logs',
                  },
                  {
                    route: '/logs/access',
                    value: RoutesValue.MANAGE_LOGS,
                    name: 'Access Logs',
                  },
                  {
                    route: '/logs/audit',
                    value: RoutesValue.MANAGE_LOGS,
                    name: 'Audit Logs',
                  },
                  {
                    route: '/logs/upload',
                    value: RoutesValue.MANAGE_LOGS,
                    name: 'Bulk Upload Logs',
                  },
                  {
                    route: '/logs/mongotocrm',
                    value: RoutesValue.MANAGE_LOGS,
                    name: 'Mongo to CRM Logs',
                  },
                  {
                    route: '/logs/crmlogs',
                    value: RoutesValue.MANAGE_LOGS,
                    name: 'CRM to Mongo Logs',
                  },
                  {
                    route: '/logs/dynamicslogs',
                    value: RoutesValue.MANAGE_LOGS,
                    name: 'Dynamics Web API Logs',
                  },
                  {
                    route: '/logs/propertysynclogs',
                    value: RoutesValue.MANAGE_LOGS,
                    name: 'Property Sync Logs',
                  },
                ])}
              </PanelGroup>
            </div>
          </div>
        </div>
        {/* <!-- END SIDEBAR --> */}
      </div>
    );
  }

  renderPrivteRoute(heading, icon = 'fa fa-bullseye', array) {
    const { permissionsArray } = this.props;

    return (
      <Panel eventKey={Math.random()}>
        <Panel.Heading>
          <Panel.Title toggle>
            <div>
              <i className={icon} /> &nbsp; {`${heading}`}
              <i className="fa fa-angle-left" style={{ float: 'right' }} />
            </div>
          </Panel.Title>
        </Panel.Heading>
        <Panel.Body collapsible bsClass="page-sidebar-menu">
          {array.map((e, index) => {
            if (!permissionsArray.includes(e.value)) return null;
            else
              return (
                <p key={index}>
                  <Link to={e.route}>{e.name}</Link>
                </p>
              );
          })}
        </Panel.Body>
      </Panel>
    );
  }

  renderPublicRoute(heading, icon = 'fa fa-bullseye', array, omitIcon = false) {
    return (
      <Panel eventKey={Math.random()}>
        <Panel.Heading>
          <Panel.Title toggle>
            <div className="sidebarNavigationContainer">
              <i className={icon} /> &nbsp; {`${heading}`}
              {!omitIcon && (
                <i className="fa fa-angle-left" style={{ float: 'right' }} />
              )}
            </div>
          </Panel.Title>
        </Panel.Heading>
        <Panel.Body collapsible>
          {array.map((e, index) => {
            return (
              <p key={index}>
                <Link to={e.route}>{e.name}</Link>
              </p>
            );
          })}
        </Panel.Body>
      </Panel>
    );
  }

  renderRoute(heading, route, icon = 'fa fa-bullseye') {
    return (
      <Panel eventKey={Math.random()} className="no-child">
        <Panel.Body>
          <i className={icon} /> &nbsp;
          <Link to={route}>{heading}</Link>
        </Panel.Body>
      </Panel>
    );
  }
}

/*get props*/
function mapStatesToProps(state) {
  let permissionsArray =
    state.admin && state.admin.permissions ? state.admin.permissions : [];
  return {
    isAdminLoggedIn: state.admin.token ? true : false,
    permissionsArray: permissionsArray,
  };
}

export default connect(mapStatesToProps)(SideBar);
