import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { push } from 'react-router-redux';
import { toast } from 'react-toastify';
import HTTP from "../../services/http";

import { required, ValidateOnlyAlpha,number } from '../common/fieldValidations';

/**COMPONENT */
import RenderFiled from "../common/renderField";
import PageHeader from "../common/pageheader";
import Loader from "../common/loader";
import { ADMIN_TRACK_AUDIT_LOGS } from '../common/actions';
/**CONSTANT DATA */
import { OPTIONS } from "../common/options"
import { defaultTheme } from 'react-autosuggest/dist/theme';
import Autosuggest from 'react-autosuggest';
import DropdownComp from "../common/DropdownList";


class AddTopArticles extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            formAction: "ADD",
            status: true,
            suggestions:[],
            sitemapLinks:[],
            value:"",
            linkValid:true
        }
        /**event binding  */
        this.addEditRole = this.addEditRole.bind(this);
        this.addTopArticle = this.addTopArticle.bind(this);
        this.getARole = this.getARole.bind(this);
        this.getTopArticle = this.getTopArticle.bind(this);
        this.onBlurEvent = this.onBlurEvent.bind(this);
    }

    componentWillMount() {
        console.log('roles', OPTIONS);
        this.getTopArticle()
        this.getSitemapLinks();

    }

    getSitemapLinks(){
        HTTP.Request('get', window.admin.sitemapActiveListing)
            .then(data => {
              if (data.Error) {
                this.setState({
                  sitemapLinks: []
                });
              } else {
                this.setState({
                  sitemapLinks: data.data.map(s=>{
                    return s.link.split(".com")[1]
                  }).filter(e=>{
                    return !["/sell-my-house-fast","/sell-my-house-home","/site-map","/sitemap","/terms-of-service"].includes(e)
                  })
                });
                this.state.sitemapLinks.push("/")
              }
            })
      }
    
    onSugChange = (event, { newValue }) => {
    this.setState({
        value: newValue,
    });
    if(this.state.sitemapLinks.includes(newValue)){
        this.setState({
          linkValid:true
        })
      }
      else{
        this.setState({
            linkValid:false
          })
      }
    };

    render() {
        const { handleSubmit } = this.props;
        const { selectedpermissions, isLoading,value,linkValid,formAction,suggestions, status } = this.state
        const renderSuggestion = suggestion => (
            <div>
              {suggestion}
            </div>
          );
        var inputProps = {
            placeholder: 'Url',
            value,
            onChange: this.onSugChange,
            onBlur:this.onBlurEvent
         };
        return (
            <div>
                {isLoading ? <Loader /> : <div><PageHeader route={formAction === "ADD" ? "Add New Top Article" : "Edit Top Article"} parent="Top Article Management" parentRoute="/role-management" />

                    <div className="tab-pane active" >
                        <form onSubmit={handleSubmit(this.addTopArticle)}  >
                            <Field name="title" fieldName="Title*" type="text" component={RenderFiled} validate={[required, ValidateOnlyAlpha]} />
                            <Autosuggest
                                suggestions={suggestions}
                                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                getSuggestionValue={this.getSuggestionValue}
                                renderSuggestion={renderSuggestion}
                                inputProps={inputProps}
                                id="link"
                                name="link"
                                value = {value}
                                theme={defaultTheme}
                            />
                            {!linkValid || value == "" ?(<p className='text-danger'>The redirect link is not valid</p>):("")}
                            {/* <Field name="position"
                                fieldName="Position*"
                                component={RenderFiled}
                                validate={number}
                            /> */}
                            <Field 
                                name="status"
                                id='status'
                                options={[{ label: "Active", value: true }, { label: "Inactive", value: false }]}
                                label="Status"
                                defaultValue={status ? "Active" : "Inactive"}
                                textField="label"
                                valueField="value"
                                component={DropdownComp}
                            />
                            <br />
                            <div className="form-actions">
                                <button type="submit" className="btn green uppercase" disabled={this.props.invalid || this.props.submitting}>{formAction == 'ADD'?('ADD'):("Update")}</button>
                            </div>
                        </form>
                    </div>
                </div>
                }

            </div>
        );
    }

    addEditRole(data) {
        data.type = "admin";
        HTTP.Request("post", window.admin.addEditRole, data)
            .then(result => {
                this.props.dispatch(push("/role-management"));
                toast(result.message, { type: "success" });

                /*log audits for user*/
                this.props.dispatch({
                    type: ADMIN_TRACK_AUDIT_LOGS,
                    action: {
                        comment: "Modified the content of Role - " + result.data.title,
                        type: "audit"
                    }
                });
            })
            .catch(err => {
                if (err && err.errors.length > 0) err.errors.map(message => toast(message, { type: "error" }))
                else toast(err.message, { type: 'error' })
            });
    }

    addTopArticle(data){
        const { match } = this.props;
        if(!this.state.value || !this.state.linkValid){
            toast.error("Redirect URL is incorrect please select a valid URL!");
            return false
          }
        if(data.status == undefined){
            data["status"]=true
          }
        HTTP.Request("post", window.admin.upsertTopArticles, match.params.id ? {...data,link:"https://stage.housebuyersofamerica.com"+this.state.value,_id:match.params.id} : {...data,link:"https://stage.housebuyersofamerica.com"+this.state.value})
        .then(result => {
            this.props.dispatch(push("/top-articles"));
            toast(result.message, { type: "success" });
        })
        .catch(err => {
            if (err && err.errors.length > 0) err.errors.map(message => toast(message, { type: "error" }))
            else toast(err.message, { type: 'error' })
        });
    }

    onSuggestionsFetchRequested = ({ value }) => {
        let sug = this.state.sitemapLinks.filter((s)=>{
          return !!s ? s.includes(value) :  false
        })
        this.setState({
          suggestions:sug
        })
      };

    onSuggestionsClearRequested = () => {
    this.setState({
        suggestions: []
    });
    };

    getSuggestionValue = suggestion => {
        this.setState({
          linkValid:true
        })
        return suggestion
    }

    onBlurEvent=ss=>{
        if(this.state.sitemapLinks.includes(ss.currentTarget.value)){
            this.setState({
              linkValid:true
            })
          }
          else{
            this.setState({
                linkValid:false
              })
          }
      }
    

    getARole() {
        const { match, initialize } = this.props;
        /*extract plant id from request*/
        let roleID = (match.params.id) ? match.params.id : null;

        if (roleID) {
            this.setState({ isLoading: true, formAction: "EDIT" })
            HTTP.Request("get", window.admin.getARole, { id: roleID })
                .then(result => {
                    this.setState({ isLoading: false, selectedpermissions: result.data.permissions, status: result.data.status })

                    /*set data to form*/
                    initialize(result.data);
                })
        }
    }

    getTopArticle() {
        const { match, initialize } = this.props;
        /*extract plant id from request*/
        let id = (match.params.id) ? match.params.id : null;
        if (id) {
            this.setState({ isLoading: true, formAction: "EDIT" })
            HTTP.Request("get", window.admin.getTopArticlesById, { id: id })
                .then(result => {                    
                    this.setState({ isLoading: false,status:result.data.status,value:result.data.link.split(".com")[1]})

                    /*set data to form*/
                    initialize({title:result.data.title,status:result.data.status});
                })
        }
    }

}

//decorate form component
let topArticlesMangment_Form = reduxForm({
    form: "topArticlesMang_Form",
})(AddTopArticles);


export default topArticlesMangment_Form;

