import { Field, reduxForm } from 'redux-form';
import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';

import DatePicker from '../../common/DateTimePicker';
import TimePicker from '../../common/timePicker';
import HTTP from '../../../services/http';

class CreateOpenHouses extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {onAddOpenHouse} = this.props;
    
    const handleAddOpenHouse = (data, e) => {
      let addData = { ...data, property_id: this.props.property_id };

      const tempTimeTo = Date.parse('01/01/2011 ' + data.time_to);
      const tempTime = Date.parse('01/01/2011 ' + data.time);
      const timeDiff = Math.abs(tempTimeTo - tempTime) / 36e5;
      const isTimeInValid = tempTimeTo > tempTime;
      
      if(data.time && data.time_to && data.date) {
        if(!isTimeInValid) {
          toast('From Time should be less then To Time.', { type: 'error' });
          return false;
        }
        if(timeDiff > 2) {
          toast('Time difference should not be more then 2 hours.', { type: 'error' });
          return false;
        }
        HTTP.Request('post', window.admin.addOpenHouse, addData)
        .then((result) => {
          toast(result.message, { type: 'success' });
          this.props.onAddOpenHouse();
          this.props.reset();
          })
          .catch((err) => {
            toast(err.message, { type: 'error' });
          });
      } else {
        toast('Please select valid Date and Time.', { type: 'error' });
      }
    };

    return (
      <div>
        <div className="row">
          <div className="col-sm-12">
            <h2>Add Open House</h2> <hr />
          </div>
        </div>
        <form onSubmit={this.props.handleSubmit(handleAddOpenHouse)}>
          <div className="row">
            <div className="col-sm-6">
              <Field
                name="date"
                id="date"
                fieldName="Date*"
                component={DatePicker}
                min={new Date()}
                max={new Date('1-01-2030')}
              />
              <button className="btn green w-100" type="submit">
                Create Open House
              </button>
            </div>
            <div className="col-sm-3">
              <Field
                name="time"
                id="time"
                fieldName="Time From*"
                component={TimePicker}
              />
            </div>
            <div className="col-sm-3">
              <Field
                name="time_to"
                id="time_to"
                fieldName="Time To*"
                component={TimePicker}
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}
let AddOpenHouses = reduxForm({
  form: 'CreateOpenHouses',
})(CreateOpenHouses);
const mapStateToProps = (state) => {
  return {
    time:
      state &&
      state.form &&
      state.form.OpenHouses &&
      state.form.OpenHouses.values &&
      state.form.OpenHouses.values.time,
    time_to:
      state &&
      state.form &&
      state.form.OpenHouses &&
      state.form.OpenHouses.values &&
      state.form.OpenHouses.values.time_to,
  };
};
export default connect(mapStateToProps)(AddOpenHouses);
