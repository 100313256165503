import { HelpBlock, Table, Alert } from 'react-bootstrap';
import Pagination from "react-js-pagination";
import { DropdownList } from 'react-widgets';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import { connect } from "react-redux";
import xlsx from 'xlsx';

import { ValidateOnlyAlpha } from "../../common/fieldValidations";
import HTTP from '../../../services/http';
import Loader from "../../common/loader";
import ROW from "./row";

var timer;
class TableComp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            array: [],
            statusFilter:"",
            dealTypeFilter:"",
            markets:[],
            options: [
                { label: "Renovated", value: "renovated" },
                { label: "Wholesale", value: "wholesale" },
            ],
            statusOptions:[
                { label: "Active", value: "active" },
                { label: "Inactive", value: "in-active" },
            ],
            isLoading: false,
            searchQuery: '',
            activePage: 1,
            totalItemsCount: 1
        }
        this.getProperty = this.getProperty.bind(this);
        this.search = this.search.bind(this);
        this.filterByDate = this.filterByDate.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.downloadPropertyDetails = this.downloadPropertyDetails.bind(this);
        this.delete = this.delete.bind(this);
        this.handleReset = this.handleReset.bind(this);
    }

    componentWillMount() {
        this.getMarkets()
        const queryParams = queryString.parse(this.props.location.search);
        this.getProperty({
            page: queryParams.page > 1 ? queryParams.page : 1
        });
        this.setState({activePage: queryParams.page > 1 ? queryParams.page : 1})
    }

    componentWillReceiveProps() {
        console.log('store props', this.props)
    }

    render() {
        const { array, isLoading, seracherror, options,statusOptions,markets } = this.state;
        return (
            <div>
                {isLoading && <Loader />}
                <div className="portlet light bordered">
                    <div className="portlet-body">
                        {/* actions search addnew  */}
                        <div className="table-toolbar">
                            <div className="row">
                                <div className="col-md-9">
                                    <div className="row">
                                        <div className="col-md-2">
                                            <label>Deal Type</label>
                                        </div>
                                        <div className="col-md-4">
                                            <DropdownList
                                                data={options}
                                                defaultValue={undefined}
                                                textField="label"
                                                valueField="value"
                                                name={"fileter"}
                                                placeholder={'---Select----'}
                                                value={this.state.dealTypeFilter ? this.state.dealTypeFilter : undefined}
                                                onChange={this.filterByDate}
                                            />
                                        </div>
                                        <div className="col-md-2">
                                            <label>Status Type</label>
                                        </div>
                                        <div className="col-md-4">
                                            <DropdownList
                                                data={statusOptions}
                                                defaultValue={undefined}
                                                textField="label"
                                                valueField="value"
                                                name={"fileter"}
                                                placeholder={'---Select----'}
                                                value={this.state.statusFilter ? this.state.statusFilter : undefined}
                                                onChange={this.filterByDate}
                                            />
                                        </div>

                                        <div className="col-md-3">
                                            <button className="btn sbold red" onClick={this.handleReset}>Reset</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="btn-group pull-right">
                                        <input type="text" className="form-control" id="search_field" placeholder="Search Property(s)" onChange={this.search} />
                                        <HelpBlock style={{ color: '#e73d4a' }}>
                                            {seracherror ? seracherror : null}
                                        </HelpBlock>
                                    </div>
                                </div>

                                {/* <!-- add new --> */}
                                <div className="col-md-2 col-sm-offset-4">
                                    <div className="btn-group pull-right">
                                        <Link to="/new-property"><button id="sample_editable_1_new" className="btn sbold green"> + Add New
                                        </button></Link>
                                    </div>
                                </div>
                                {
                                    (this.props.allowDownloadProperties) ?
                                        <div className="col-md-3" >
                                            <div className="btn-group pull-right ml-10">
                                                <button onClick={this.downloadPropertyDetails} id="sample_editable_1_new" className="btn sbold green"> Download Properties </button>
                                            </div>
                                        </div>
                                        : ""
                                }
                            </div>
                        </div>
                        {/* if list is empty */}
                        {!isLoading && !array.length ? <Alert bsStyle="warning">
                            <strong>No Data Found !</strong>
                        </Alert> :
                            <div style={{ display: "flow-root" }}>
                                <Table responsive striped bordered condensed hover>
                                    <thead>
                                        <tr>
                                            <th> Category </th>
                                            <th> Property Address </th>
                                            <th> Market </th>
                                            <th> County </th>
                                            <th> Property Status </th>
                                            <th> Distressed </th>
                                            <th> Status </th>
                                            <th> Created On </th>
                                            <th> Modified On </th>
                                            <th width="11%"> Actions </th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {array.map(cms => {
                                            // console.log(cms);
                                            return (
                                                <ROW key={cms._id} markets={markets} cms={cms} delete={this.delete} allowDeleteProperties={this.props.allowDeleteProperties} />
                                            )
                                        })}
                                    </tbody>
                                </Table>
                                <div style={{ float: "right" }}>
                                    <Pagination
                                        activePage={parseInt(this.state.activePage)}
                                        itemsCountPerPage={window.limit}
                                        totalItemsCount={this.state.totalItemsCount}
                                        pageRangeDisplayed={3}
                                        onChange={this.handlePageChange}
                                    />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
    /* handle Reset */
    handleReset() {
        this.setState({dealTypeFilter:undefined,statusFilter:undefined, value: undefined, seracherror: '', searchQuery: '', activePage: 1 });
        this.props.history.push({
            search: '?page=' + 1
        });
        let searchEle = document.getElementById('search_field');
        if (searchEle) {
            searchEle.value = '';
        }
        this.getProperty()
    }

    /**PAGINATION */
    handlePageChange(eventKey) {
        this.setState({ activePage: eventKey });
        this.getProperty({
            page: eventKey ? eventKey : 1,
            filter: this.state.dealTypeFilter,
            statusFilter:this.state.statusFilter,
            searchQuery: this.state.searchQuery ? this.state.searchQuery : '',
        });
        /**to set query in route */
        this.props.history.push({
            search: '?page=' + eventKey
        })
    }
    /**SEARCH */
    search(e) {
        /**to remove Event Pooling  */
        e.persist();
        let searchedValue = e.target.value;
        let seracherror = ValidateOnlyAlpha(searchedValue);
        if (seracherror) {
            this.setState({ seracherror: seracherror });
            return;
        }

        if (!searchedValue.length) {
            this.setState({ searchQuery: '', seracherror: '', activePage: 1 });
            this.props.history.push({
                search: '?page=' + 1
            });
        } else if (searchedValue.length > 2) {
            this.props.history.push({
                search: '?page=' + 1
            });
        this.setState({ searchQuery: searchedValue, activePage: 1, seracherror: seracherror });
        } else {
            this.setState({ seracherror: 'Enter minimum 3 characters to search.', searchQuery: '' });
            return;
        }
    
        clearTimeout(timer);
        timer = setTimeout(() => {
        this.getProperty({
            page: this.state.activePage ? this.state.activePage : 1,
            searchQuery: this.state.searchQuery,
            filter: this.state.value ? this.state.value : '',
        });
        }, 1000);
    }

    getMarkets(){
        this.setState({ isLoading: true })
        HTTP.Request("get", window.admin.allAgentsDynamic)
            .then((response) => {
                this.setState({
                    markets: response.data.market,
                    isLoading: false,
                })
            })
    }
    getProperty(params = {}) {
        this.setState({ isLoading: true })
        HTTP.Request("get", window.admin.listPropertDetails, params)
            .then((response) => {
                console.log(response.data)
                this.setState({
                    array: response.data.records,
                    isLoading: false,
                    totalItemsCount: response.data.total
                })
            })

    }
    /* search data by date */

    filterByDate(data) {
        if(data.value == "in-active" || data.value == "active"){
        this.state.statusFilter = data.value
        }
        if(data.value == "wholesale" || data.value == "renovated"){
        this.state.dealTypeFilter = data.value
        }
        this.props.history.push({
            search: '?page=' + 1
        });
        this.setState({
            activePage: 1
        },
        this.getProperty({
            page: 1,
            searchQuery: this.state.searchQuery ? this.state.searchQuery : '',
            filter: this.state.dealTypeFilter,
            statusFilter:this.state.statusFilter
        })
        );
    }

    downloadPropertyDetails() {
        this.setState({ isLoading: true });
        HTTP.Request("get", window.admin.downloadPropertyDetails)
            .then((response) => {
                const workSheet = xlsx.utils.json_to_sheet(response.data);
                this.workBook = xlsx.utils.book_new();
                xlsx.utils.book_append_sheet(this.workBook, workSheet, "Properties");
                xlsx.writeFile(this.workBook, "Properties_List.xlsx");
                this.setState({ isLoading: false });
            })
            .catch((error) => {
                this.setState({ isLoading: false });
                console.log("Error downloadProperties =>>>", error);
            });
    }

    /* delete */
    delete(id) {
        let page = 1;
        let data = {};
        data.id = id;
        data.trash = true;

        HTTP.Request("post", window.admin.removePropertyDeatial, data)
            .then(result => {
                /**to stop loader */
                this.setState({ isLoading: false });
                /**refresh list after deletion */
                this.getProperty();
            })
            .catch(error => {
                this.setState({ isLoading: false });
            });

    }
}

const mapStateToProp = state => {
    var solvedPermissions = {};
    if (state.admin.permissions && (state.admin.permissions.indexOf("DOWNLOAD_PROPERTIES") !== -1)) {
        solvedPermissions.allowDownloadProperties = true;
    }
    if (state.admin.permissions && (state.admin.permissions.indexOf("DELETE_PROPERTIES") !== -1)) {
        solvedPermissions.allowDeleteProperties = true;
    }
    return solvedPermissions;
}

export default connect(mapStateToProp)(TableComp);