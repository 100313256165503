import React from 'react';
import { Switch } from 'react-router-dom';
import { ConnectedRouter } from 'react-router-redux';
import { connect } from 'react-redux';
import ReactDOM from 'react-dom';

/*import private routes for authenticated users*/
import WithoutLogin from './withoutLogin';
import PrivateRoute from './private';
import PermissionRoutes from './permissionRoutes';
import WhiteListRoute from './whiteListRoute';

import Login from '../components/login/login';
import ForgotPassword from '../components/login/forgotPassword';
import RequestAccess from '../components/login/requestAccess';
import ResetPassword from '../components/login/resetPassword';
import NewUserCreatePassword from '../components/login/newUserCreatePassword';
import Dashboard from '../components/dashboard/dashboard';

/**GET AN OFFER */
import GetAnOffer from '../components/get_offer';
import StagingGetAnOffer from '../components/get_offer/staging_offer';
import ViewGetOffer from '../components/get_offer/view';
import ViewStagingGetOffer from '../components/get_offer/staging_offer/view';

/* sign_up leads */
import sign_up from '../components/sign_up';
import ViewSign_up from '../components/sign_up/view';

/* Career Management*/
import CareerManage from '../components/career';
import ViewCareer from '../components/career/view';

/**MARKET MANAGEMENT*/
import MarketManagement from '../components/marketManagement';
import AddMarket from '../components/marketManagement/add';
import ViewMarket from '../components/marketManagement/view';
import EditMarket from '../components/marketManagement/edit';
import importMarket from '../components/marketManagement/import';
/* AGENT MANAGEMENT */
import Agent from '../components/agent';
import AddAgent from '../components/agent/add';

/**Property Management*/
import PropertyManagement from '../components/propertyManagement';
import AddProperty from '../components/propertyManagement/add';

// /**Review management */
// import getReview from "../components/reviewManagement";
// import ViewReview from "../components/reviewManagement/view";

/**ROLE MANAGMENT */
import RoleManagement from '../components/adminManagement/roleManagment';
import AddRole from '../components/adminManagement/roleManagment/add';

/**THIRD PARTY SERVICE */
import ThirdPartyServicesManagement from '../components/adminManagement/thirdPartyManagement';
import AddThirdPartyCredential from '../components/adminManagement/thirdPartyManagement/add';

/**USER MANAGMENT */
import UserManagement from '../components/adminManagement/userManagment';
import AddUser from '../components/adminManagement/userManagment/add';

/**Customer MANAGMENT */
import CustomerManagement from '../components/customerManagement';
import EditCustomer from '../components/customerManagement/add';

/**CUSTOMER ROLE MANAGMENT */
import CustomerRoleManagement from '../components/customerRoleMangment';
import CustomerAddRole from '../components/customerRoleMangment/add';

/**EMAIL Managment */
import EmailManagement from '../components/emailMangment';
import EditEmail from '../components/emailMangment/add';

/* SMS Management */

import SmsManagement from '../components/smsManagement';

/* Wholesale Flyer */

import WholesaleFlyerManagemnet from '../components/WholesaleFlyer';

/* Renovated Flyer */

import RenovatedFlyerManagemnet from '../components/renovatedFlyer';
import RenovatedFlyerSend from '../components/renovatedFlyer/renovated-flyer-log';
import RenovatedFlyer from '../components/renovatedFlyer/add';
import RenovatedFlyerPreview from '../components/renovatedFlyer/flyerPreview';

/* Wholesale Flyer */

import WholesaleFlyer from '../components/WholesaleFlyer/add';
import WholesaleFlyerPreview from '../components/WholesaleFlyer/flyerPreview';
import WholesaleFlyerSend from '../components/WholesaleFlyer/wholesale-flyer-log';

/*Pages Management*/
import _Pages from '../components/pages';
import _Pages_Add from '../components/pages/add';
import importPages from '../components/pages/import';

// Top Articles Management
import TopArticlesManagement from '../components/topArticles/index'
import topArticlesMangment_Form from '../components/topArticles/add'

// Footer Links Management
import FooterLink  from '../components/moreFooter/index'
import AddLink_Form from '../components/moreFooter/add'

/*Blogs Management*/
import _Blogs from '../components/blogs';
import _Blogs_Add from '../components/blogs/add';

/*Slider Management*/
import _Slides from '../components/slides';
import _Slides_Add from '../components/slides/add';
/* Sitemap MAnagement */
import _Sitemap from '../components/sitemap';
import _Sitemap_Add from '../components/sitemap/add';

/*CMS Blocks Management*/
import _BLOCK from '../components/cms-blocks';
import _BLOCK_Add from '../components/cms-blocks/add';

/*Ads Management*/
import _Ads from '../components/ads';
import _Ads_Add from '../components/ads/add';

import Profile from '../components/profile/profile';
import ChangePassword from '../components/changepassword';

/** IP MANAGMENT */
import AddIP from '../components/IpManagement';

/** TAXES */
import Taxes from '../components/taxes';

/** Settings */
import Settings from '../components/settings';

/** Social */
import Social from '../components/social';
import UpsertSocial from '../components/social/add';

/** FAQ */
import FAQ from '../components/FAQ';
import UpsertFAQ from '../components/FAQ/add';
import FAQcategory from '../components/FAQ_category/category';
import FAQcategorylist from '../components/FAQ_category';

/** Testimonial */
import Testimonial from '../components/testimonial';
import UpsertTestimonial from '../components/testimonial/add';

/** Feedback */
import Feedback from '../components/feedback';
import FeedbackResponse from '../components/feedback/reply';

/** Test */
import Test from '../components/test';
import UpsertTest from '../components/test/add';

/** Payment */
import Payment from '../components/payment';
import viewPayment from '../components/payment/view';

/*Logs*/
import Logs_Login from '../components/logs/login/';
import Logs_Audit from '../components/logs/audit/';
import User_Upload from '../components/logs/userupload';
import MongoToCrm from '../components/logs/mongoToCrm/mongoToCrm';
import CrmToMongo from '../components/logs/crmLogs/crmlogsTable';
import DynamicsWebLogs from '../components/logs/dynamicsWebApi/';

/**Access Logs*/
import AccessLogs from '../components/accessLogs';
import ViewAccessLog from '../components/accessLogs/view';

/**Reports*/
import Reports from '../components/reports';

/*import Element*/
import Header from '../components/elements/header';
//import Footer from '../components/elements/footer';
import SideBar from '../components/elements/sidebar';

/** Notifications.... */
import Notifications from '../components/notifications/';
/**404 NOT FOUND */
import NotFound from '../components/404/404';

/* New Property */
import NewProperty from '../components/property';
import AddProp from '../components/property/add';
/* Property CMS */
import PropertySec from '../components/propertySection';
import AddPropSec from '../components/propertySection/add';

/* Buy Property Page */

import BuyPropertySec from '../components/buyPropetyPage';
import AddPropertySec from '../components/buyPropetyPage/add';

/**import RoutesValue */
import { RoutesValue } from '../components/common/options';
/* Market Image */
import MarketImage from '../components/marketImage';
import MArketImageManage from '../components/marketImage/add';
/* Realtore */
import RealtorDistressed from '../components/RealtorDistressed';
/* Offers */
import Offers from '../components/offers';
/* Buyers */
import Buyers from '../components/Buyer';
import RecContacts from '../components/RecContacts';
import BuyersModule from '../components/buyersModule';
import AddBuyersModule from '../components/buyersAddModule/add';
// Redirect Links
import AddRedirectLink_Form from '../components/redirectLinksManagement/add';
import RedirectLinkManagement from '../components/redirectLinksManagement';

/*  */
import RealEstateInvestors from '../components/RealEstateInvestors';
import ImportInvestors from '../components/RealEstateInvestors/importuser';
import AddRealEstateInvestors from '../components/RealEstateInvestors/add';
import Investors from '../components/Investor';
import InvestorCMS from '../components/InvestorCMS';
import InvestorsCmsAdd from '../components/InvestorCMS/add';
import RealtorCMS from '../components/RealtorCMS';
import RealtorCMSAdd from '../components/RealtorCMS/add';
import RealtorSignUp from '../components/RealtorSignUp';
import AddRealtorSignUp from '../components/RealtorSignUp/add';
import BuyersCMS from '../components/BuyersCMS';
import BuyersCmsAdd from '../components/BuyersCMS/add';
import BuyersLead from '../components/BuyersLead/index.js';
import Buyer from '../components/Buyer';
import PropertySync from '../components/logs/property_sync_logs/propertySyncLogsTable';
import pagePreview from '../components/pages/pagePreview';
import blogPreview from '../components/blogs/blogPreview';
import exportCms from '../components/exportCms';
import fixPageIssues from '../components/fixPageIssues';
import AuthorManagement from '../components/adminManagement/authorManagment';
import AddAuthor from '../components/adminManagement/authorManagment/add';

// Cache 
import CacheManagement from '../components/adminManagement/pageCacheManagement'

class AppRouter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isToggle: false,
    };
    /*bind this with current class object*/
  }
  handleToggle = () => {
    this.setState({ isToggle: !this.state.isToggle });
  };

  /**this hook will run after render method */
  componentDidMount() {
    /**to get height of the left sidebar */
    /* let elHeight = document.getElementsByClassName('page-sidebar-menu  page-header-fixed ');
		/**find content side *
		let rightpanel = document.getElementById('rightPanel');
		/**set height of right and left panel equal *
		if (elHeight && elHeight["0"] && elHeight["0"].clientHeight) ReactDOM.findDOMNode(rightpanel).style.height =`${elHeight["0"].clientHeight}px`;   */
  }

  render() {
    const { isAdminLoggedIn } = this.props;
    return (
      <ConnectedRouter history={this.props.history}>
        <div>
          {this.props.isAdminLoggedIn && (
            <Header handleToggle={this.handleToggle} />
          )}
          {/* <!-- BEGIN CONTAINER --> */}
          <div className="page-container margin-top-container">
            <SideBar isToggle={this.state.isToggle} />
            <div className="page-content-wrapper">
              {/* <!-- BEGIN CONTENT BODY --> */}
              <div
                id="rightPanel"
                className={
                  isAdminLoggedIn ? 'page-content min-height1000 ' : null
                }
                onClick={() => {
                  if (this.state.isToggle == false) {
                    this.handleToggle();
                  }
                }}
              >
                <Switch>
                  <WithoutLogin exact path="/login" component={Login} />
                  <WhiteListRoute
                    exact
                    path="/forgot-password"
                    component={ForgotPassword}
                  />
                  <WhiteListRoute
                    exact
                    path="/request-access"
                    component={RequestAccess}
                  />
                  <WhiteListRoute
                    exact
                    path="/reset-password/:token"
                    component={ResetPassword}
                  />
                  <WhiteListRoute
                    exact
                    path="/create-password"
                    component={NewUserCreatePassword}
                  />
                  {/* Dashboard */}
                  <PrivateRoute exact path="/" component={Dashboard} />
                  {/*Contact_us*/}
                  {/* <PermissionRoutes
										exact
										path="/contact_us"
										component={Feedback}
										permission={RoutesValue.CONTACT_US}
									/> */}
                  {/* Profile	 */}
                  <PermissionRoutes
                    exact
                    path="/profile"
                    component={Profile}
                    permission={RoutesValue.PROFILE}
                  />
                  {/*Get An Offer*/}
                  <PermissionRoutes
                    exact
                    path="/get-offer-list"
                    component={GetAnOffer}
                    permission={RoutesValue.GET_AN_OFFER}
                  />
                  <PermissionRoutes
                    exact
                    path="/get-staging-offer-list"
                    component={StagingGetAnOffer}
                    permission={RoutesValue.GET_AN_OFFER}
                  />
                  <PermissionRoutes
                    exact
                    path="/get-offer-view/:id"
                    component={ViewGetOffer}
                    permission={RoutesValue.GET_AN_OFFER}
                  />
                  <PermissionRoutes
                    exact
                    path="/get-staging-offer-view/:id"
                    component={ViewStagingGetOffer}
                    permission={RoutesValue.GET_AN_OFFER}
                  />
                  {/* sign_up leads */}
                  <PermissionRoutes
                    exact
                    path="/get-out-of-market-leads"
                    component={sign_up}
                    permission={RoutesValue.OUT_OF_MARKET_LEADS}
                  />
                  <PermissionRoutes
                    exact
                    path="/get-out-of-market-lead-view/:id"
                    component={ViewSign_up}
                    permission={RoutesValue.OUT_OF_MARKET_LEADS}
                  />
                  {/* Review management
									<PermissionRoutes
										exact
										path="/get-review-list"
										component={getReview}
										permission={RoutesValue.GET_AN_OFFER}
									/>
									<PermissionRoutes
										exact
										path="/get-review-view/:id"
										component={ViewReview}
										permission={RoutesValue.GET_AN_OFFER}
									/> */}
                  {/*Market Management*/}
                  <PermissionRoutes
                    exact
                    path="/market-management-list"
                    component={MarketManagement}
                    permission={RoutesValue.MARKET_MANAGEMENT}
                  />
                  <PermissionRoutes
                    exact
                    path="/market-management/add"
                    component={AddMarket}
                    permission={RoutesValue.MARKET_MANAGEMENT}
                  />
                  <PermissionRoutes
                    exact
                    path="/market-management-import"
                    component={importMarket}
                    permission={RoutesValue.MARKET_MANAGEMENT}
                  />
                  <PermissionRoutes
                    exact
                    path="/market-view/:id"
                    component={ViewMarket}
                    permission={RoutesValue.MARKET_MANAGEMENT}
                  />
                  <PermissionRoutes
                    exact
                    path="/market-edit/:id"
                    component={EditMarket}
                    permission={RoutesValue.MARKET_MANAGEMENT}
                  />
                  {/* Career Management */}
                  <PermissionRoutes
                    exact
                    path="/career"
                    component={CareerManage}
                    permission={RoutesValue.CAREER}
                  />
                  <PermissionRoutes
                    exact
                    path="/career-view/:id"
                    component={ViewCareer}
                    permission={RoutesValue.CAREER}
                  />
                  {/*Property Management*/}
                  <PermissionRoutes
                    exact
                    path="/property-management-list"
                    component={PropertyManagement}
                    permission={RoutesValue.PROPERTY_MANAGEMENT}
                  />
                  <PermissionRoutes
                    exact
                    path="/property-management/add"
                    component={AddProperty}
                    permission={RoutesValue.PROPERTY_MANAGEMENT}
                  />
                  {/*Change Password*/}
                  <PermissionRoutes
                    exact
                    path="/change-password"
                    component={ChangePassword}
                    permission={RoutesValue.CHANGE_PASSWORD}
                  />
                  {/* RoleManagement */}
                  <PermissionRoutes
                    exact
                    path="/role-management"
                    component={RoleManagement}
                    permission={RoutesValue.MANAGE_ROLE}
                  />
                  <PermissionRoutes
                    exact
                    path="/role-management/add"
                    component={AddRole}
                    permission={RoutesValue.MANAGE_ROLE}
                  />
                  <PermissionRoutes
                    exact
                    path="/role-management/edit/:id"
                    component={AddRole}
                    permission={RoutesValue.MANAGE_ROLE}
                  />
                  {/* { ThirdPartyServicesManagement } */}
                  <PermissionRoutes
                    exact
                    path="/third-party-services"
                    component={ThirdPartyServicesManagement}
                    permission={RoutesValue.MANAGE_ROLE}
                  />
                  <PermissionRoutes
                    exact
                    path="/third-party-services/add"
                    component={AddThirdPartyCredential}
                    permission={RoutesValue.MANAGE_ROLE}
                  />
                  <PermissionRoutes
                    exact
                    path="/third-party-services/edit/:id"
                    component={AddThirdPartyCredential}
                    permission={RoutesValue.MANAGE_ROLE}
                  />
                  {/* Customer RoleManagement */}
                  <PermissionRoutes
                    exact
                    path="/customer-role-management"
                    component={CustomerRoleManagement}
                    permission={RoutesValue.MANAGE_ROLE}
                  />
                  <PermissionRoutes
                    exact
                    path="/customer-role-management/add"
                    component={CustomerAddRole}
                    permission={RoutesValue.MANAGE_ROLE}
                  />
                  <PermissionRoutes
                    exact
                    path="/customer-role-management/edit/:id"
                    component={CustomerAddRole}
                    permission={RoutesValue.MANAGE_ROLE}
                  />
                  {/* UserManagement */}
                  <PermissionRoutes
                    exact
                    path="/user-management"
                    component={UserManagement}
                    permission={RoutesValue.MANAGE_USER}
                  />
                  <PermissionRoutes
                    exact
                    path="/user-management/add"
                    component={AddUser}
                    permission={RoutesValue.MANAGE_USER}
                  />
                  <PermissionRoutes
                    exact
                    path="/user-management/edit/:id"
                    component={AddUser}
                    permission={RoutesValue.MANAGE_USER}
                  />
                  {/* AuthorManagement */}
                  <PermissionRoutes
                    exact
                    path="/author-management"
                    component={AuthorManagement}
                    permission={RoutesValue.MANAGE_USER}
                  />
                  <PermissionRoutes
                    exact
                    path="/author-management/add"
                    component={AddAuthor}
                    permission={RoutesValue.MANAGE_USER}
                  />
                  <PermissionRoutes
                    exact
                    path="/author-management/edit/:id"
                    component={AddAuthor}
                    permission={RoutesValue.MANAGE_USER}
                  />
                  {/* CacheManagement */}
                  <PermissionRoutes
                    exact
                    path="/cache-management"
                    component={CacheManagement}
                    permission={RoutesValue.MANAGE_USER}
                  />
                  {/* CustomerManagement */}
                  <PermissionRoutes
                    exact
                    path="/customer-management"
                    component={CustomerManagement}
                    permission={RoutesValue.MANAGE_USER}
                  />
                  <PermissionRoutes
                    exact
                    path="/customer-management/edit/:id"
                    component={EditCustomer}
                    permission={RoutesValue.MANAGE_USER}
                  />
                  {/* Email Management */}
                  <PermissionRoutes
                    exact
                    path="/email-management"
                    component={EmailManagement}
                    permission={RoutesValue.MANAGE_EMAIL}
                  />
                  <PermissionRoutes
                    exact
                    path="/email-management/add"
                    component={EditEmail}
                    permission={RoutesValue.MANAGE_EMAIL}
                  />
                  <PermissionRoutes
                    exact
                    path="/email-management/edit/:id"
                    component={EditEmail}
                    permission={RoutesValue.MANAGE_EMAIL}
                  />
                  {/* Renovated Flyer Management */}
                  <PermissionRoutes
                    exact
                    path="/renovated-flyer-management"
                    component={RenovatedFlyerManagemnet}
                    permission={RoutesValue.RENOVATED_FLYER}
                  />
                  <PermissionRoutes
                    exact
                    path="/renovated-flyer/:id"
                    component={RenovatedFlyer}
                    permission={RoutesValue.RENOVATED_FLYER}
                  />
                  <PermissionRoutes
                    exact
                    path="/renovatedflyer/preview"
                    component={RenovatedFlyerPreview}
                    permission={RoutesValue.RENOVATED_FLYER}
                  />
                  <PermissionRoutes
                    exact
                    path="/renovatedflyer"
                    component={RenovatedFlyerSend}
                    permission={RoutesValue.RENOVATED_FLYER}
                  />
                  {/* <PermissionRoutes
										exact
										path="/email-management/edit/:id"
										component={EditEmail}
										permission={RoutesValue.MANAGE_EMAIL}
									/> */}
                  {/* WholeSale Flyers */}
                  <PermissionRoutes
                    exact
                    path="/wholesale-flyer-management"
                    component={WholesaleFlyerManagemnet}
                    permission={RoutesValue.WHOLESALE_FLYER}
                  />
                  <PermissionRoutes
                    exact
                    path="/wholesale-flyer/:id"
                    component={WholesaleFlyer}
                    permission={RoutesValue.WHOLESALE_FLYER}
                  />
                  <PermissionRoutes
                    exact
                    path="/wholesaleflyer/preview"
                    component={WholesaleFlyerPreview}
                    permission={RoutesValue.WHOLESALE_FLYER}
                  />
                  <PermissionRoutes
                    exact
                    path="/wholesaleflyer"
                    component={WholesaleFlyerSend}
                    permission={RoutesValue.WHOLESALE_FLYER}
                  />
                  {/* SMS Management */}
                  <PermissionRoutes
                    exact
                    path="/sms-management"
                    component={SmsManagement}
                    permission={RoutesValue.MANAGE_EMAIL}
                  />
                  {/* <PermissionRoutes
										exact
										path="/sms-management/add"
										component={SmsManagement}
										permission={RoutesValue.MANAGE_EMAIL}
									/>
									<PermissionRoutes
										exact
										path="/sms-management/edit/:id"
										component={SmsManagement}
										permission={RoutesValue.MANAGE_EMAIL}
									/> */}
                  {/* Pages */}
                  <PermissionRoutes
                    exact
                    path="/export-cms"
                    component={exportCms}
                    permission={RoutesValue.MANAGE_PAGES}
                  />
                  <PermissionRoutes
                    exact
                    path="/fix-page-issues"
                    component={fixPageIssues}
                    permission={RoutesValue.MANAGE_PAGES}
                  />
                  <PermissionRoutes
                    exact
                    path="/pages"
                    component={_Pages}
                    permission={RoutesValue.MANAGE_PAGES}
                  />
                  <PermissionRoutes
                    exact
                    path="/city-pages"
                    component={_Pages}
                    permission={RoutesValue.MANAGE_PAGES}
                  />
                  <PermissionRoutes
                    exact
                    path="/pages/add"
                    component={_Pages_Add}
                    permission={RoutesValue.MANAGE_PAGES}
                  />
                  <PermissionRoutes
                    exact
                    path="/pages/preview"
                    component={pagePreview}
                    permission={RoutesValue.MANAGE_PAGES}
                  />
                  <PermissionRoutes
                    exact
                    path="/city-pages/add"
                    component={_Pages_Add}
                    permission={RoutesValue.MANAGE_PAGES}
                  />
                  <PermissionRoutes
                    exact
                    path="/pages/edit/:_id"
                    component={_Pages_Add}
                    permission={RoutesValue.MANAGE_PAGES}
                  />
                  <PermissionRoutes
                    exact
                    path="/pages/edit/:_id/:draft"
                    component={_Pages_Add}
                    permission={RoutesValue.MANAGE_PAGES}
                  />
                  <PermissionRoutes
                    exact
                    path="/city-pages/edit/:_id"
                    component={_Pages_Add}
                    permission={RoutesValue.MANAGE_PAGES}
                  />
                  <PermissionRoutes
                    exact
                    path="/city-pages/edit/:_id/:draft"
                    component={_Pages_Add}
                    permission={RoutesValue.MANAGE_PAGES}
                  />
                  <PermissionRoutes
                    exact
                    path="/top-articles"
                    component={TopArticlesManagement}
                    permission={RoutesValue.MANAGE_PAGES}
                  />
                  <PermissionRoutes
                    exact
                    path="/top-articles/add"
                    component={topArticlesMangment_Form}
                    permission={RoutesValue.MANAGE_PAGES}
                  />
                  <PermissionRoutes
                    exact
                    path="/top-articles/edit/:id/"
                    component={topArticlesMangment_Form}
                    permission={RoutesValue.MANAGE_PAGES}
                  />
                  <PermissionRoutes
                    exact
                    path="/footer-pages"
                    component={FooterLink}
                    permission={RoutesValue.MANAGE_PAGES}
                  />
                  <PermissionRoutes
                    exact
                    path="/footer-pages/add"
                    component={AddLink_Form}
                    permission={RoutesValue.MANAGE_PAGES}
                  />
                  <PermissionRoutes
                    exact
                    path="/footer-pages/edit/:id/"
                    component={AddLink_Form}
                    permission={RoutesValue.MANAGE_PAGES}
                  />
                  {/* <PermissionRoutes
                    exact
                    path="/redirect-links"
                    component={RedirectLinkManagement}
                    permission={RoutesValue.MANAGE_PAGES}
                  />
                  <PermissionRoutes
                    exact
                    path="/redirect-links/add"
                    component={AddRedirectLink_Form}
                    permission={RoutesValue.MANAGE_PAGES}
                  />
                  <PermissionRoutes
                    exact
                    path="/redirect-links/edit/:id/"
                    component={AddRedirectLink_Form}
                    permission={RoutesValue.MANAGE_PAGES}
                  /> */}
                  <PermissionRoutes
                    exact
                    path="/pages-import"
                    component={importPages}
                    permission={RoutesValue.MANAGE_PAGES}
                  />
                  {/* Blogs */}
                  <PermissionRoutes
                    exact
                    path="/blogs"
                    component={_Blogs}
                    permission={RoutesValue.MANAGE_BLOGS}
                  />
                  <PermissionRoutes
                    exact
                    path="/blogs/add"
                    component={_Blogs_Add}
                    permission={RoutesValue.MANAGE_BLOGS}
                  />
                  <PermissionRoutes
                    exact
                    path="/blogs/edit/:_id"
                    component={_Blogs_Add}
                    permission={RoutesValue.MANAGE_BLOGS}
                  />
                  <PermissionRoutes
                    exact
                    path="/blogs/preview/"
                    component={blogPreview}
                    permission={RoutesValue.MANAGE_BLOGS}
                  />
                  <PermissionRoutes
                    exact
                    path="/blogs/edit/:_id/:draft"
                    component={_Blogs_Add}
                    permission={RoutesValue.MANAGE_BLOGS}
                  />
                  {/* Slides */}
                  <PermissionRoutes
                    exact
                    path="/slides"
                    component={_Slides}
                    permission={RoutesValue.PRESS_RELEASE}
                  />
                  <PermissionRoutes
                    exact
                    path="/slides/add"
                    component={_Slides_Add}
                    permission={RoutesValue.PRESS_RELEASE}
                  />
                  <PermissionRoutes
                    exact
                    path="/slides/edit/:_id"
                    component={_Slides_Add}
                    permission={RoutesValue.PRESS_RELEASE}
                  />
                  {/* Sitemap */}
                  <PermissionRoutes
                    exact
                    path="/site-map"
                    component={_Sitemap}
                    permission={RoutesValue.MANAGE_SITEMAP}
                  />
                  <PermissionRoutes
                    exact
                    path="/sitemap/add"
                    component={_Sitemap_Add}
                    permission={RoutesValue.MANAGE_SITEMAP}
                  />
                  <PermissionRoutes
                    exact
                    path="/sitemap/edit/:id"
                    component={_Sitemap_Add}
                    permission={RoutesValue.MANAGE_SITEMAP}
                  />
                  {/* CMS Blocks */}
                  <PermissionRoutes
                    exact
                    path="/cms-blocks"
                    component={_BLOCK}
                    permission={RoutesValue.CMS}
                  />
                  <PermissionRoutes
                    exact
                    path="/cms-blocks/add"
                    component={_BLOCK_Add}
                    permission={RoutesValue.CMS}
                  />
                  <PermissionRoutes
                    exact
                    path="/cms-blocks/edit/:_id"
                    component={_BLOCK_Add}
                    permission={RoutesValue.CMS}
                  />
                  {/* Ads */}
                  <PermissionRoutes
                    exact
                    path="/ads"
                    component={_Ads}
                    permission={RoutesValue.PHOTO_GALLERY}
                  />
                  <PermissionRoutes
                    exact
                    path="/ads/add"
                    component={_Ads_Add}
                    permission={RoutesValue.PHOTO_GALLERY}
                  />
                  <PermissionRoutes
                    exact
                    path="/ads/edit/:_id"
                    component={_Ads_Add}
                    permission={RoutesValue.PHOTO_GALLERY}
                  />
                  {/* IP Management */}
                  <PermissionRoutes
                    exact
                    path="/ip-management"
                    component={AddIP}
                    permission={RoutesValue.MANAGE_ROLE}
                  />
                  {/* Taxes Management */}
                  <PermissionRoutes
                    exact
                    path="/taxes"
                    component={Taxes}
                    permission={RoutesValue.MANAGE_ROLE}
                  />
                  {/* Settings */}
                  <PermissionRoutes
                    exact
                    path="/settings"
                    component={Settings}
                    permission={RoutesValue.MANAGE_SETTINGS}
                  />
                  {/* Social */}
                  <PermissionRoutes
                    exact
                    path="/social"
                    component={Social}
                    permission={RoutesValue.CUSTOMER_STORIES}
                  />
                  <PermissionRoutes
                    exact
                    path="/social/add"
                    component={UpsertSocial}
                    permission={RoutesValue.CUSTOMER_STORIES}
                  />
                  <PermissionRoutes
                    exact
                    path="/social/edit/:_id"
                    component={UpsertSocial}
                    permission={RoutesValue.CUSTOMER_STORIES}
                  />
                  {/* FAQ */}
                  <PermissionRoutes
                    exact
                    path="/faq"
                    component={FAQ}
                    permission={RoutesValue.FAQ}
                  />
                  <PermissionRoutes
                    exact
                    path="/faq/add"
                    component={UpsertFAQ}
                    permission={RoutesValue.FAQ}
                  />
                  <PermissionRoutes
                    exact
                    path="/faq/edit/:_id"
                    component={UpsertFAQ}
                    permission={RoutesValue.FAQ}
                  />
                  <PermissionRoutes
                    exact
                    path="/faq-category/add"
                    component={FAQcategory}
                    permission={RoutesValue.FAQ_CATEGORY}
                  />
                  <PermissionRoutes
                    exact
                    path="/faq-category-list"
                    component={FAQcategorylist}
                    permission={RoutesValue.FAQ_CATEGORY}
                  />
                  <PermissionRoutes
                    exact
                    path="/faq-category/edit/:id"
                    component={FAQcategory}
                    permission={RoutesValue.FAQ_CATEGORY}
                  />
                  {/* Testimonial */}
                  <PermissionRoutes
                    exact
                    path="/reviews"
                    component={Testimonial}
                    permission={RoutesValue.REVIEWS}
                  />
                  <PermissionRoutes
                    exact
                    path="/review/add"
                    component={UpsertTestimonial}
                    permission={RoutesValue.REVIEWS}
                  />
                  <PermissionRoutes
                    exact
                    path="/review/edit/:_id"
                    component={UpsertTestimonial}
                    permission={RoutesValue.REVIEWS}
                  />
                  {/* FeedBack */}
                  <PermissionRoutes
                    exact
                    path="/feedback"
                    component={Feedback}
                    permission={RoutesValue.SHORT_FORM_LEAD}
                  />
                  <PermissionRoutes
                    exact
                    path="/feedback/reply/:_id"
                    component={FeedbackResponse}
                    permission={RoutesValue.SHORT_FORM_LEAD}
                  />
                  {/* Tests */}
                  <PermissionRoutes
                    exact
                    path="/test"
                    component={Test}
                    permission={RoutesValue.MANAGE_USER}
                  />
                  <PermissionRoutes
                    exact
                    path="/test/add"
                    component={UpsertTest}
                    permission={RoutesValue.MANAGE_USER}
                  />
                  <PermissionRoutes
                    exact
                    path="/test/edit/:_id"
                    component={UpsertTest}
                    permission={RoutesValue.MANAGE_USER}
                  />
                  {/* Payment*/}
                  <PermissionRoutes
                    exact
                    path="/payment"
                    component={Payment}
                    permission={RoutesValue.MANAGE_USER}
                  />
                  <PermissionRoutes
                    exact
                    path="/payment/action/:_id"
                    component={viewPayment}
                    permission={RoutesValue.MANAGE_USER}
                  />
                  {/* Aceess Logs*/}
                  <PermissionRoutes
                    exact
                    path="/logs/access"
                    component={AccessLogs}
                    permission={RoutesValue.MANAGE_LOGS}
                  />
                  <PermissionRoutes
                    exact
                    path="/logs/access/view"
                    component={ViewAccessLog}
                    permission={RoutesValue.MANAGE_LOGS}
                  />
                  {/* Logs */}
                  <PermissionRoutes
                    exact
                    path="/logs/login"
                    component={Logs_Login}
                    permission={RoutesValue.MANAGE_LOGS}
                  />
                  <PermissionRoutes
                    exact
                    path="/logs/audit"
                    component={Logs_Audit}
                    permission={RoutesValue.MANAGE_LOGS}
                  />
                  <PermissionRoutes
                    exact
                    path="/logs/upload"
                    component={User_Upload}
                    permission={RoutesValue.MANAGE_LOGS}
                  />
                  <PermissionRoutes
                    exact
                    path="/logs/mongotocrm"
                    component={MongoToCrm}
                    permission={RoutesValue.MANAGE_LOGS}
                  />
                  <PermissionRoutes
                    exact
                    path="/logs/propertysynclogs"
                    component={PropertySync}
                    permission={RoutesValue.MANAGE_LOGS}
                  />
                  <PermissionRoutes
                    exact
                    path="/logs/crmlogs"
                    component={CrmToMongo}
                    permission={RoutesValue.MANAGE_LOGS}
                  />
                  <PermissionRoutes
                    exact
                    path="/logs/dynamicslogs"
                    component={DynamicsWebLogs}
                    permission={RoutesValue.MANAGE_LOGS}
                  />
                  {/* User Notifications.... */}
                  <PrivateRoute
                    exact
                    path="/notifications"
                    component={Notifications}
                  />
                  {/* Reports */}
                  <PermissionRoutes
                    exact
                    path="/reports/:type"
                    component={Reports}
                    permission={RoutesValue.MANAGE_USER}
                  />
                  {/* New Property */}
                  <PermissionRoutes
                    exact
                    path="/property-list"
                    component={NewProperty}
                    permission={RoutesValue.PROPERTY_MANAGEMENT}
                  />
                  <PermissionRoutes
                    exact
                    path="/new-property"
                    component={AddProp}
                    permission={RoutesValue.PROPERTY_MANAGEMENT}
                  />
                  <PermissionRoutes
                    exact
                    path="/update-property/:_id"
                    component={AddProp}
                    permission={RoutesValue.PROPERTY_MANAGEMENT}
                  />
                  <PermissionRoutes
                    exact
                    path="/update-property/:_id/:draft"
                    component={AddProp}
                    permission={RoutesValue.PROPERTY_MANAGEMENT}
                  />
                  <PermissionRoutes
                    exact
                    path="/update-property/:_id/offers"
                    component={AddProp}
                    permission={RoutesValue.PROPERTY_MANAGEMENT}
                  />
                  <PermissionRoutes
                    exact
                    path="/update-property/:_id/comparables"
                    component={AddProp}
                    permission={RoutesValue.PROPERTY_MANAGEMENT}
                  />
                  <PermissionRoutes
                    exact
                    path="/update-property/:_id/inquiries"
                    component={AddProp}
                    permission={RoutesValue.PROPERTY_MANAGEMENT}
                  />
                  <PermissionRoutes
                    exact
                    path="/update-property/:_id/open-houses"
                    component={AddProp}
                    permission={RoutesValue.PROPERTY_MANAGEMENT}
                  />
                  <PermissionRoutes
                    exact
                    path="/update-property/:_id/edit"
                    component={AddProp}
                    permission={RoutesValue.PROPERTY_MANAGEMENT}
                  />
                  {/* Agent Management */}
                  <PermissionRoutes
                    exact
                    path="/disposition-consultant-list"
                    component={Agent}
                    permission={RoutesValue.PROPERTY_MANAGEMENT}
                  />
                  <PermissionRoutes
                    exact
                    path="/new-disposition-consultant"
                    component={AddAgent}
                    permission={RoutesValue.PROPERTY_MANAGEMENT}
                  />
                  <PermissionRoutes
                    exact
                    path="/new-disposition-consultant/:propGuid"
                    component={AddAgent}
                    permission={RoutesValue.PROPERTY_MANAGEMENT}
                  />
                  <PermissionRoutes
                    exact
                    path="/update-disposition-consultant/:_id"
                    component={AddAgent}
                    permission={RoutesValue.PROPERTY_MANAGEMENT}
                  />
                  <PermissionRoutes
                    exact
                    path="/property-cms-list"
                    component={PropertySec}
                    permission={RoutesValue.BUYLANDINGPAGE}
                  />
                  <PermissionRoutes
                    exact
                    path="/property-cms-add"
                    component={AddPropSec}
                    permission={RoutesValue.BUYLANDINGPAGE}
                  />
                  <PermissionRoutes
                    exact
                    path="/property-cms-edit/:_id"
                    component={AddPropSec}
                    permission={RoutesValue.BUYLANDINGPAGE}
                  />
                  <PermissionRoutes
                    exact
                    path="/buy-property-cms-list"
                    component={BuyPropertySec}
                    permission={RoutesValue.BUYPROPERTYPAGE}
                  />
                  <PermissionRoutes
                    exact
                    path="/buy-property-cms-add"
                    component={AddPropertySec}
                    permission={RoutesValue.BUYPROPERTYPAGE}
                  />
                  <PermissionRoutes
                    exact
                    path="/buy-property-cms-edit/:_id"
                    component={AddPropertySec}
                    permission={RoutesValue.BUYPROPERTYPAGE}
                  />
                  <PermissionRoutes
                    exact
                    path="/market-image-list"
                    component={MarketImage}
                    permission={RoutesValue.PROPERTY_MANAGEMENT}
                  />
                  <PermissionRoutes
                    exact
                    path="/market-image-add"
                    component={MArketImageManage}
                    permission={RoutesValue.PROPERTY_MANAGEMENT}
                  />
                  <PermissionRoutes
                    exact
                    path="/market-image-edit/:_id"
                    component={MArketImageManage}
                    permission={RoutesValue.PROPERTY_MANAGEMENT}
                  />
                  <PermissionRoutes
                    exact
                    path="/customers"
                    component={BuyersModule}
                    permission={RoutesValue.BUYERS}
                  />
                  <PermissionRoutes
                    exact
                    path="/addBuyer"
                    component={AddBuyersModule}
                    permission={RoutesValue.BUYERS}
                  />
                  <PermissionRoutes
                    exact
                    path="/updateBuyer/:_id"
                    component={AddBuyersModule}
                    permission={RoutesValue.BUYERS}
                  />
                  <PermissionRoutes
                    exact
                    path="/rec_contacts"
                    component={RecContacts}
                    permission={RoutesValue.REC_CONTACTS_LIST}
                  />
                  <PermissionRoutes
                    exact
                    path="/offers"
                    component={Offers}
                    permission={RoutesValue.REC_CONTACTS_LIST}
                  />
                  {/* Realtors */}
                  RecContacts
                  <PermissionRoutes
                    exact
                    path="/realtors-distressed-list"
                    component={RealtorDistressed}
                    permission={RoutesValue.Realtors}
                  />
                  {/* Real Estate Investors */}
                  <PermissionRoutes
                    exact
                    path="/users"
                    component={RealEstateInvestors}
                    permission={RoutesValue.REALSTATEINVESTORS}
                  />
                  <PermissionRoutes
                    exact
                    path="/users-add"
                    component={AddRealEstateInvestors}
                    permission={RoutesValue.REALSTATEINVESTORS}
                  />
                  <PermissionRoutes
                    exact
                    path="/users-edit/:_id"
                    component={AddRealEstateInvestors}
                    permission={RoutesValue.REALSTATEINVESTORS}
                  />
                  <PermissionRoutes
                    exact
                    path="/users-edit/:_id/offers"
                    component={AddRealEstateInvestors}
                    permission={RoutesValue.REALSTATEINVESTORS}
                  />
                  <PermissionRoutes
                    exact
                    path="/users-edit/:_id/edit"
                    component={AddRealEstateInvestors}
                    permission={RoutesValue.REALSTATEINVESTORS}
                  />
                  <PermissionRoutes
                    exact
                    path="/investor-imports"
                    component={ImportInvestors}
                    permission={RoutesValue.REALSTATEINVESTORS}
                  />
                  {/* investors */}
                  <PermissionRoutes
                    exact
                    path="/investor-list"
                    component={Investors}
                    permission={RoutesValue.Investors}
                  />
                  {/* investors CMS */}
                  <PermissionRoutes
                    exact
                    path="/investor-cms-list"
                    component={InvestorCMS}
                    permission={RoutesValue.INVESTORCMSMANAGE}
                  />
                  <PermissionRoutes
                    exact
                    path="/investor-cms-add"
                    component={InvestorsCmsAdd}
                    permission={RoutesValue.INVESTORCMSMANAGE}
                  />
                  <PermissionRoutes
                    exact
                    path="/investor-cms-edit/:_id"
                    component={InvestorsCmsAdd}
                    permission={RoutesValue.INVESTORCMSMANAGE}
                  />
                  {/* Realtor CMS */}
                  <PermissionRoutes
                    exact
                    path="/realtor-cms-list"
                    component={RealtorCMS}
                    permission={RoutesValue.REALTORSCMSMANAGE}
                  />
                  <PermissionRoutes
                    exact
                    path="/realtor-cms-add"
                    component={RealtorCMSAdd}
                    permission={RoutesValue.REALTORSCMSMANAGE}
                  />
                  <PermissionRoutes
                    exact
                    path="/realtor-cms-edit/:_id"
                    component={RealtorCMSAdd}
                    permission={RoutesValue.REALTORSCMSMANAGE}
                  />
                  {/* Realtors Sign up */}
                  <PermissionRoutes
                    exact
                    path="/realtor-signup-list"
                    component={RealtorSignUp}
                    permission={RoutesValue.REALTORSIGNUPMANAGE}
                  />
                  <PermissionRoutes
                    exact
                    path="/realtor-signup-add"
                    component={AddRealtorSignUp}
                    permission={RoutesValue.REALTORSIGNUPMANAGE}
                  />
                  <PermissionRoutes
                    exact
                    path="/realtor-signup-edit/:_id"
                    component={AddRealtorSignUp}
                    permission={RoutesValue.REALTORSIGNUPMANAGE}
                  />
                  {/* Buyers CMS */}
                  <PermissionRoutes
                    exact
                    path="/buyers-cms-add"
                    component={BuyersCmsAdd}
                    permission={RoutesValue.BUYERS}
                  />
                  {/* Buyers Leads */}
                  <PermissionRoutes
                    exact
                    path="/buyers-lead"
                    component={BuyersLead}
                    permission={RoutesValue.BUYERS_LEAD}
                  />
                  {/* <PermissionRoutes exact path='/logs/audit' component={Logs_Audit} permission={RoutesValue.MANAGE_USER} /> */}
                  <PrivateRoute path="*" component={NotFound} />
                </Switch>
              </div>
            </div>
          </div>
          {/* <Footer /> */}
        </div>
      </ConnectedRouter>
    );
  }
}

/*get props*/
function mapStatesToProps(state) {
  console.log('state is ', state);
  return {
    isAdminLoggedIn: state.admin.token ? true : false,
  };
}

export default connect(mapStatesToProps)(AppRouter);
