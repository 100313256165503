import React, { Component } from 'react';
import Moment from "react-moment";
import moment from "moment-timezone";
import { Link } from 'react-router-dom';
import Modal from '../../common/modal';
import TT from "../../common/tooltip";

class ROW extends Component {
    
    constructor(props){
        super(props);

        this.state={
            show: false
        }
        /*bind this with methods*/
        this.showModal = this.showModal.bind(this);
        this.deleteRow = this.deleteRow.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }
    
    render() {
        const {cms, indx} = this.props;
        return (
          <tr className="odd gradeX">
            <td> {indx + 1} </td>
            <td>
              {' '}
              {cms.firstName} {cms.lastName}{' '}
            </td>
            <td> {cms.email} </td>
            <td>{cms.phone || 'Unknown'}</td>
            <td>{cms.isVIP ? 'YES' : 'NO' || '-'}</td>
            <td>
              <TT tooltip="Edit">
                <Link
                  to={'/updateBuyer/' + cms._id}
                  className=" btn btn-xs grey-mint"
                >
                  <i className="fa fa-pencil-square-o"></i>
                </Link>
              </TT>
              <TT tooltip="Remove Buyer">
                <a
                  className=" btn btn-xs red-mint"
                  onClick={this.showModal}
                  title="View"
                >
                  <i className="fa fa-trash no-pointer"></i>
                </a>
              </TT>
              {this.state.show && (
                <Modal
                  show={true}
                  func={this.deleteRow}
                  closeParentModal={this.closeModal}
                  title={cms.title}
                />
              )}
            </td>
          </tr>
        );
    }

    showModal() {
        /*show popup and confirm before delete*/
        this.setState({ show: true });
    }

    deleteRow(){
        this.setState({ isLoading: true,show:false });
        /*delete row by call prop func*/
        this.props.delete(this.props.cms._id);        
    }

    closeModal() {
        this.setState({ show: false });
    }

}

export default ROW;

