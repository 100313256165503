import React, { Component } from 'react';
import Moment from "react-moment";
import { connect } from 'react-redux';
import Modal from '../../../common/modal';
import { Link } from 'react-router-dom';
import TT from "../../../common/tooltip";
import moment from "moment-timezone";


class ROW extends Component {
    constructor(props){
        super(props);
        this.state={
            show: false
        }
        /*bind this with methods*/
        this.showModal = this.showModal.bind(this);
        this.deleteRow = this.deleteRow.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    render() {

        const {admin,adminID} = this.props;
        console.log(admin);
        console.log('adminId', adminID)

        /*console.log('admin',admin.roleTitle.title);

        console.log('adminRole',adminRole);*/

        var edit = "";
        
        edit = admin.firstName + " " + admin.lastName

        return (
            <tr className="odd gradeX" >
                <td> {edit} </td>
                <td> {admin.email} </td>
                <td> {admin.phone_no ? admin.phone_no:"NA"} </td>
                <td><Moment format="MM/DD/YY">{moment(admin.updateded_at).zone("-04:00")}</Moment></td>
                <td><Moment format="MM/DD/YY">{moment(admin.created_at).zone("-04:00")}</Moment></td>

                {/* <td><Moment format="MM/DD/YY">{admin.created_at}</Moment></td>             */}
                <td className="center"> <label className={admin.status ? 'label label-info' : 'label label-danger'}>{admin.status ? "Active" : "Inactive"}</label> </td>
                <td>
                    <TT tooltip="Remove"><a className=" btn btn-xs red-mint" onClick={this.showModal} title="View"><i className="fa fa-trash no-pointer"  ></i></a></TT>
                    <TT tooltip="Edit">
            <Link to={'/author-management/edit/' + admin._id} className=" btn btn-xs grey-mint">
              <i className="fa fa-pencil-square-o"></i>
            </Link>
          </TT>
                     {this.state.show && <Modal show={true} func={this.deleteRow} closeParentModal={this.closeModal} title={admin.title} />}
                </td>
            </tr>
        );
    }

    showModal() {
        /*show popup and confirm before delete*/
        this.setState({ show: true });
    }

    deleteRow(){
        this.setState({ isLoading: true,show:false });
        /*delete row by call prop func*/
        this.props.delete(this.props.admin._id);
    }

    closeModal() {
        this.setState({ show: false });
    }
}
/*get props*/
const mapStatesToProps =(state) => {
}

export default connect(mapStatesToProps)(ROW);
